import React from 'react';
import { Image, View, ScrollView, Linking, Pressable, useWindowDimensions } from 'react-native';
import { create, useDeviceContext } from 'twrnc';
import { PrimaryNav, Modal, Heading, MemoizedSimpleTextInput as SimpleTextInput, Button, Span, AssetItem, SelectMenu, TextInput, Notification, HorizontalRule, PillMenu, ImagesUploadInput, AddNewAssetButtons, DocumentUploadInput, SpecsCard, Tag } from '../elements';
import { useSpring, animated } from 'react-spring';
import { getLabel, getYachtOptions, now, is_date_in_past, is_date_within_30_days, date_DMJYYYY, YYYYMMDD_to_DMJYYYY, formatDateToLocal, date_DMJYYYY_TIME, getUserOptions, truncate, sort_by, getOptionsByLabelGroup, getPropertiesByGroup, recurrence_fromPeriod, getRouteFilter, updateRouteFilter, processImagesInput, uploadImages, canIhazIcon, getEngineOptions, getEquipmentOptions, getLocationFromAssetID, processDocumentInput, uploadDocument, getAssetDocuments, getAssetPictures } from '../helpers';
import { GlobalContext } from '../global';
import { useToast } from 'react-native-toast-notifications';

function MyTasksView ({navigation})
{
    const AnimatedView = animated(View);
    const tw = create( require(`../../tailwind.config.js`) );
    useDeviceContext(tw);
    const {height, width} = useWindowDimensions();
    const formHeight = height - 100;
    const toast = useToast();
    
    let {
        apiUrl,
        apiVersion,
        settings,
        setLastUpdate,
        loggedIn, setLoggedIn,
        userID,
        userYachts,
        userTasks,
        enabledFeatures,
        themes, theme
    } = React.useContext(GlobalContext);

    React.useEffect(()=> 
    {        
        if ( ! loggedIn ) { navigation.navigate('loading'); }
    },[loggedIn, navigation]);

    const [getFilter, setFilter] = React.useState(null);
    const [filterVisible, setFilterVisible] = React.useState(false);

    const labels = React.useRef(null);
    React.useEffect(()=> 
    {
        if (!labels.current) {
            labels.current = {
                _ASSETS: getLabel('ICON_TASKS'),
                _ASSET: getLabel('TASK'),
                _404_TEXT: getLabel('TASKS_404_TEXT2'),

                YACHT: getLabel('YACHT'),
                EDIT: getLabel('EDIT'),
                DELETE: getLabel('DELETE'),
                CANCEL: getLabel('CANCEL'),
                NOTIFICATIONS: getLabel('NOTIFICATIONS'),
                CONTENT_ADD: getLabel('CONTENT_ADD'),
                ADD: getLabel('ADD'),
                UPDATE: getLabel('UPDATE'),

                NAME: getLabel('TASK') + ' ' + getLabel('NAME'),
                ASSIGNED_TO: getLabel('ASSIGNED_TO'),
                ASSIGN_TO: getLabel('ASSIGN_TO'),
                ASSIGNED_BY_ME: getLabel('ASSIGNED_BY_ME'),
                ASSIGNED_TO_ME: getLabel('ASSIGNED_TO_ME'),
                ASSIGN: getLabel('ASSIGN'),
                ASSIGNEE_ALTERNATE: getLabel('ASSIGNEE_ALTERNATE'),
                DATE: getLabel('DATE'),
                INVALID_DATE: getLabel('INVALID_DATE'),
                DETAILS: getLabel('DETAILS'),
                COMPLETED: getLabel('COMPLETED'),
                TODO: getLabel('TODO'),
                DUE: getLabel('DUE'),
                STATUS: getLabel('STATUS'),
                OVERDUE: getLabel('OVERDUE'),
                REQUIRED: getLabel('REQUIRED'),
                PRIORITY: getLabel('PRIORITY'),
                LOW_PRIORITY: getLabel('LOW_PRIORITY'),
                MEDIUM_PRIORITY: getLabel('MEDIUM_PRIORITY'),
                HIGH_PRIORITY: getLabel('HIGH_PRIORITY'),
                CRITICAL_PRIORITY: getLabel('CRITICAL_PRIORITY'),
                DUE_HOURS: getLabel('DUE_HOURS'),
                LOCATION: getLabel('LOCATION'),
                EQUIPMENT: getLabel('EQUIPMENT'),
                ENGINE: getLabel('ENGINE'),

                REQUEST: getLabel('REQUEST'),
                REQUEST_VALID: getLabel('REQUEST_VALID'),
                DELETE_CONFIRM_TITLE: getLabel('DELETE_CONFIRM_TITLE'),
                DELETE_CONFIRM_BODY: getLabel('DELETE_CONFIRM_BODY'),
                DELETE_CONFIRM2_BODY: getLabel('DELETE_CONFIRM2_BODY'),
                PERMANENT_ACTION: getLabel('PERMANENT_ACTION'),
                VIEW: getLabel('VIEW'),
                SORT: getLabel('SORT'),
                ALL: getLabel('ALL'),
                SELECT: getLabel('SELECT'),
                SELECTED: getLabel('SELECTED'),

                TASK_RECURRING: getLabel('TASK_RECURRING'),
                TASK_ONETIME: getLabel('TASK_ONETIME'),
                TASK_MARK_COMPLETE: getLabel('TASK_MARK_COMPLETE'),
                TASK_MARK_COMPLETE_TITLE: getLabel('TASK_MARK_COMPLETE_TITLE'),
                TASK_MARK_COMPLETE_BODY: getLabel('TASK_MARK_COMPLETE_BODY'),
                TASK_RECURRING_CREATE_TITLE: getLabel('TASK_RECURRING_CREATE_TITLE'),
                TASK_RECURRING_CREATE_HOURS: getLabel('TASK_RECURRING_CREATE_HOURS'),
                TASK_RECURRING_CREATE: getLabel('TASK_RECURRING_CREATE'),
                TASK_RECUR: getLabel('TASK_RECUR'),
                EVERY: getLabel('EVERY'),
                NEVER: getLabel('NEVER'),
                TYPE: getLabel('TYPE'),
                TASK_RECUR_NO_MODIFY: getLabel('TASK_RECUR_NO_MODIFY'),
                TASK_DELETE_CONFIRM_RECURRENCE: getLabel('TASK_DELETE_CONFIRM_RECURRENCE'),
                INTERVAL: getLabel('INTERVAL'),
                SHARE: getLabel('SHARE'),

                TOAST_UPDATE: getLabel('TOAST_UPDATE'),
                TOAST_ADD: getLabel('TOAST_ADD'),
                TOAST_DELETE: getLabel('TOAST_DELETE'),

                DETAILS: getLabel('DETAILS'),
                PHOTOS: getLabel('PHOTOS'),
                PHOTO: getLabel('PHOTO'),
                _404_PHOTOS_TEXT: getLabel('PHOTOS_404_TEXT'),

                DOCUMENTS: getLabel('DOCUMENTS'),
                DOCUMENT: getLabel('DOCUMENT'),
                _404_DOCUMENTS_TEXT: getLabel('DOCUMENT_404_TEXT'),
                DOCUMENT_FILE: getLabel('ATTACHMENT_FILE'),
                DOCUMENT_TOO_LARGE_BODY: getLabel('ATTACHMENT_TOO_LARGE_BODY'),
                DATE: getLabel('DATE'),
                NAME: getLabel('NAME'),
                EXPIRES: getLabel('EXPIRES'),
                EXPIRING: getLabel('EXPIRING'),
                EXPIRED: getLabel('EXPIRED'),
                EXPIRATION_NONE: getLabel('EXPIRATION_NONE'),
                EXPIRATION_VALID: getLabel('EXPIRATION_VALID'),
                EXPIRATION: getLabel('EXPIRATION'),
                // TYPE: getLabel('TYPE'),
                // DETAILS: getLabel('DETAILS'),
                // VIEW: getLabel('VIEW'),
                DETAIL: getLabel('DETAIL'),
            };
        }

        setFilter(getRouteFilter('tasks') || getLabel('ALL'));
    },[setFilter]);

    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : false;

    const [primaryMsg, setPrimaryMsg] = React.useState('');
    const [primaryMsgType, setPrimaryMsgType] = React.useState('warning');
    const [modalActive, setModalActive] = React.useState(false);
    const [modalAffectID, setModalAffectID] = React.useState(0);
    const [quickCompleteModalActive, setQuickCompleteModalActive] = React.useState(false);
    const [quickCompleteModalAffectObject, setQuickCompleteModalAffectObject] = React.useState({});
    
    const [addingNew, setAddingNew] = React.useState(false);
    const [updateID, setUpdateID] = React.useState(0);
    
    const [viewingAsset, setViewingAsset] = React.useState(false);
    const [viewingAssetObj, setViewingAssetObj] = React.useState({});

    const [newAssetMsg, setNewAssetMsg] = React.useState('');
    const [newAssetMsgType, setNewAssetMsgType] = React.useState('warning');
    const [newAssetValidation, doNewAssetValidation] = React.useState(false);
    const [getAssets, setAssets] = React.useState([]);

    // asset specific input parameters
    const [newTaskYacht, setNewTaskYacht] = React.useState('');
    const [yachtSelectVisible, setYachtSelectVisible] = React.useState(false);
    const [newTaskName, setNewTaskName] = React.useState('');
    const [newTaskOwner, setNewTaskOwner] = React.useState(true === enabledFeatures.sharing ? '' : user.id);
    const [newTaskAlternate, setNewTaskAlternate] = React.useState('');
    const [ownerSelectVisible, setOwnerSelectVisible] = React.useState(false);
    const [newTaskDate, setNewTaskDate] = React.useState('');
    const [newHoursDue, setNewHoursDue] = React.useState('');
    const [newNotes, setNewNotes] = React.useState('');
    const [newIsComplete, setNewIsComplete] = React.useState(false);
    const [newPriority, setNewPriority] = React.useState(0);
    const [prioritySelectVisible, setPrioritySelectVisible] = React.useState(false);

    const [ownerOptions, setOwnerOptions] = React.useState([]);

    const [newType, setNewType] = React.useState('');
    const [typeSelectVisible, setTypeSelectVisible] = React.useState(false);
    const [newLocation, setNewLocation] = React.useState('');
    const [newLocationVisible, setNewLocationVisible] = React.useState(false);
    const [newMechanicalID, setNewMechanicalID] = React.useState('');
    const [newEquipmentID, setNewEquipmentID] = React.useState('');
    const [newMechanicalIDVisible, setNewMechanicalVisible ] = React.useState(false);
    const [newEquipmentVisible, setNewEquipmentVisible ] = React.useState(false);
    const [equipmentOptions, setEquipmentOptions] = React.useState([]);
    const [mechanicalOptions, setMechanicalOptions] = React.useState([]);
    const [locationSelectVisible, setLocationSelectVisible] = React.useState(false);
    const [mechanicalSelectVisible, setMechanicalSelectVisible] = React.useState(false);
    const [equipmentSelectVisible, setEquipmentSelectVisible] = React.useState(false);

    const [newIsRecurring, setNewIsRecurring] = React.useState(0);
    const [newRecurrenceType, setNewRecurrenceType] = React.useState(null);
    const [newRecurrenceInterval, setNewRecurrenceInterval] = React.useState(null);
    const [newRecurrenceDays, setNewRecurrenceDays] = React.useState(0);
    const [isRecurrenceLocked, setIsRecurrenceLocked] = React.useState(false);
    const [recurrenceTypeSelectVisible, setRecurrenceTypeSelectVisible] = React.useState(false);
    const [recurrenceIntervalSelectVisible, setRecurrenceIntervalSelectVisible] = React.useState(false);

    // input refs for asset specific inputs
    const newTaskNameInput = React.useRef(null);
    const newNotesInput = React.useRef(null);
    const newTaskDateInput = React.useRef(null);
    const newHoursDueInput = React.useRef(null);
    const newTaskAlternateInput = React.useRef(null);

    // misc refs
    const newStatusText = React.useRef('');
    const newCompleteDateText = React.useRef('');
    const is_recurrence_locked = React.useRef(0);
    const recurringTaskIDs = React.useRef([]);

    // options
    const recurrenceTypeOptions = getOptionsByLabelGroup('types.time', true).filter(option => option.value !== 1 && option.value !== 2);
    const recurrenceTypeFactor = getPropertiesByGroup('value', 'types.time', 'factor', false, true);
    const recurrenceIntervalOptions = [...Array(999).keys()].map(i => i + 1).map(i => { return { value: i, label: i } });
    const priorityOptions = getPropertiesByGroup('id', 'types.task.priorities', 'label', false, true);
    const typeOptions = getOptionsByLabelGroup('types.maintenance.types', true);
    const locationOptions = getOptionsByLabelGroup('types.inventory.locations', true);
    const allMechanicalOptions = getEngineOptions();
    const allEquipmentOptions = getEquipmentOptions();

    const logUseLocation = getPropertiesByGroup('value', 'types.maintenance.types', 'use_location_field', false, true);
    const logUseMechanical = getPropertiesByGroup('value', 'types.maintenance.types', 'use_mechanical_field', false, true);
    const logUseEquipment = getPropertiesByGroup('value', 'types.maintenance.types', 'use_equipment_field', false, true);

    React.useEffect(()=>
    {
        let useLocation = false;
        let useMechanical = false;
        let useEquipment = false;

        if ( newType )
        {
            useLocation = newType ? ( logUseLocation.find(obj => {return obj.value == newType})?.use_location_field === 1 ? true : false ) : false;
            useMechanical = newType ? ( logUseMechanical.find(obj => {return obj.value == newType})?.use_mechanical_field === 1 ? true : false ) : false;
            useEquipment = newType ? ( logUseEquipment.find(obj => {return obj.value == newType})?.use_equipment_field === 1 ? true : false ) : false;

            setNewLocationVisible( useLocation );
            setNewMechanicalVisible( useMechanical );
            setNewEquipmentVisible( useEquipment );
        }
        else
        {
            setNewLocationVisible( false );
            setNewMechanicalVisible( false );
            setNewEquipmentVisible( false );
        }

        let theseEngineOptions = [];

        if ( newType && useMechanical )
        {
            theseEngineOptions = getEngineOptions(newTaskYacht, newType);
            setMechanicalOptions(theseEngineOptions);

            /*console.log({
                useLocation: useLocation,
                useMechanical: useMechanical,
                mechanicalOptions: getEngineOptions(newTaskYacht, newType),
            });*/
        }
        else
        {
            setEquipmentOptions(getEquipmentOptions(newTaskYacht));
            setMechanicalOptions(theseEngineOptions);
        }

    },[newType, newTaskYacht, setMechanicalOptions, setEquipmentOptions, getEquipmentOptions, setNewLocationVisible, setNewMechanicalVisible, setNewEquipmentVisible]);

    React.useEffect(()=>
    {
        if ( ! newTaskYacht || true !== enabledFeatures.sharing ) { return; }

        let theseOwnerOptions = getUserOptions(newTaskYacht);
        setOwnerOptions(theseOwnerOptions);

        // if newTaskOwner doesn't exist in the new ownerOptions, set it to an empty value
        if ( newTaskOwner && ! theseOwnerOptions.find(obj => {return obj.value == newTaskOwner}) )
        {
            setNewTaskOwner('');
        }

    },[newTaskYacht, setOwnerOptions, setNewTaskOwner, enabledFeatures]);

    React.useEffect(() => {
        const values = recurrence_fromPeriod(newRecurrenceDays);
        // console.log({newRecurrenceDays: newRecurrenceDays, values: values});
        if ( values?.typeId )
        {
            setNewRecurrenceInterval(values.interval);
            setNewRecurrenceType(values.typeId);
        }
    }, [newRecurrenceDays, setNewRecurrenceInterval, setNewRecurrenceType]);

    const yachtOptions = getYachtOptions();

    // ref for photo type
    const photoObjectType = React.useRef('obj_task');
    const newPhotosInput = React.useRef(null);
    const [contentType, setContentType] = React.useState(getLabel('DETAILS'));
    const [photos, setPhotos] = React.useState([]);
    const [viewingPhoto, setViewingPhoto] = React.useState(false);
    const [viewingPhotoObj, setViewingPhotoObj] = React.useState({});
    const [deleteFileModalActive, setDeleteFileModalActive] = React.useState(false);
    const [deleteFileModalAffectID, setDeleteFileModalAffectID] = React.useState('');
    const [isImageUploading, setIsImageLoading] = React.useState(false);
    const iDsContainingFiles = React.useRef([]);
    const prepareAssetPhotos = React.useCallback((asset)=>
    {
        let photoAssets = [];

        if ( getAssetPictures(asset.vessel_id, asset.id, userYachts).length )
        {
            let images = getAssetPictures(asset.vessel_id, asset.id, userYachts).sort(sort_by('modified_date', true));
            let imageWidth = (width - 75)/3;
            images.forEach((photo, index) => {
                photoAssets.push(
                    <Pressable
                        key={index}
                        style={tw`h-[${imageWidth}px] w-[${imageWidth}px] md:h-32 md:w-32`}
                        onPress={()=>{
                            // recalculate optimal image display width and height given the max height should be 80vh for md devices and height - 142 for sm devices, and a max width for md devices being 80vw and 100vw for sm devices
                            let displayWidth = width;
                            let displayHeight = height - 142;
                            if ( tw.prefixMatch('md') ) { displayWidth = Math.floor(width * 0.8); displayHeight = Math.floor(height * 0.8); }
                            if ( photo.width > displayWidth ) {
                                photo.height = Math.ceil((displayWidth / photo.width) * photo.height);
                                photo.width = displayWidth;
                            }
                            if ( photo.height > displayHeight ) {
                                photo.width = Math.floor((displayHeight / photo.height) * photo.width);
                                photo.height = displayHeight;
                            }
                            setViewingPhotoObj(photo);
                            setViewingPhoto(true);
                        }}
                    >
                        <Image
                            source={{ uri: photo.uri }}
                            resizeMode="cover"
                            style={[tw`bg-gray1 w-full h-full border-b border-white`, (index + 1) % 3 === 0 ? tw`border-r-0 md:border-r` : tw`border-r`]}
                        />
                    </Pressable>
                )
            });
        }

        return photoAssets;
    },[userYachts, width, height, labels, setViewingPhoto, setViewingPhotoObj]);
    let viewPhotoAnimation = useSpring(
    {
        from: {
            willChange: 'transform',
            zIndex: 91,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: tw.color('gray0'),
            boxShadow: 'rgb(0 0 0 / 50%) 0px 0px 24px',
            transform: 'translateX(110%)',
        },
        to: {
            transform: viewingPhoto ? 'translateX(0%)' : 'translateX(110%)',
        },
        config: viewingPhoto ? { mass: 1, tension: 190, friction: 30 } : { mass: 1, tension: 190, friction: 15 }
    });
    let viewPhotoAnimationDesktop = useSpring(
    {
        from: {
            willChange: 'opacity',
            zIndex: 91,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            opacity: 0,
            justifyContent: 'center',
            alignItems: 'center',
            pointerEvents: 'none',
        },
        to: {
            opacity: viewingPhoto ? 1 : 0,
            pointerEvents: viewingPhoto ? 'auto' : 'none',
        },
        config: viewingPhoto ? { mass: 1, tension: 190, friction: 30 } : { mass: 1, tension: 190, friction: 15 }
    });
    viewPhotoAnimation = tw.prefixMatch('md') ? viewPhotoAnimationDesktop : viewPhotoAnimation;

    // document specific inputs
    const [documentName, setDocumentName] = React.useState('');
    const [documentDesc, setDocumentDesc] = React.useState('');
    const [documentType, setDocumentType] = React.useState(0);
    const [documentExpiration, setDocumentExpiration] = React.useState('');
    const [documentTypeSelectVisible, setDocumentTypeSelectVisible] = React.useState(false);
    const [updateDocument, setUpdateDocument] = React.useState(false);
    const [updateDocumentID, setUpdateDocumentID] = React.useState(0);
    const [documents, setDocuments] = React.useState([]);
    const [documentData, setDocumentData] = React.useState('');
    const documentMimeType = React.useRef('');
    const [viewingDocument, setViewingDocument] = React.useState(false);
    const [viewingDocumentObj, setViewingDocumentObj] = React.useState({});
    const documentSize = React.useRef(0);
    const documentExt = React.useRef('');
    const documentInput = React.useRef(null);
    const documentNameInput = React.useRef(null);
    const documentFileNameInput = React.useRef(null);
    const documentDescInput = React.useRef(null);
    const documentExpirationInput = React.useRef(null);
    const documentTypeOptions = getOptionsByLabelGroup('types.attachment.types', true);
    const documentTypeIcons = getPropertiesByGroup( 'value', 'types.attachment.types', 'icon', false, true );
    const prepareAssetDocuments = React.useCallback((asset, editable)=>
    {
        if ( ! enabledFeatures.documents ) { return false; }

        let yachtObj = false;
        userYachts.every((value, index) => {
            if ( asset.vessel_id == value.id ) { yachtObj = value; return false; }
            return true;
        });

        let documentAssets = [];

        if ( getAssetDocuments(asset.vessel_id, asset.id, userYachts).length )
        {
            let documents = getAssetDocuments(asset.vessel_id, asset.id, userYachts).sort(sort_by('modified_date', true));
            documents.forEach((file, index) => {
                let icon = file.type_id ? documentTypeIcons.find(obj => {return obj.value == file.type_id})?.icon : 'miscellaneous';
                let icon_variant = '_white';
                icon = icon + icon_variant;
                icon = canIhazIcon( icon ) ? icon : 'miscellaneous' + icon_variant;
                file.expiration_tag = file.expiration_date ? ( is_date_within_30_days(file.expiration_date) ? ((is_date_in_past(file.expiration_date) ? labels?.current?.EXPIRED : labels?.current?.EXPIRING) ) : labels?.current?.EXPIRATION_VALID ) : labels?.current?.EXPIRATION_NONE;
                file.expiration_color = file.expiration_date ? ( is_date_within_30_days(file.expiration_date) ? ((is_date_in_past(file.expiration_date) ? 'red' : 'orange') ) : 'green' ) : 'gray5_5';
                documentAssets.push(
                    <AssetItem
                        key={index}
                        style={[editable === true && {width:'calc(100% - 18px)'}, tw`md:w-76`]}
                        minHeight={73}
                        color={themes[theme].brand_primary}
                        icon={{ uri: require('../svg/'+icon+'.svg') }}
                        title={ file.type_id ? documentTypeOptions.find(obj => {return obj.value == file.type_id})?.label : '' }
                        description={ file.name }
                        description2={ file.expiration_date ? file.expiration_tag +': '+YYYYMMDD_to_DMJYYYY(file.expiration_date) : ' ' }
                        tag={ file.expiration_tag }
                        tagColor={ file.expiration_color }
                        // description2={ file.mime_type }
                        menu={editable === true && [
                            { name:labels.current?.EDIT, color:'green', onPress: ()=>{
                                setUpdateDocumentID(file.id);
                                setDocumentName(file.name); documentNameInput.current.value = file.name;
                                setDocumentDesc(file.details); documentDescInput.current.value = file.details;
                                setDocumentExpiration(file.expiration_date); documentExpirationInput.current.value = file.expiration_date;
                                setDocumentType(file.type_id);
                                setUpdateDocument(true);
                            }},
                            { name:labels.current?.DELETE, color:'red', onPress: ()=>{
                                setDeleteFileModalAffectID(file.id);
                                documentMimeType.current = file.mime_type;
                                setDeleteFileModalActive(true);
                            }}
                        ]}
                        onPress={() => {
                            setViewingDocumentObj(file);
                            setViewingDocument(true);
                        }}
                        viewMore={labels.current?.VIEW}
                    />
                )
            });
        }

        return documentAssets;
    },[labels, userYachts, setUpdateDocumentID, setDocumentName, setDocumentExpiration, setDocumentDesc, setDocumentType, setUpdateDocument, documentNameInput, documentExpirationInput, documentDescInput, setDeleteFileModalAffectID, setDeleteFileModalActive, enabledFeatures]);
    let addNewDocumentPaneAnimation = useSpring(
    {
        from: {
            willChange: 'transform',
            zIndex: 91,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: tw.color('gray0'),
            boxShadow: 'rgb(0 0 0 / 50%) 0px 0px 24px',
            transform: 'translateX(110%)',
        },
        to: {
            transform: updateDocument ? 'translateX(0%)' : 'translateX(110%)',
        },
        config: updateDocument ? { mass: 1, tension: 190, friction: 30 } : { mass: 1, tension: 190, friction: 15 }
    });

    let addNewDocumentPaneAnimationDesktop = useSpring(
    {
        from: {
            willChange: 'opacity',
            zIndex: 91,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            opacity: 0,
            justifyContent: 'center',
            alignItems: 'center',
            pointerEvents: 'none',
            backdropFilter: 'blur(3px)',
        },
        to: {
            opacity: updateDocument ? 1 : 0,
            pointerEvents: updateDocument ? 'auto' : 'none',
        },
        config: updateDocument ? { mass: 1, tension: 190, friction: 30 } : { mass: 1, tension: 190, friction: 15 }
    });

    addNewDocumentPaneAnimation = tw.prefixMatch('md') ? addNewDocumentPaneAnimationDesktop : addNewDocumentPaneAnimation;

    let viewDocumentAnimation = useSpring(
    {
        from: {
            willChange: 'transform',
            zIndex: 91,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: tw.color('gray0'),
            boxShadow: 'rgb(0 0 0 / 50%) 0px 0px 24px',
            transform: 'translateX(110%)',
        },
        to: {
            transform: viewingDocument ? 'translateX(0%)' : 'translateX(110%)',
        },
        config: viewingDocument ? { mass: 1, tension: 190, friction: 30 } : { mass: 1, tension: 190, friction: 15 }
    });
    let viewDocumentAnimationDesktop = useSpring(
    {
        from: {
            willChange: 'opacity',
            zIndex: 91,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            opacity: 0,
            justifyContent: 'center',
            alignItems: 'center',
            pointerEvents: 'none',
        },
        to: {
            opacity: viewingDocument ? 1 : 0,
            pointerEvents: viewingDocument ? 'auto' : 'none',
        },
        config: viewingDocument ? { mass: 1, tension: 190, friction: 30 } : { mass: 1, tension: 190, friction: 15 }
    });
    viewDocumentAnimation = tw.prefixMatch('md') ? viewDocumentAnimationDesktop : viewDocumentAnimation;

    React.useEffect(()=> 
    {
        let assets = [];

        if ( userTasks.length )
        {
            let tasks = [];
            let tasks1 = [];
            let tasks2 = [];
            const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : false;
            
            switch(getFilter)
            {
                case labels.current?.COMPLETED:
                    updateRouteFilter('tasks', labels.current?.COMPLETED);
                    tasks = userTasks.filter(function (el)
                    {
                        return el.is_complete;
                    });
                    tasks = [...tasks].sort(sort_by('priority_value, due_date', true));
                    break;

                case labels.current?.ASSIGNED_BY_ME:
                    updateRouteFilter('tasks', labels.current?.ASSIGNED_BY_ME);
                    tasks = userTasks.filter(function (el)
                    {
                        return el.create_user_id == user.id;
                    });
                    // tasks = [...tasks].sort(sort_by('!is_complete, priority_value, !due_date', true));
                    tasks1 = [...tasks].filter(function (el)
                    {
                        return ! el.is_complete;
                    });
                    tasks1 = [...tasks1].sort(sort_by('!priority_value, due_date', false));
                    tasks2 = [...tasks].filter(function (el)
                    {
                        return el.is_complete;
                    });
                    tasks2 = [...tasks2].sort(sort_by('due_date', true));
                    tasks = tasks1.concat(tasks2);
                    break;

                case labels.current?.ASSIGNED_TO_ME:
                    updateRouteFilter('tasks', labels.current?.ASSIGNED_TO_ME);
                    tasks = userTasks.filter(function (el)
                    {
                        return el.assignee_user_id == user.id;
                    });
                    // tasks = [...tasks].sort(sort_by('!is_complete, priority_value, !due_date', true));
                    tasks1 = [...tasks].filter(function (el)
                    {
                        return ! el.is_complete;
                    });
                    tasks1 = [...tasks1].sort(sort_by('!priority_value, due_date', false));
                    tasks2 = [...tasks].filter(function (el)
                    {
                        return el.is_complete;
                    });
                    tasks2 = [...tasks2].sort(sort_by('due_date', true));
                    tasks = tasks1.concat(tasks2);
                    break;

                case labels.current?.OVERDUE:
                    updateRouteFilter('tasks', labels.current?.OVERDUE);
                    tasks = userTasks.filter(function (el)
                    {
                        return ! el.is_complete && is_date_in_past(el.due_date);
                    });
                    tasks = [...tasks].sort(sort_by('!priority_value, due_date', false));
                    break;

                case labels.current?.TODO:
                    updateRouteFilter('tasks', labels.current?.TODO);
                    tasks = userTasks.filter(function (el)
                    {
                        return ! el.is_complete;
                    });
                    tasks = [...tasks].sort(sort_by('!priority_value, due_date', false));
                    break;

                case labels.current?.LOW_PRIORITY:
                    updateRouteFilter('tasks', labels.current?.LOW_PRIORITY);
                    tasks = userTasks.filter(function (el)
                    {
                        return 1 === el.priority_id;
                    });
                    // tasks = [...tasks].sort(sort_by('!is_complete, due_date', true));
                    tasks1 = [...tasks].filter(function (el)
                    {
                        return ! el.is_complete;
                    });
                    tasks1 = [...tasks1].sort(sort_by('due_date', false));
                    tasks2 = [...tasks].filter(function (el)
                    {
                        return el.is_complete;
                    });
                    tasks2 = [...tasks2].sort(sort_by('due_date', true));
                    tasks = tasks1.concat(tasks2);
                    break;

                case labels.current?.MEDIUM_PRIORITY:
                    updateRouteFilter('tasks', labels.current?.MEDIUM_PRIORITY);
                    tasks = userTasks.filter(function (el)
                    {
                        return 2 === el.priority_id;
                    });
                    // tasks = [...tasks].sort(sort_by('!is_complete, due_date', true));
                    tasks1 = [...tasks].filter(function (el)
                    {
                        return ! el.is_complete;
                    });
                    tasks1 = [...tasks1].sort(sort_by('due_date', false));
                    tasks2 = [...tasks].filter(function (el)
                    {
                        return el.is_complete;
                    });
                    tasks2 = [...tasks2].sort(sort_by('due_date', true));
                    tasks = tasks1.concat(tasks2);
                    break;

                case labels.current?.HIGH_PRIORITY:
                    updateRouteFilter('tasks', labels.current?.HIGH_PRIORITY);
                    tasks = userTasks.filter(function (el)
                    {
                        return 3 === el.priority_id;
                    });
                    // tasks = [...tasks].sort(sort_by('!is_complete, due_date', true));
                    tasks1 = [...tasks].filter(function (el)
                    {
                        return ! el.is_complete;
                    });
                    tasks1 = [...tasks1].sort(sort_by('due_date', false));
                    tasks2 = [...tasks].filter(function (el)
                    {
                        return el.is_complete;
                    });
                    tasks2 = [...tasks2].sort(sort_by('due_date', true));
                    tasks = tasks1.concat(tasks2);
                    break;

                case labels.current?.CRITICAL_PRIORITY:
                    updateRouteFilter('tasks', labels.current?.CRITICAL_PRIORITY);
                    tasks = userTasks.filter(function (el)
                    {
                        return 4 === el.priority_id;
                    });
                    // tasks = [...tasks].sort(sort_by('!is_complete, due_date', true));
                    tasks1 = [...tasks].filter(function (el)
                    {
                        return ! el.is_complete;
                    });
                    tasks1 = [...tasks1].sort(sort_by('due_date', false));
                    tasks2 = [...tasks].filter(function (el)
                    {
                        return el.is_complete;
                    });
                    tasks2 = [...tasks2].sort(sort_by('due_date', true));
                    tasks = tasks1.concat(tasks2);
                    break;

                case labels.current?.ALL:
                default:
                    updateRouteFilter('tasks', labels.current?.ALL);
                    tasks1 = [...userTasks].filter(function (el)
                    {
                        return ! el.is_complete;
                    });
                    tasks1 = [...tasks1].sort(sort_by('!priority_value, due_date', false));
                    tasks2 = [...userTasks].filter(function (el)
                    {
                        return el.is_complete;
                    });
                    tasks2 = [...tasks2].sort(sort_by('due_date', true));
                    tasks = tasks1.concat(tasks2);
                    break;
            }
                
            tasks.forEach((asset, index) => {
                asset.status = asset.is_complete ? labels.current?.COMPLETED : ( is_date_in_past(asset.due_date) ? labels.current?.OVERDUE : labels.current?.TODO );
                photos[asset.id] = prepareAssetPhotos(asset);
                if ( asset.pictures?.length > 0 && ! iDsContainingFiles.current.includes(asset.id) ) { iDsContainingFiles.current.push(asset.id); }
                documents[asset.id] = prepareAssetDocuments(asset, 0 !== updateID);
                if ( asset.documents?.length > 0 && ! iDsContainingFiles.current.includes(asset.id) ) { iDsContainingFiles.current.push(asset.id); }

                // console.log({userID:userID, assignee_user_id:asset.assignee_user_id});
                if ( userID === asset.assignee_user_id ) { asset.assignee_user_name = asset.assignee_name + ' (Me)'; }
                else { asset.assignee_user_name = asset.assignee_name; }
                if ( asset.is_recurring && ! recurringTaskIDs.current.includes(asset.id) ) { recurringTaskIDs.current.push(asset.id); }
                // console.log(recurringTaskIDs.current);
                const assetMenu = [
                    { name:labels.current?.EDIT, color:'green', onPress: ()=>{
                        setViewingAssetObj(asset);
                        setUpdateID(asset.id);
                        setContentType(labels.current?.DETAILS);
                        documents[asset.id] = prepareAssetDocuments(asset, true);

                        newStatusText.current = asset.status;
                        newCompleteDateText.current = asset.complete_date ? date_DMJYYYY_TIME(asset.complete_date, true) : '';
                        is_recurrence_locked.current = asset.is_complete || asset.is_recurrence_locked;

                        newTaskNameInput.current.value = asset.name;
                        newNotesInput.current.value = asset.details || '';
                        newTaskDateInput.current.value = asset.due_date;
                        newHoursDueInput.current.value = asset.due_hours;
                        newTaskAlternateInput.current.value = asset.assignee_alternate;
                        setOwnerOptions(getUserOptions(asset.vessel_id));
                        setNewTaskOwner(asset.assignee_user_id);
                        setNewTaskAlternate(asset.assignee_alternate);
                        setNewTaskName(asset.name);
                        setNewTaskYacht(asset.vessel_id);
                        setNewTaskDate(asset.due_date);
                        setNewHoursDue(asset.due_hours || '');
                        setNewNotes(asset.details || '');
                        setNewPriority(asset.priority_id || 2);
                        setNewIsComplete(asset.is_complete || false);
                        setNewIsRecurring(asset.is_recurring || 0);
                        setNewRecurrenceType(asset.recurrence_type || null);
                        setNewRecurrenceInterval(asset.recurrence_interval || null);
                        setNewRecurrenceDays(asset.recurrence_days || 0);
                        setNewType(asset.type_id);
                        setNewLocation(asset.location_id);
                        setNewMechanicalID(asset.mechanical_id || '');
                        setNewEquipmentID(asset.equipment_id || '');

                        setAddingNew(true);
                    }},

                ];
                if ( ! asset.is_complete && 1 !== asset.is_recurrence_locked ) {
                    assetMenu.push(
                        { name:labels.current?.TASK_MARK_COMPLETE, color:'blue', onPress: ()=>{ setQuickCompleteModalAffectObject(asset); setQuickCompleteModalActive(true) } }
                    );
                }
                assetMenu.push(
                    { name:labels.current?.DELETE, color:'red', onPress: ()=>{ setModalAffectID(asset.id); setModalActive(true) } }
                );
                let formattedDate = YYYYMMDD_to_DMJYYYY(asset.due_date);
                let formattedCompleteDate = date_DMJYYYY(asset.complete_date);
                let priorityColor = 'gray5_5';

                if ( ! asset.is_complete )
                {
                    switch(asset.priority_id)
                    {
                        case 1:
                            priorityColor = 'green';
                            break;
                        case 2:
                            priorityColor = 'blue';
                            break;
                        case 3:
                            priorityColor = 'orange';
                            break;
                        case 4:
                            priorityColor = 'red';
                            break;
                    }
                }

                assets.push(
                    <AssetItem
                        key={asset.id}
                        minHeight={130}
                        title={asset.name}
                        subtitle={ ( getAssetPictures(asset.vessel_id, asset.id, userYachts).length > 0 || getAssetDocuments(asset.vessel_id, asset.id, userYachts)?.length > 0 ) &&
                            <View style={tw`flex-row`}>
                                { getAssetPictures(asset.vessel_id, asset.id, userYachts).length > 0 && enabledFeatures.photos &&
                                    <Image
                                        accessibilityHidden={true}
                                        source={{ uri: require('../svg/photos_lightgray.svg') }}
                                        resizeMode="contain"
                                        style={tw`h-4 w-4 mt-1 ml-2`}
                                    />
                                }
                                { getAssetDocuments(asset.vessel_id, asset.id, userYachts)?.length > 0 && enabledFeatures.documents &&
                                    <Image
                                        accessibilityHidden={true}
                                        source={{ uri: require('../svg/attachment_lightgray.svg') }}
                                        resizeMode="contain"
                                        style={tw`h-4 w-4 mt-1 ml-2`}
                                    />
                                }
                            </View>
                        }
                        titleIcon={asset.is_recurring || asset.is_recurrence_locked ? { uri: require('../svg/recurring_black.svg') } : ''}
                        titleIcon2={parseInt(asset.due_hours) ? { uri: require('../svg/enginehours_black.svg') } : ''}
                        label={asset.status}
                        color={asset.is_complete ? 'gray5_5' : ( is_date_in_past(asset.due_date) ? 'red' : 'green' ) }
                        boat={asset.vessel_name}
                        owner={asset.assignee_alternate || asset.assignee_user_name}
                        due={asset.is_complete ? false : formattedDate}
                        date={asset.is_complete ? getLabel('COMPLETED')+' '+ formattedCompleteDate : false}
                        tag={asset.priority_id ? priorityOptions.find(obj => {return obj.value == asset.priority_id})?.label : ''}
                        tagColor={priorityColor}
                        menu={assetMenu}
                        onPress={() => { 
                            if ( asset.is_recurring )
                            {
                                const values = recurrence_fromPeriod(asset.recurrence_days);
                                // console.log({newRecurrenceDays: newRecurrenceDays, values: values});
                                if ( values?.typeId )
                                {
                                    asset.recurrence_interval = values.interval;
                                    asset.recurrence_type = values.typeId;
                                }
                            }

                            newStatusText.current = asset.status;
                            newCompleteDateText.current = asset.complete_date ? date_DMJYYYY_TIME(asset.complete_date, true) : '';
                            documents[asset.id] = prepareAssetDocuments(asset, false);
                            setContentType(labels.current?.DETAILS);
                            setViewingAssetObj(asset);
                            setViewingAsset(true);
                        }}
                    />
                )
            });         
        }
        
        setAssets(assets);
    },[getFilter, userTasks, labels]);

    let addNewPaneAnimation = useSpring(
    {
        from: {
            willChange: 'transform',
            zIndex: 91,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: tw.color('gray0'),
            boxShadow: 'rgb(0 0 0 / 50%) 0px 0px 24px',
            transform: 'translateX(110%)',
        },
        to: {
            transform: addingNew ? 'translateX(0%)' : 'translateX(110%)',
        },
        config: addingNew ? { mass: 1, tension: 190, friction: 30 } : { mass: 1, tension: 190, friction: 15 }
    });

    let addNewPaneAnimationDesktop = useSpring(
    {
        from: {
            willChange: 'opacity',
            zIndex: 91,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            opacity: 0,
            justifyContent: 'center',
            alignItems: 'center',
            pointerEvents: 'none',
            backdropFilter: 'blur(3px)',
        },
        to: {
            opacity: addingNew ? 1 : 0,
            pointerEvents: addingNew ? 'auto' : 'none',
        },
        config: addingNew ? { mass: 1, tension: 190, friction: 30 } : { mass: 1, tension: 190, friction: 15 }
    });

    addNewPaneAnimation = tw.prefixMatch('md') ? addNewPaneAnimationDesktop : addNewPaneAnimation;

    let viewPaneAnimation = useSpring(
    {
        from: {
            willChange: 'transform',
            zIndex: 91,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: tw.color('gray0'),
            boxShadow: 'rgb(0 0 0 / 50%) 0px 0px 24px',
            transform: 'translateX(110%)',
        },
        to: {
            transform: viewingAsset ? 'translateX(0%)' : 'translateX(110%)',
        },
        config: viewingAsset ? { mass: 1, tension: 190, friction: 30 } : { mass: 1, tension: 190, friction: 15 }
    });

    let viewPaneAnimationDesktop = useSpring(
    {
        from: {
            willChange: 'opacity',
            zIndex: 91,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            opacity: 0,
            justifyContent: 'center',
            alignItems: 'center',
            pointerEvents: 'none',
            backdropFilter: 'blur(3px)',
        },
        to: {
            opacity: viewingAsset ? 1 : 0,
            pointerEvents: viewingAsset ? 'auto' : 'none',
        },
        config: viewingAsset ? { mass: 1, tension: 190, friction: 30 } : { mass: 1, tension: 190, friction: 15 }
    });

    viewPaneAnimation = tw.prefixMatch('md') ? viewPaneAnimationDesktop : viewPaneAnimation;

    return (
        <View label="myTasks" style={tw`bg-gray0 h-screen md:flex-row overflow-hidden max-w-screen`}>

            <PrimaryNav navigation={navigation} setLoggedIn={setLoggedIn} />
        
            <View style={tw`bg-[${themes[theme].brand_primary}] h-8 md:h-full md:w-10 z-0 -mb-3 md:mb-0 md:-mr-3`}>
                <View style={tw`bg-gray0 h-full w-full rounded-t-3xl md:rounded-l-3xl md:rounded-r-0 z-10 md:shadow-black md:shadow-opacity-10 md:shadow-radius-4`}></View>
            </View>
            
            <View style={[tw`bg-gray0 grow md:pr-8 md:pl-4 z-20`, {width: tw.prefixMatch('md') ? 'calc(100% - 320px)' : '100%', height: tw.prefixMatch('md') ? '100%' : 'calc(100% - 112px)'}]}>
                
                <View style={tw`h-13 px-5 pb-5 md:py-0 md:px-0 md:h-24 lg:h-33 flex-row md:items-center z-10`}>
                    
                    <Heading style={tw`mt-1.5 md:mt-0 leading-4 mb-0`} hlevel={1} styleHlevel={tw.prefixMatch('md') ? 2 : 4}>{ labels.current?._ASSETS }</Heading>

                    { userTasks.length > 0 &&
                    
                        <Pressable
                            style={tw`border border-gray3 py-1 px-2 md:py-1.5 md:px-3 rounded flex-row md:mr-4 md:min-w-60`}
                            onPress={()=>{setFilterVisible(true)}}
                        >
                            <Image
                                accessibilityHidden={true}
                                source={{ uri: require('../svg/sort.svg') }}
                                resizeMode="contain"
                                style={tw`h-4 w-4 top-0.5 md:top-1 mr-2`}
                            />
                            
                            <Span style={tw`text-sm md:text-base leading-4`}>{ labels.current?.VIEW }: <Span style={tw`text-sm md:text-base font-medium`}>{getFilter}</Span></Span>
                            
                        </Pressable>
                    }
                    
                </View>

                <View style={! getAssets.length ? tw`grow` : tw`shrink`}>
                    <ScrollView
                        centerContent={getAssets.length ? false : true}
                        style={{width:'100%',overflowX:'visible'}}
                        contentContainerStyle={tw`w-full min-h-full justify-start`}
                    >
                        { '' !== primaryMsg &&
                            <View style={tw`w-full px-4 md:px-1`}>
                                <Notification
                                    style={tw`my-4`}
                                    type={primaryMsgType}
                                >{primaryMsg}</Notification>
                            </View>
                        }

                        { ! getAssets.length &&
                            <View style={tw`justify-center h-full items-center`}>
                                
                                <Image
                                    accessibilityHidden={true}
                                    source={{ uri: require('../svg/tasks_gray.svg') }}
                                    resizeMode="contain"
                                    style={tw`h-32 w-32 md:h-48 md:w-48`}
                                />                            
                                <Heading style={tw`text-gray5 text-center p-5 mb-26`} hlevel={tw.prefixMatch('md') ? 2 : 3}>{ userTasks.length > 0 ? getLabel('SEARCH_NOTFOUND_TITLE') : labels.current?._404_TEXT }</Heading>
                                
                                <View style={tw`fixed bottom-9 md:bottom-18 right-18 pointer-events-none`}>
                                    
                                    <Span style={tw`text-sm md:text-base font-medium font-sans mb-2 mr-6`}>{ labels.current?.CONTENT_ADD }</Span>
                                    
                                    <Image
                                        accessibilityHidden={true}
                                        source={{ uri: require('../svg/'+theme+'/arrow_organic.svg') }}
                                        resizeMode="contain"
                                        style={tw`h-9 w-18 left-14 md:left-16`}
                                    />
                                </View>
                            </View>
                        }
                        
    
                        { getAssets.length > 0 && 
                            <View style={tw`md:flex-wrap md:flex-row mb-20 md:justify-start md:items-start`}>
                                {getAssets}
                            </View>
                        }
                        
                    </ScrollView>

                    <AddNewAssetButtons addNewLabel={ labels.current?.ADD +' '+ labels.current?._ASSET } onAddNew={()=>{
                        setUpdateID(0);
                        setContentType(labels.current?.DETAILS);
                        setNewAssetMsg('');

                        newTaskNameInput.current.value = '';
                        newNotesInput.current.value = '';
                        newTaskDateInput.current.value = '';
                        newHoursDueInput.current.value = '';
                        newTaskAlternateInput.current.value = '';
                        is_recurrence_locked.current = 0;
                        setNewTaskName('');
                        setNewTaskOwner(true === enabledFeatures.sharing ? '' : user.id);
                        setNewTaskAlternate('');
                        setNewTaskYacht('');
                        setNewTaskDate('');
                        setNewHoursDue('');
                        setNewNotes('');
                        setNewPriority(0);
                        setNewIsComplete(false);
                        setNewIsRecurring(0);
                        setNewRecurrenceType(null);
                        setNewRecurrenceInterval(null);
                        setNewRecurrenceDays(0);
                        setNewType('');
                        setNewLocation('');
                        setNewMechanicalID('');
                        setNewEquipmentID('');

                        setTimeout(()=>{
                            setAddingNew(true);
                        }, 250);
                    }} />
                </View>
            </View>

            <AnimatedView style={addNewPaneAnimation}>

                <Pressable style={tw`hidden md:flex bg-[${themes[theme].brand_primary}]/75 backdrop-blur-sm w-screen h-screen left-0 top-0 fixed`}
                    onPress={() => {setNewAssetMsg(''); setAddingNew(false); setNewRecurrenceDays(0)}}
                ></Pressable>

                <View style={tw`md:w-188 md:rounded-md md:shadow-black md:shadow-opacity-25 md:shadow-radius-8`}>
                
                    <View style={tw`bg-[${themes[theme].brand_primary}] md:bg-gray0 md:border-b-2 md:border-gray1 md:items-center h-20 p-5 md:px-9 pt-6.5 flex-row md:rounded-t-md`}>
                        <Pressable
                            style={tw`p-2 -ml-2 -mt-2`}
                            onPress={() => {setNewAssetMsg(''); setAddingNew(false); setNewRecurrenceDays(0)}}
                        >
                            <Image
                                accessibilityLabel={ labels.current?.CANCEL }
                                source={{ uri: tw.prefixMatch('md') ? require('../svg/'+theme+'/back.svg') : require('../svg/back_white.svg') }}
                                resizeMode="contain"
                                style={tw`h-6 w-6 top-px md:top-1 md:mr-4`}
                            />
                        </Pressable>
                        <Heading style={tw`mt-1.5 md:mt-0 leading-4 mb-0 text-white md:text-ink`} hlevel={1} styleHlevel={tw.prefixMatch('md') ? 2 : 4}>{ ( 0 !== updateID ? labels.current?.EDIT : labels.current?.ADD ) +' '+ labels.current?._ASSET }</Heading>
                    </View>
                
                    <View style={tw`bg-[${themes[theme].brand_primary}] h-8 z-0 -mb-3 md:hidden`}>
                        <View style={tw`bg-gray0 h-full w-full rounded-t-3xl`}></View>
                    </View>
        
                    <View style={[tw`bg-gray0 grow md:pr-4 md:pb-5 md:pt-8 md:pl-4 z-20 md:rounded-b-md w-full`, { height: tw.prefixMatch('md') ? 'max-content' : formHeight, maxHeight: formHeight }]}>
                        <View style={tw`h-full`}>

                            { 0 !== updateID &&
                                <PillMenu
                                    style={tw`w-auto mx-5.5 mb-3 md:mb-4 md:-mt-2`}
                                    selected={ contentType }
                                    menu={[
                                        { name:labels.current?.DETAILS, icon:{ uri: require('../svg/notes_white.svg') }, inactiveIcon:{ uri: require('../svg/'+theme+'/notes.svg') }, onPress:()=>{ setContentType(labels.current?.DETAILS); } },
                                        ...(enabledFeatures.photos ? [{ name:labels.current?.PHOTOS, icon:{ uri: require('../svg/photos_white.svg') }, inactiveIcon:{ uri: require('../svg/'+theme+'/photos.svg') }, onPress:()=>{ setContentType(labels.current?.PHOTOS); } }] : []),
                                        ...(enabledFeatures.documents ? [{ name: labels.current?.DOCUMENTS, icon: { uri: require('../svg/attachment_white.svg') }, inactiveIcon: { uri: require('../svg/'+theme+'/attachment.svg') }, onPress: () => { setContentType(labels.current?.DOCUMENTS); } }] : [])
                                    ]}
                                />
                            }

                            <ScrollView
                                style={{width:'100%',overflowX:tw.prefixMatch('md') ? 'visible' : 'hidden'}}
                                contentContainerStyle={tw`w-full justify-start px-5 pb-5 md:pb-0`}
                            >
                                { '' !== newAssetMsg &&
                                    <View style={tw`w-full md:pr-2`}>
                                        <Notification
                                            style={tw`my-2`}
                                            type={newAssetMsgType}
                                        >{newAssetMsg}</Notification>
                                    </View>
                                }

                                <View style={[tw`md:flex-row md:flex-wrap md:justify-between`, labels?.current?.DETAILS !== contentType && tw`hidden`]}>

                                    <View style={tw`w-full md:flex-row md:justify-between mt-0 mb-4`}>
                                        { 0 !== updateID &&
                                            <Heading hlevel={2} style={tw`text-[18px] leading-4 text-ink self-center`}>{ labels.current?.STATUS }: { newStatusText.current } { newCompleteDateText.current }</Heading>
                                        }

                                        { 0 !== updateID &&
                                            <View style={[tw`flex-row items-center`, is_recurrence_locked.current && tw`pointer-events-none opacity-50`]}>
                                                <Pressable
                                                    style={[tw`py-2 px-3 rounded-l-xl border border-[${themes[theme].brand_primary}] bg-gray0`, ! newIsComplete && tw`bg-[${themes[theme].brand_primary}]`]}
                                                    onPress={()=>{
                                                        if ( true === newIsComplete || 1 === newIsComplete ) { setNewIsComplete(false); }
                                                    }}
                                                >
                                                    <Span style={[tw`text-xs text-[${themes[theme].brand_primary}]`, ! newIsComplete && tw`text-gray0`]}>{ labels.current?.TODO }</Span>
                                                </Pressable>
                                                <Pressable
                                                    style={[tw`py-2 px-3 md:mr-2 rounded-r-xl border border-[${themes[theme].brand_primary}] bg-[${themes[theme].brand_primary}]`, ! newIsComplete && tw`bg-gray0`]}
                                                    onPress={()=>{
                                                        if ( false === newIsComplete || 0 === newIsComplete ) { setNewIsComplete(true); }
                                                    }}
                                                >
                                                    <Span style={[tw`text-xs text-gray0`, ! newIsComplete && tw`text-[${themes[theme].brand_primary}]`]}>{ labels.current?.COMPLETED }</Span>
                                                </Pressable>
                                            </View>
                                        }
                                    </View>

                                    <Pressable
                                        style={tw`mt-0 md:w-full`}
                                        onPress={()=>{setYachtSelectVisible(true)}}
                                    >
                                        <TextInput
                                            style={tw`pointer-events-none`}
                                            autoCapitalize="on"
                                            placeholder={ labels.current?.YACHT +'*' }
                                            image={ newTaskYacht ? { uri: require('../svg/'+theme+'/vesseltype.svg') } : { uri: require('../svg/vesseltype_gray.svg') }}
                                            value={newTaskYacht ? yachtOptions.find(obj => {return obj.value == newTaskYacht}).label : ''}
                                            required
                                        />

                                        <Image
                                            accessibilityHidden={true}
                                            source={{ uri: require('../svg/'+theme+'/chevron_down_blue.svg') }}
                                            resizeMode="contain"
                                            style={tw`h-3 w-3 absolute top-1/2 -translate-y-1/2 right-4`}
                                        />
                                    </Pressable>
                                    
                                    <TextInput
                                        style={ tw`mt-2 md:mt-4 md:w-48.5/100`}
                                        autoCapitalize="on"
                                        placeholder={ labels.current?.NAME +'*' }
                                        image={ newTaskName ? { uri: require('../svg/'+theme+'/task_blue.svg') } : { uri: require('../svg/task_gray.svg') }}
                                        // onChangeText={(value)=>{setNewTaskName(value)}}
                                        pattern="^.{2,}$"
                                        validateGroup={newAssetValidation}
                                        validatedValue={(value) => {setNewTaskName(value)}}
                                        validateOnChange={true}
                                        stateValue={newTaskName}
                                        passedRef={newTaskNameInput}
                                        required
                                    />

                                    <Pressable
                                            style={tw`mt-2 md:mt-4 md:w-48.5/100`}
                                            onPress={()=>{setPrioritySelectVisible(true)}}
                                    >
                                        <TextInput
                                            style={tw`pointer-events-none`}
                                            autoCapitalize="on"
                                            placeholder={ labels.current?.PRIORITY +' *' }
                                            image={ newPriority ? { uri: require('../svg/'+theme+'/triangleExclamation.svg') } : { uri: require('../svg/triangleExclamation_gray.svg') }}
                                            value={ newPriority ? priorityOptions.find(obj => {return obj.value == newPriority})?.label : ''}
                                            required
                                        />

                                        <Image
                                            accessibilityHidden={true}
                                            source={{ uri: require('../svg/'+theme+'/chevron_down_blue.svg') }}
                                            resizeMode="contain"
                                            style={tw`h-3 w-3 absolute top-1/2 -translate-y-1/2 right-4`}
                                        />
                                    </Pressable>

                                    { true === enabledFeatures.sharing &&
                                        <Pressable
                                            style={[tw`mt-2 md:mt-4 md:w-48.5/100`, ! newTaskYacht && tw`grayscale cursor-not-allowed`]}
                                            onPress={()=>{newTaskYacht ? setOwnerSelectVisible(true) : setOwnerSelectVisible(false)}}
                                        >
                                            <TextInput
                                                style={tw`pointer-events-none`}
                                                autoCapitalize="on"
                                                placeholder={ labels.current?.ASSIGNED_TO +'*' }
                                                image={ newTaskOwner ? { uri: require('../svg/'+theme+'/user.svg') } : { uri: require('../svg/user_gray.svg') }}
                                                disabled={ newTaskYacht ? false : true }
                                                value={ newTaskOwner ? ownerOptions.find(obj => {return obj.value == newTaskOwner})?.label : ''}
                                                required
                                            />

                                            <Image
                                                accessibilityHidden={true}
                                                source={{ uri: require('../svg/'+theme+'/chevron_down_blue.svg') }}
                                                resizeMode="contain"
                                                style={tw`h-3 w-3 absolute top-1/2 -translate-y-1/2 right-4`}
                                            />
                                        </Pressable>
                                    }

                                    <TextInput
                                        style={ tw`mt-2 md:mt-4 md:w-48.5/100`}
                                        autoCapitalize="on"
                                        placeholder={ labels.current?.ASSIGNEE_ALTERNATE }
                                        image={ newTaskAlternate ? { uri: require('../svg/'+theme+'/user.svg') } : { uri: require('../svg/user_gray.svg') }}
                                        // onChangeText={(value)=>{setNewTaskAlternate(value)}}
                                        pattern="^.{2,100}$"
                                        validateGroup={newAssetValidation}
                                        validatedValue={(value) => {setNewTaskAlternate(value)}}
                                        validateOnChange={true}
                                        stateValue={newTaskAlternate}
                                        passedRef={newTaskAlternateInput}
                                    />
                                    
                                    <SimpleTextInput
                                        type="date"
                                        style={tw`mt-2 md:mt-4 md:w-48.5/100`}
                                        autoCapitalize="off"
                                        prefix={ labels.current?.DUE +"*" }
                                        placeholder={labels.current?.DUE +" "+ labels.current?.DATE +"*"}
                                        image={ newTaskDate ? { uri: require('../svg/'+theme+'/calendar_blue.svg') } : { uri: require('../svg/calendar_gray.svg') }}
                                        validateGroup={newAssetValidation}
                                        onChange={(e) => {setNewTaskDate(e.target.value)}}
                                        onFocus={(e) => {newTaskDateInput?.current && newTaskDateInput.current.showPicker()}}
                                        onKeyDown={(e) => {e.preventDefault();}}
                                        passedRef={newTaskDateInput}
                                        required
                                    />

                                    <TextInput
                                        style={tw`mt-2 md:mt-4 md:w-48.5/100`}
                                        keyboardType="numeric"
                                        prefix={ labels.current?.DUE_HOURS }
                                        image={ newHoursDue ? { uri: require('../svg/'+theme+'/enginehours_blue.svg') } : { uri: require('../svg/enginehours_gray.svg') }}
                                        // onChangeText={(value)=>{setNewHoursDue(value)}}
                                        pattern="^[0-9]*$"
                                        validateGroup={newAssetValidation}
                                        validatedValue={(value) => {setNewHoursDue(value)}}
                                        validateOnChange={true}
                                        stateValue={newHoursDue}
                                        passedRef={newHoursDueInput}
                                    />

                                    <Span style={tw`text-sm text-gray6 my-2 w-full`}>{ '* ' + labels.current?.REQUIRED }</Span>

                                    <Pressable
                                        style={tw`mt-2 md:mt-4 md:w-48.5/100`}
                                        onPress={()=>{setTypeSelectVisible(true)}}
                                    >
                                        <TextInput
                                            style={tw`pointer-events-none`}
                                            placeholder={ labels.current?.TYPE }
                                            image={ newType ? { uri: require('../svg/'+theme+'/maintenanceType_blue.svg') } : { uri: require('../svg/maintenanceType_gray.svg') }}
                                            value={newType ? typeOptions.find(obj => {return obj.value == newType})?.label : ''}
                                        />

                                        <Image
                                            accessibilityHidden={true}
                                            source={{ uri: require('../svg/'+theme+'/chevron_down_blue.svg') }}
                                            resizeMode="contain"
                                            style={tw`h-3 w-3 absolute top-1/2 -translate-y-1/2 right-4`}
                                        />
                                    </Pressable>

                                    <Pressable
                                        style={[tw`mt-2 md:mt-4 md:w-48.5/100`, true !== newLocationVisible ? tw`hidden` : ( newEquipmentID ? tw`grayscale cursor-not-allowed` : {} )]}
                                        onPress={newEquipmentID ? ()=>{return false} : ()=>{setLocationSelectVisible(true)}}
                                    >
                                        <TextInput
                                            style={tw`pointer-events-none`}
                                            placeholder={ labels.current?.LOCATION }
                                            image={ newLocation ? { uri: require('../svg/'+theme+'/location_blue.svg') } : { uri: require('../svg/location_gray.svg') }}
                                            value={newLocation ? locationOptions.find(obj => {return obj.value == newLocation})?.label : ''}
                                            disabled={newEquipmentID ? true : false}
                                        />

                                        <Image
                                            accessibilityHidden={true}
                                            source={{ uri: require('../svg/'+theme+'/chevron_down_blue.svg') }}
                                            resizeMode="contain"
                                            style={tw`h-3 w-3 absolute top-1/2 -translate-y-1/2 right-4`}
                                        />
                                    </Pressable>

                                    <Pressable
                                        style={[tw`mt-2 md:mt-4 md:w-48.5/100`, true !== newMechanicalIDVisible ? tw`hidden` : {}, ! newTaskYacht && tw`grayscale cursor-not-allowed`]}
                                        onPress={()=>{newTaskYacht ? setMechanicalSelectVisible(true) : setMechanicalSelectVisible(false)}}
                                    >
                                        <TextInput
                                            style={tw`pointer-events-none`}
                                            placeholder={ labels.current?.ENGINE }
                                            image={ newMechanicalID ? { uri: require('../svg/'+theme+'/engine.svg') } : { uri: require('../svg/engine_gray.svg') }}
                                            disabled={ newTaskYacht ? false : true }
                                            value={newMechanicalID ? mechanicalOptions.find(obj => {return obj.value == newMechanicalID})?.label : ''}
                                        />

                                        <Image
                                            accessibilityHidden={true}
                                            source={{ uri: require('../svg/'+theme+'/chevron_down_blue.svg') }}
                                            resizeMode="contain"
                                            style={tw`h-3 w-3 absolute top-1/2 -translate-y-1/2 right-4`}
                                        />
                                    </Pressable>

                                    <Pressable
                                        style={[tw`mt-2 md:mt-4 md:w-48.5/100`, true !== newEquipmentVisible ? tw`hidden` : {}, ! newTaskYacht && tw`grayscale cursor-not-allowed`]}
                                        onPress={()=>{newTaskYacht ? setEquipmentSelectVisible(true) : setEquipmentSelectVisible(false)}}
                                    >
                                        <TextInput
                                            style={tw`pointer-events-none`}
                                            placeholder={ labels.current?.EQUIPMENT }
                                            image={ newEquipmentID ? { uri: require('../svg/'+theme+'/equipment.svg') } : { uri: require('../svg/equipment_gray.svg') }}
                                            disabled={ newTaskYacht ? false : true }
                                            value={newEquipmentID ? allEquipmentOptions.find(obj => {return obj.value == newEquipmentID})?.label : ''}
                                        />

                                        <Image
                                            accessibilityHidden={true}
                                            source={{ uri: require('../svg/'+theme+'/chevron_down_blue.svg') }}
                                            resizeMode="contain"
                                            style={tw`h-3 w-3 absolute top-1/2 -translate-y-1/2 right-4`}
                                        />
                                    </Pressable>
                                    
                                    <TextInput
                                        style={tw`mt-2 md:mt-4 w-full`}
                                        autoCapitalize="on"
                                        placeholder={ labels.current?.DETAILS }
                                        multiline={true}
                                        numberOfLines={4}
                                        textAlignVertical={true}
                                        image={ newNotes ? { uri: require('../svg/'+theme+'/notes_blue.svg') } : { uri: require('../svg/notes_gray.svg') }}
                                        // onChangeText={(value)=>{setNewNotes(value)}}
                                        pattern="^[\s\S]{0,8100}$"
                                        validateGroup={newAssetValidation}
                                        validatedValue={(value) => {setNewNotes(value)}}
                                        validateOnChange={true}
                                        stateValue={newNotes}
                                        passedRef={newNotesInput}
                                        // required
                                    />
                                    <View style={tw`flex-row justify-end w-full mt-1 mr-1`}>
                                        <Span style={[tw`text-sm text-gray6`, newNotesInput.current?.value.length > 8100 && tw`text-red`]}>{`${newNotesInput.current?.value.length}/8100`}</Span>
                                    </View>

                                    <View style={[tw`flex-row w-1/2 mb-0`, 1 === is_recurrence_locked.current && tw`pointer-events-none opacity-50`]}>
                                        <Pressable
                                            style={[tw`py-2 px-3 rounded-l-xl border border-[${themes[theme].brand_primary}] bg-gray0`, ! newIsRecurring && tw`bg-[${themes[theme].brand_primary}]`]}
                                            onPress={()=>{
                                                if ( 1 === newIsRecurring ) { setNewIsRecurring(0); }
                                            }}
                                        >
                                            <Span style={[tw`text-xs text-[${themes[theme].brand_primary}]`, ! newIsRecurring && tw`text-gray0`]}>{ labels.current?.TASK_ONETIME }</Span>
                                        </Pressable>
                                        <Pressable
                                            style={[tw`py-2 px-3 md:mr-2 rounded-r-xl border border-[${themes[theme].brand_primary}] bg-[${themes[theme].brand_primary}]`, ! newIsRecurring && tw`bg-gray0`]}
                                            onPress={()=>{
                                                if ( 0 === newIsRecurring ) { setNewIsRecurring(1); }
                                            }}
                                        >
                                            <Span style={[tw`text-xs text-gray0`, ! newIsRecurring && tw`text-[${themes[theme].brand_primary}]`]}>{ labels.current?.TASK_RECURRING }</Span>
                                        </Pressable>
                                    </View>

                                    <Heading hlevel={2} style={tw`text-[18px] leading-4 text-ink self-center mt-5`}>{ labels.current?.TASK_RECUR }: { newIsRecurring ? labels.current?.EVERY +' '+ (newRecurrenceInterval ? recurrenceIntervalOptions.find(obj => {return obj.value == newRecurrenceInterval})?.label : '') +' '+ (newRecurrenceType ? recurrenceTypeOptions.find(obj => {return obj.value == newRecurrenceType})?.label : '') : labels.current?.NEVER }</Heading>

                                    { 1 === newIsRecurring && 1 !== is_recurrence_locked.current &&
                                        <View style={tw`w-full`}>
                                            <View style={tw`w-full flex-col md:flex-row justify-between`}>
                                                <Pressable
                                                    style={tw`mt-2 md:w-48.5/100`}
                                                    onPress={()=>{setRecurrenceIntervalSelectVisible(true)}}
                                                >
                                                    <TextInput
                                                        style={tw`pointer-events-none`}
                                                        autoCapitalize="on"
                                                        placeholder={ labels.current?.INTERVAL +'*' }
                                                        image={ newRecurrenceInterval ? { uri: require('../svg/'+theme+'/enginehours_blue.svg') } : { uri: require('../svg/enginehours_gray.svg') }}
                                                        value={newRecurrenceInterval ? recurrenceIntervalOptions.find(obj => {return obj.value == newRecurrenceInterval})?.label : ''}
                                                    />

                                                    <Image
                                                        accessibilityHidden={true}
                                                        source={{ uri: require('../svg/'+theme+'/chevron_down_blue.svg') }}
                                                        resizeMode="contain"
                                                        style={tw`h-3 w-3 absolute top-1/2 -translate-y-1/2 right-4`}
                                                    />
                                                </Pressable>

                                                <Pressable
                                                    style={tw`mt-2 md:w-48.5/100`}
                                                    onPress={()=>{setRecurrenceTypeSelectVisible(true)}}
                                                >
                                                    <TextInput
                                                        style={tw`pointer-events-none`}
                                                        autoCapitalize="on"
                                                        placeholder={ labels.current?.TYPE +'*' }
                                                        image={ newRecurrenceType ? { uri: require('../svg/'+theme+'/recurring.svg') } : { uri: require('../svg/recurring_gray.svg') }}
                                                        value={newRecurrenceType ? recurrenceTypeOptions.find(obj => {return obj.value == newRecurrenceType})?.label : ''}
                                                    />

                                                    <Image
                                                        accessibilityHidden={true}
                                                        source={{ uri: require('../svg/'+theme+'/chevron_down_blue.svg') }}
                                                        resizeMode="contain"
                                                        style={tw`h-3 w-3 absolute top-1/2 -translate-y-1/2 right-4`}
                                                    />
                                                </Pressable>
                                            </View>

                                            <Span style={tw`text-sm text-gray6 my-2 w-full`}>{ '* ' + labels.current?.REQUIRED }</Span>
                                        </View>
                                    }

                                    { 1 === is_recurrence_locked.current &&
                                        <Span style={tw`mt-2 text-sm`}>{ labels.current?.TASK_RECUR_NO_MODIFY }</Span>
                                    }
                                </View>

                                <View style={[tw`md:flex-row md:flex-wrap md:justify-between md:pb-5`, ( labels?.current?.PHOTOS !== contentType || ! updateID ) && tw`hidden`]}>

                                    <ScrollView
                                        style={[{width:'100%',overflowX:tw.prefixMatch('md') ? 'visible' : 'hidden'}, tw`pt-4 rounded bg-white border border-gray4 mt-2`, photos[updateID]?.length && tw`pl-4`]}
                                        contentContainerStyle={tw`w-full justify-start h-[${height - 200}px] md:h-[272px]`}
                                    >
                                        { photos[updateID]?.length ? <View style={tw`flex-wrap flex-row justify-start items-start`}>{ photos[updateID] }</View> : <View></View> }
                                        { ! photos[updateID]?.length &&
                                            <View style={tw`justify-center grow items-center mt-4`}>
                                                <Image
                                                    accessibilityHidden={true}
                                                    source={{ uri: require('../svg/photos_lightgray.svg') }}
                                                    resizeMode="contain"
                                                    style={tw`h-28 w-28 md:h-40 md:w-40`}
                                                />
                                                <Heading style={tw`text-gray5 text-center p-5`} hlevel={tw.prefixMatch('md') ? 2 : 3}>{ labels.current?._404_PHOTOS_TEXT }</Heading>
                                            </View>
                                        }
                                    </ScrollView>

                                    <ImagesUploadInput
                                        passedRef={newPhotosInput}
                                        onChange={(e)=>{
                                            if ( ! e.target.files[0] ) { return; }

                                            let imagesArray = processImagesInput(e.target.files, settings.max_size_image).then((imagesArray)=>{
                                                if ( 'string' === typeof imagesArray )
                                                {
                                                    toast.show(imagesArray, {type: 'danger', duration:10000});
                                                    return;
                                                }
                                                setIsImageLoading(true);
                                                uploadImages(
                                                    imagesArray,
                                                    photoObjectType.current,
                                                    updateID,
                                                    toast,
                                                    labels.current?.TOAST_ADD.replace('{object}',labels.current?.PHOTOS),
                                                    {apiUrl:apiUrl, apiVersion:apiVersion},
                                                    function (status) {
                                                        setIsImageLoading(false);
                                                        if ( 'success' === status )
                                                        {
                                                            setLastUpdate(now());
                                                        }
                                                    }
                                                );
                                            })
                                        }}
                                    />

                                    <AddNewAssetButtons style={tw`bottom-10 right-9 md:right-10`} hideRefresh={true} hideTooltips={true} isLoading={isImageUploading} addNewLabel={ labels.current?.ADD +' '+ labels.current?.PHOTOS } onAddNew={()=>{
                                        /* trigger click on #image-picker */
                                        newPhotosInput.current.click();
                                    }} />

                                </View>

                                <View style={[tw`md:flex-row md:flex-wrap md:justify-between md:pb-5`, ( labels?.current?.DOCUMENTS !== contentType || ! updateID ) && tw`hidden`]}>

                                    <ScrollView
                                        style={[{width:'100%',overflowX:tw.prefixMatch('md') ? 'visible' : 'hidden'}, tw`pt-4 rounded bg-white border border-gray4 mt-2`, documents[updateID]?.length && tw`pl-4`]}
                                        contentContainerStyle={tw`w-full justify-start h-[${height - 200}px] md:h-[272px]`}
                                    >
                                        { documents[updateID]?.length ? <View style={tw`md:flex-wrap md:flex-row pb-16 md:justify-start md:items-start`}>{ documents[updateID] }</View> : <View></View> }
                                        { ! documents[updateID]?.length &&
                                            <View style={tw`justify-center grow items-center mt-4`}>
                                                <Image
                                                    accessibilityHidden={true}
                                                    source={{ uri: require('../svg/attachment_lightgray.svg') }}
                                                    resizeMode="contain"
                                                    style={tw`h-28 w-28 md:h-40 md:w-40`}
                                                />
                                                <Heading style={tw`text-gray5 text-center p-5`} hlevel={tw.prefixMatch('md') ? 2 : 3}>{ labels.current?._404_DOCUMENTS_TEXT }</Heading>
                                            </View>
                                        }
                                    </ScrollView>

                                    <DocumentUploadInput
                                        passedRef={documentInput}
                                        onChange={(e)=>{
                                            if ( ! e.target.files[0] ) { return; }
                                            // if size is > 30 MB
                                            if ( e.target.files[0].size > ( settings?.max_size_document ? Math.round(parseInt(settings.max_size_document, 10)) : 31457280 ) ) { toast.show(labels.current?.DOCUMENT_TOO_LARGE_BODY.replace('%size%', '30 MB'), {type: 'danger', duration:10000}); return; }

                                            documentFileNameInput.current.value = e.target.files[0].name;
                                            processDocumentInput(e.target.files[0]).then((result)=>{
                                                setDocumentData(result);
                                                documentMimeType.current = e.target.files[0].type;
                                                documentSize.current = e.target.files[0].size;
                                                documentExt.current = e.target.files[0].name.split('.').pop().toLowerCase();
                                            }).catch((error)=>{
                                                toast.show(error, {type: 'danger', duration:10000});
                                            });
                                        }}
                                    />

                                    <AddNewAssetButtons style={tw`bottom-10 right-9 md:right-10`} hideRefresh={true} hideTooltips={true} addNewLabel={ labels.current?.ADD +' '+ labels.current?.DOCUMENT } onAddNew={()=>{
                                        setUpdateDocumentID(0);

                                        setDocumentData('');
                                        documentSize.current = 0;
                                        documentExt.current = '';
                                        if ( documentFileNameInput.current?.value ) { documentFileNameInput.current.value = ''; }
                                        documentMimeType.current = '';
                                        setDocumentName('');
                                        if ( documentNameInput.current?.value ) { documentNameInput.current.value = ''; }
                                        setDocumentExpiration('');
                                        if ( documentExpirationInput.current?.value ) { documentExpirationInput.current.value = ''; }
                                        setDocumentType(0);
                                        setDocumentDesc('');
                                        if ( documentDescInput.current?.value ) { documentDescInput.current.value = ''; }

                                        setUpdateDocument(true);
                                    }} />

                                </View>

                            </ScrollView>

                            <HorizontalRule style={tw`md:hidden`} />
                                
                            <Button
                                style={[tw`m-5`, labels?.current?.DETAILS !== contentType && tw`hidden`]}
                                title={0 !== updateID ? labels.current?.UPDATE : labels.current?.ADD}
                                disabled={ newTaskYacht
                                    && newTaskOwner
                                    && newTaskName
                                    && newPriority
                                    // && newNotes
                                    && newTaskDate ? false : true
                                }
                                validateGroup={newAssetValidation}
                                onPress={()=>{
                                    console.log('Adding new task:');
                                    console.log({
                                        vessel_id: newTaskYacht,
                                        assignee_user_id: newTaskOwner,
                                        assignee_alternate: newTaskAlternate,
                                        name : newTaskName,
                                        details : newNotes || '',
                                        due_hours: parseInt(newHoursDue) || '',
                                        priority_id: newPriority,
                                        due_date : newTaskDate,
                                        is_complete : newIsComplete,
                                        is_recurring : newIsRecurring,
                                        type_id : newType,
                                        location_id : newLocation,
                                        mechanical_id : newMechanicalID,
                                        equipment_id : newEquipmentID,
                                    });

                                    if ( newAssetValidation ) { return; }

                                    doNewAssetValidation(true);
                                    setNewAssetMsg('');

                                    setTimeout(()=>
                                    {
                                        // check if fields are valid

                                        // only required fields should be:
                                        // name (thus initials)
                                        // note

                                        if ( ! newTaskYacht
                                            || ! newTaskOwner
                                            || ! newTaskName
                                            || ! newTaskDate
                                            || ! newPriority
                                            || ( ! newTaskAlternate && newTaskAlternateInput.current?.value.length > 0 )
                                            || ( newIsRecurring && ! newRecurrenceInterval )
                                            || ( newIsRecurring && ! newRecurrenceType )
                                            || ( ! newNotes && newNotesInput.current?.value.length > 0 )
                                            || ( '' !== newHoursDue && newHoursDue.current?.value.length > 0 )
                                        )
                                        {
                                            let errorMsg = [];

                                            if ( ! newTaskOwner )
                                                { errorMsg.push(labels.current?.REQUEST +' '+labels.current?.ASSIGNED_TO); }
                                            if ( ! newTaskYacht )
                                                { errorMsg.push(labels.current?.REQUEST +' '+labels.current?.YACHT); }
                                            if ( ! newTaskName )
                                                { errorMsg.push(labels.current?.REQUEST +' '+labels.current?.NAME); }
                                            if ( ! newPriority )
                                                { errorMsg.push(labels.current?.REQUEST +' '+labels.current?.PRIORITY); }
                                            if ( ! newTaskDate )
                                                { errorMsg.push(labels.current?.INVALID_DATE); }

                                            if ( newIsRecurring && ! newRecurrenceInterval )
                                                { errorMsg.push(labels.current?.REQUEST +' '+ labels.current?.TASK_RECURRING +' '+labels.current?.INTERVAL); }
                                            if ( newIsRecurring && ! newRecurrenceType )
                                                { errorMsg.push(labels.current?.REQUEST +' '+ labels.current?.TASK_RECURRING +' '+labels.current?.TYPE); }
                                            if ( ! newNotes && newNotesInput.current?.value.length > 0 )
                                                { errorMsg.push(labels.current?.REQUEST_VALID +' '+ labels.current?.DETAILS);  }
                                            if ( ! newTaskAlternate && newTaskAlternateInput.current?.value.length > 0 )
                                                { errorMsg.push(labels.current?.REQUEST_VALID +' '+ labels.current?.ASSIGNEE_ALTERNATE);  }
                                            if ( '' !== newHoursDue && newHoursDue.current?.value.length > 0 )
                                                { errorMsg.push(labels.current?.REQUEST_VALID +' '+ labels.current?.DUE_HOURS); }

                                            setNewAssetMsgType('warning');
                                            setNewAssetMsg(errorMsg.join('\n'));
                                            doNewAssetValidation(false);
                                        }
                                        else
                                        {
                                            let recurrence_days = newRecurrenceInterval ? recurrenceTypeFactor.find(obj => {return obj.value == newRecurrenceType})?.factor * newRecurrenceInterval : 0;
                                            let payload = {
                                                vessel_id: newTaskYacht,
                                                assignee_user_id: newTaskOwner,
                                                assignee_alternate: newTaskAlternate || '',
                                                name : newTaskName,
                                                details : newNotes || '',
                                                due_hours: parseInt(newHoursDue) || '',
                                                priority_id: newPriority,
                                                due_date : newTaskDate,
                                                is_complete : newIsComplete ? 1 : 0,
                                                is_recurring : newIsRecurring,
                                                is_recurrence_locked : is_recurrence_locked.current,
                                                recurrence_days : newIsRecurring ? recurrence_days : 0,
                                                type_id : newType || '',
                                                location_id : newLocation || '',
                                                mechanical_id : newMechanicalID || '',
                                                equipment_id : newEquipmentID || '',
                                            };
                                            if ( 0 !== updateID ) { payload.id = updateID; }
                                            
                                            fetch(apiUrl, {
                                                method: 'POST',
                                                cache: 'no-cache',
                                                headers: {
                                                    'Content-Type': 'application/json'
                                                },
                                                body: JSON.stringify({
                                                    endpoint: 0 !== updateID ? 'tasks/update' : 'tasks/add', v: apiVersion,
                                                    device_id: localStorage.getItem('deviceID'),
                                                    client_key: localStorage.getItem('clientKey'),
                                                    payload: payload
                                                }),
                                            })
                                            .then((response) => {
                                                return response.text().then(function(text) {
                                                    const result = JSON.parse(text);
                                                    if ( result.error )
                                                    {
                                                        if ( '401' === result.error ){ setLoggedIn(false); result.error = 'Your session token has expired, please log in again.'; }
                                                        setNewAssetMsgType('warning');
                                                        setNewAssetMsg(result.error);
                                                        doNewAssetValidation(false);
                                                    }
                                                    else
                                                    {
                                                        setAddingNew(false);  
                                                        doNewAssetValidation(false);
                                        
                                                        toast.show((0 !== updateID ? labels.current?.TOAST_UPDATE : labels.current?.TOAST_ADD).replace('{object}',labels.current?._ASSET), {type: 'success', duration:2000});
                                                        setTimeout(()=>{
                                                            setLastUpdate(now()); // forces app to refetch all account data
                                                        }, 500);

                                                        setTimeout(()=>{
                                                            newTaskNameInput.current.value = '';
                                                            newNotesInput.current.value = '';
                                                            newTaskDateInput.current.value = '';
                                                            newHoursDueInput.current.value = '';
                                                            newTaskAlternateInput.current.value = '';
                                                            is_recurrence_locked.current = 0;
                                                            setNewTaskName('');
                                                            setNewTaskOwner(true === enabledFeatures.sharing ? '' : user.id);
                                                            setNewTaskAlternate('');
                                                            setNewTaskYacht('');
                                                            setNewTaskDate('');
                                                            setNewHoursDue('');
                                                            setNewNotes('');
                                                            setNewPriority(0);
                                                            setNewIsComplete(false);
                                                            setNewIsRecurring(0);
                                                            setNewRecurrenceType(null);
                                                            setNewRecurrenceInterval(null);
                                                            setNewRecurrenceDays(0);
                                                            setNewType('');
                                                            setNewLocation('');
                                                            setNewMechanicalID('');
                                                            setNewEquipmentID('');
                                                        }, 1500);
                                                    }
                                                });
                                            })
                                            .catch(error => {
                                                console.warn(error);
                                                setNewAssetMsgType('warning');
                                                if ( ! window.navigator.onLine ) { setNewAssetMsg('Internet connection failed. Please try again.'); }
                                                else { setNewAssetMsg('An error ocurred. See console log.'); }
                                                doNewAssetValidation(false);
                                            });
                                        }
                                    }, 250);
                                }}
                            />
                        </View>
                    </View>
                </View>
            </AnimatedView>

            <AnimatedView style={viewPaneAnimation}>

                <Pressable style={tw`hidden md:flex bg-[${themes[theme].brand_primary}]/75 w-screen h-screen left-0 top-0 fixed`}
                    onPress={() => {setViewingAsset(false)}}
                ></Pressable>

                <View style={tw`md:w-188 md:rounded-md md:shadow-black md:shadow-opacity-25 md:shadow-radius-8`}>
                
                    <View style={tw`bg-[${themes[theme].brand_primary}] md:bg-gray0 md:border-b-2 md:border-gray1 md:items-center h-20 p-5 md:px-9 pt-6.5 flex-row md:rounded-t-md`}>
                        <Pressable
                            style={tw`p-2 -ml-2 -mt-2`}
                            onPress={() => {setViewingAsset(false)}}
                        >
                            <Image
                                accessibilityLabel={ labels.current?.CANCEL }
                                source={{ uri: tw.prefixMatch('md') ? require('../svg/'+theme+'/back.svg') : require('../svg/back_white.svg') }}
                                resizeMode="contain"
                                style={tw`h-6 w-6 top-px md:top-1 md:mr-4`}
                            />
                        </Pressable>
                        <Heading style={tw`mt-1.5 leading-4 mb-0 text-white md:hidden`} hlevel={1} styleHlevel={tw.prefixMatch('md') ? 2 : 4}>{ labels.current?._ASSET }</Heading>
                        <View style={tw`justify-between hidden md:flex md:flex-row grow mt-0 mb-0 items-start h-full`}>
                            <View style={tw`flex-row w-full justify-between items-center self-center`}>
                                <Heading style={tw`mt-0 mb-0 text-ink`} hlevel={1} styleHlevel={2} numberOfLines={1}>{ viewingAssetObj.name }</Heading>
                                <Pressable
                                    style={tw`p-2`}
                                    onPress={() => {Linking.openURL('https://app.yachtwave.com/task?key='+viewingAssetObj.uuid)}}
                                >
                                    <Image
                                        accessibilityLabel={ labels.current?.SHARE }
                                        source={{ uri: require('../svg/'+theme+'/share_blue.svg') }}
                                        resizeMode="contain"
                                        style={tw`h-10 w-10`}
                                    />
                                </Pressable>
                            </View>
                        </View>
                    </View>
                
                    <View style={tw`bg-[${themes[theme].brand_primary}] h-8 z-0 -mb-3 md:hidden`}>
                        <View style={tw`bg-gray0 h-full w-full rounded-t-3xl`}></View>
                    </View>
        
                    <View style={[tw`bg-gray0 grow md:pr-4 md:pb-5 md:pt-8 md:pl-4 z-20 md:rounded-b-md w-full`, { height: tw.prefixMatch('md') ? 'max-content' : 'calc(100vh - 100px)'}]}>
                        <View style={tw`h-full md:h-max`}>
                            <View style={tw`w-full items-center justify-between px-5 mb-4 flex-row md:hidden`}>
                                <Span style={tw`font-bold`} numberOfLines={1}>{ viewingAssetObj.name }</Span>
                                <Pressable
                                    onPress={() => {Linking.openURL('https://app.yachtwave.com/task?key='+viewingAssetObj.uuid)}}
                                >
                                    <Image
                                        accessibilityLabel={ labels.current?.SHARE }
                                        source={{ uri: require('../svg/'+theme+'/share_blue.svg') }}
                                        resizeMode="contain"
                                        style={tw`h-7.5 w-7.5`}
                                    />
                                </Pressable>
                            </View>
                            <HorizontalRule style={tw`mb-4 md:hidden`} />

                            { ( 0 !== photos[viewingAssetObj.id]?.length || 0 !== documents[viewingAssetObj.id]?.length ) &&
                                <PillMenu
                                    style={tw`w-auto mx-5.5 mb-3 md:mb-4 md:-mt-2`}
                                    selected={ contentType }
                                    menu={[
                                        { name:labels.current?.DETAILS, icon:{ uri: require('../svg/notes_white.svg') }, inactiveIcon:{ uri: require('../svg/'+theme+'/notes.svg') }, onPress:()=>{ setContentType(labels.current?.DETAILS); } },
                                        ...(enabledFeatures.photos && 0 !== photos[viewingAssetObj.id]?.length ? [{ name:labels.current?.PHOTOS, icon:{ uri: require('../svg/photos_white.svg') }, inactiveIcon:{ uri: require('../svg/'+theme+'/photos.svg') }, onPress:()=>{ setContentType(labels.current?.PHOTOS); } }] : []),
                                        ...(enabledFeatures.documents && 0 !== documents[viewingAssetObj.id]?.length ? [{ name: labels.current?.DOCUMENTS, icon: { uri: require('../svg/attachment_white.svg') }, inactiveIcon: { uri: require('../svg/'+theme+'/attachment.svg') }, onPress: () => { setContentType(labels.current?.DOCUMENTS); } }] : [])
                                    ]}
                                />
                            }

                            <ScrollView
                                style={{width:'100%',overflowX:tw.prefixMatch('md') ? 'visible' : 'hidden'}}
                                contentContainerStyle={tw`w-full justify-start px-5`}
                            >
                                <View style={[tw`md:flex-row md:flex-wrap md:justify-between content-start md:min-h-[300px]`, labels?.current?.DETAILS !== contentType && tw`hidden`]}>

                                    <View style={tw`p-1 w-full flex-row justify-start items-center`}>
                                        <Heading hlevel={2} style={tw`text-[18px] leading-4 text-ink self-center mb-4 md:mb-5 mt-0`}>{ labels.current?.STATUS }: { newStatusText.current } { newCompleteDateText.current }</Heading>
                                    </View>

                                    <View style={tw`md:flex-row md:flex-wrap md:px-1 w-full max-w-full`}>
                                        <SpecsCard
                                            style={[tw`md:mr-2`, {width: tw.prefixMatch('md') ? 'calc(50% - 8px)' : '100%'}]}
                                            label={ labels.current?.YACHT }
                                            labelImage={{ uri: require('../svg/'+theme+'/vesseltype.svg') }}
                                            value={viewingAssetObj.vessel_name}
                                            onPress={() => { setViewingAsset(false); navigation.navigate('yacht', {yachtID:viewingAssetObj.vessel_id}) }}
                                        />
                                        <SpecsCard
                                            style={[tw`md:mr-2`, {width: tw.prefixMatch('md') ? 'calc(50% - 8px)' : '100%'}]}
                                            label={ labels.current?.PRIORITY }
                                            labelImage={{ uri: require('../svg/'+theme+'/triangleExclamation.svg') }}
                                            value={viewingAssetObj.priority_id ? priorityOptions.find(obj => {return obj.value == viewingAssetObj.priority_id})?.label : ''}
                                            valueImage={4 == viewingAssetObj.priority_id ? { uri: require('../svg/status_outofservice.svg') } : ( 3 == viewingAssetObj.priority_id ? { uri: require('../svg/status_disabled.svg') } : ( 2 == viewingAssetObj.priority_id ? { uri: require('../svg/status_default.svg') } : { uri: require('../svg/status_inservice.svg') } ) )}
                                        />            
                                        <SpecsCard
                                            style={[tw`md:mr-2`, {width: tw.prefixMatch('md') ? 'calc(50% - 8px)' : '100%'}]}
                                            label={ labels.current?.DUE }
                                            labelImage={{ uri: require('../svg/'+theme+'/calendar_blue.svg') }}
                                            value={YYYYMMDD_to_DMJYYYY(viewingAssetObj.due_date)}
                                        />
                                        <SpecsCard
                                            style={[tw`md:mr-2`, {width: tw.prefixMatch('md') ? 'calc(50% - 8px)' : '100%'}]}
                                            label={ labels.current?.TASK_RECUR }
                                            labelImage={{ uri: require('../svg/'+theme+'/recurring.svg') }}
                                            value={ viewingAssetObj.is_recurring ? labels.current?.EVERY +' '+ (viewingAssetObj.recurrence_interval ? recurrenceIntervalOptions.find(obj => {return obj.value == viewingAssetObj.recurrence_interval})?.label : '') +' '+ (viewingAssetObj.recurrence_type ? recurrenceTypeOptions.find(obj => {return obj.value == viewingAssetObj.recurrence_type})?.label : '') : labels.current?.NEVER }
                                        />
                                        <SpecsCard
                                            style={[tw`md:mr-2`, {width: tw.prefixMatch('md') ? 'calc(50% - 8px)' : '100%'}]}
                                            label={ labels.current?.ASSIGNED_TO }
                                            labelImage={{ uri: require('../svg/'+theme+'/user.svg') }}
                                            value={viewingAssetObj.assignee_user_name}
                                            onPress={() => { setViewingAsset(false); navigation.navigate('shared', {yachtID:viewingAssetObj.vessel_id}) }}
                                        />
                                        <SpecsCard
                                            style={[tw`md:mr-2`, ! viewingAssetObj.assignee_alternate && tw`hidden`, {width: tw.prefixMatch('md') ? 'calc(50% - 8px)' : '100%'}]}
                                            label={ labels.current?.ASSIGNEE_ALTERNATE }
                                            labelImage={{ uri: require('../svg/'+theme+'/user.svg') }}
                                            value={viewingAssetObj.assignee_alternate}
                                        />
                                        <SpecsCard
                                            style={[tw`md:mr-2`, {width: tw.prefixMatch('md') ? 'calc(50% - 8px)' : '100%'}]}
                                            label={ labels.current?.DUE_HOURS }
                                            labelImage={{ uri: require('../svg/'+theme+'/enginehours_blue.svg') }}
                                            value={viewingAssetObj.due_hours || 'n/a'}
                                        />
                                    </View>

                                    <View style={tw`p-1 max-w-full`}>
                                        <Heading hlevel={2} styleHlevel={tw.prefixMatch('md') ? 3 : 5} style={tw`mt-5`}>{ labels.current?.DETAILS }</Heading>
                                        <Span>{ viewingAssetObj.details }</Span>
                                    </View>

                                    { 1 !== viewingAssetObj.is_complete && 1 !== viewingAssetObj.is_recurrence_locked &&
                                        <View style={tw`w-full`}>
                                            <Button
                                                style={tw`my-5 mx-1`}
                                                title={labels.current?.TASK_MARK_COMPLETE}
                                                validateGroup={newAssetValidation}
                                                onPress={()=>{
                                                    if ( newAssetValidation ) { return; }
                                                    doNewAssetValidation(true);
                                                    setQuickCompleteModalAffectObject(viewingAssetObj);
                                                    setQuickCompleteModalActive(true);
                                                }}
                                            />
                                        </View>
                                    }
                                </View>

                                <View style={[tw`md:flex-row md:flex-wrap md:justify-between md:min-h-[300px]`, labels?.current?.PHOTOS !== contentType && tw`hidden`]}>
                                    <ScrollView
                                        style={[{width:'100%',overflowX:tw.prefixMatch('md') ? 'visible' : 'hidden'}, tw`px-4 max-w-full`, photos[updateID]?.length && tw`pl-4`]}
                                        contentContainerStyle={tw`w-full justify-start h-[${height - 290}px] md:h-[272px] mt-2`}
                                    >
                                        { photos[viewingAssetObj.id]?.length ? <View style={tw`flex-wrap flex-row justify-start items-start`}>{ photos[viewingAssetObj.id] }</View> : <View></View> }
                                    </ScrollView>
                                </View>

                                <View style={[tw`md:flex-row md:flex-wrap md:justify-between md:min-h-[300px]`, labels?.current?.DOCUMENTS !== contentType && tw`hidden`]}>
                                    <ScrollView
                                        style={[{width:'100%',overflowX:tw.prefixMatch('md') ? 'visible' : 'hidden'}, tw`px-4 max-w-full`, documents[updateID]?.length && tw`pl-4`]}
                                        contentContainerStyle={tw`w-full justify-start h-[${height - 290}px] md:h-[272px] mt-2`}
                                    >
                                        { documents[viewingAssetObj.id]?.length ? <View style={tw`md:flex-wrap md:flex-row md:justify-start md:items-start`}>{ documents[viewingAssetObj.id] }</View> : <View></View> }
                                    </ScrollView>
                                </View>
                            </ScrollView>
                        </View>
                    </View>
                </View>
            </AnimatedView>

            <AnimatedView style={viewPhotoAnimation}>

                <Pressable style={tw`hidden md:flex w-screen h-screen left-0 top-0 fixed`}
                    onPress={() => {setViewingPhoto(false)}}
                ></Pressable>

                <View style={tw`w-full md:w-[${viewingPhotoObj.width}px] md:max-w-[80vw] md:rounded-md md:shadow-black md:shadow-opacity-25 md:shadow-radius-8`}>

                    <View style={tw`bg-[${themes[theme].brand_primary}] md:bg-gray0 md:border-b-2 md:border-gray1 md:items-center h-20 p-5 md:pl-9 pt-6.5 flex-row md:rounded-t-md`}>
                        <Pressable
                            style={tw`p-2 -ml-2 -mt-2`}
                            onPress={() => {setViewingPhoto(false)}}
                        >
                            <Image
                                accessibilityLabel={ labels.current?.CANCEL }
                                source={{ uri: tw.prefixMatch('md') ? require('../svg/'+theme+'/back.svg') : require('../svg/back_white.svg') }}
                                resizeMode="contain"
                                style={tw`h-6 w-6 top-px md:top-1 md:mr-4`}
                            />
                        </Pressable>
                        <Heading style={tw`mt-1.5 leading-4 mb-0 text-white md:hidden`} hlevel={1} styleHlevel={tw.prefixMatch('md') ? 2 : 4}>{ labels.current?._ASSET +' '+ labels.current?.PHOTOS }</Heading>
                        <View style={tw`justify-between hidden md:flex md:flex-row grow mt-0 mb-0 items-start h-full`}>
                            <View style={tw`self-center`}>
                                <Heading style={tw`leading-4 mt-0 mb-0 text-ink`} hlevel={1} styleHlevel={4}>{ viewingPhotoObj.description }</Heading>
                            </View>

                            <View style={tw`self-center`}>
                                <View style={tw`flex-row items-center`}>
                                    <Image
                                        accessibilityHidden={true}
                                        source={{ uri: require('../svg/date.svg') }}
                                        resizeMode="contain"
                                        style={tw`h-4 w-4 mr-2`}
                                    />
                                    <Span style={tw`text-gray6 text-sm leading-6 text-right`}>{ date_DMJYYYY( viewingPhotoObj.modified_date ) }</Span>

                                    { 0 !== updateID && true === addingNew && ! viewingPhotoObj.is_restricted && ! viewingAssetObj?.is_static &&
                                        <Pressable
                                            style={(state) => [tw`p-2 rounded bg-gray2 ml-5`, state.hovered && tw`bg-red`]}
                                            onPress={() => {
                                                setDeleteFileModalAffectID(viewingPhotoObj.id);
                                                setDeleteFileModalActive(true);
                                            }}
                                        >
                                            {( state ) => (
                                                <Image
                                                    accessibilityLabel={labels.current?.DELETE}
                                                    source={ state.hovered ? { uri: require('../svg/delete_white.svg') } : { uri: require('../svg/delete.svg') }}
                                                    resizeMode="contain"
                                                    style={tw`h-6 w-6`}
                                                />
                                            )}
                                        </Pressable>
                                    }
                                </View>
                            </View>
                        </View>
                    </View>

                    <View style={tw`bg-[${themes[theme].brand_primary}] h-8 z-0 -mb-3 md:hidden`}>
                        <View style={tw`bg-gray0 h-full w-full rounded-t-3xl`}></View>
                    </View>

                    <View style={[tw`bg-gray0 grow md:pr-0 md:pb-0 md:pt-0 md:pl-0 z-20 md:rounded-b-md w-full`, { height: tw.prefixMatch('md') ? 'max-content' : 'calc(100vh - 100px)'}]}>
                        <View style={tw`h-full md:h-max`}>
                            <View style={tw`w-full justify-between px-5 mb-4 flex-row md:hidden`}>
                                <View style={tw`w-full`}>
                                    <Span style={tw`font-bold`}>{ viewingPhotoObj.description }</Span>
                                    <View style={tw`flex-row items-center w-full justify-between`}>
                                        <View style={tw`flex-row items-center`}>
                                            <Image
                                                accessibilityHidden={true}
                                                source={{ uri: require('../svg/date.svg') }}
                                                resizeMode="contain"
                                                style={tw`h-4 w-4 mr-2`}
                                            />
                                            <Span style={tw`text-gray6 text-sm leading-6`}>{ date_DMJYYYY( viewingPhotoObj.modified_date ) }</Span>
                                        </View>

                                        { 0 !== updateID && true === addingNew &&
                                            <Pressable
                                                style={tw`p-2 rounded bg-gray2 ml-5`}
                                                onPress={() => {
                                                    setDeleteFileModalAffectID(viewingPhotoObj.id);
                                                    setDeleteFileModalActive(true);
                                                }}
                                            >
                                                <Image
                                                    accessibilityLabel={labels.current?.DELETE}
                                                    source={{ uri: require('../svg/delete.svg') }}
                                                    resizeMode="contain"
                                                    style={tw`h-6 w-6`}
                                                />
                                            </Pressable>
                                        }
                                    </View>
                                </View>
                            </View>
                            <HorizontalRule style={tw`md:hidden`} />
                            <ScrollView
                                style={{width:'100%',overflowX:tw.prefixMatch('md') ? 'visible' : 'hidden'}}
                                contentContainerStyle={tw`w-full justify-start px-0`}
                            >
                                <View style={tw`md:flex-row md:flex-wrap md:justify-between h-[${viewingPhotoObj.height}px] max-h-[${height - 142}px] md:max-h-[80vh]`}>
                                    { 'undefined' !== typeof viewingPhotoObj?.uri &&
                                        <Image
                                            source={{ uri: viewingPhotoObj.uri }}
                                            resizeMode="contain"
                                            style={tw`w-full max-w-full h-auto min-h-full md:rounded-b-md`}
                                        />
                                    }
                                </View>
                            </ScrollView>
                        </View>
                    </View>
                </View>
            </AnimatedView>

            <AnimatedView style={addNewDocumentPaneAnimation}>

                <Pressable style={tw`hidden md:flex bg-[${themes[theme].brand_primary}]/75 w-screen h-screen left-0 top-0 fixed`}
                    onPress={() => {setNewAssetMsg(''); setUpdateDocument(false)}}
                ></Pressable>

                <View style={tw`md:w-108 md:rounded-md md:shadow-black md:shadow-opacity-25 md:shadow-radius-8`}>

                    <View style={tw`bg-[${themes[theme].brand_primary}] md:bg-gray0 md:border-b-2 md:border-gray1 md:items-center h-20 p-5 md:px-9 pt-6.5 flex-row md:rounded-t-md`}>
                        <Pressable
                            style={tw`p-2 -ml-2 -mt-2`}
                            onPress={() => {setNewAssetMsg(''); setUpdateDocument(false)}}
                        >
                            <Image
                                accessibilityLabel={ labels.current?.CANCEL }
                                source={{ uri: tw.prefixMatch('md') ? require('../svg/'+theme+'/back.svg') : require('../svg/back_white.svg') }}
                                resizeMode="contain"
                                style={tw`h-6 w-6 top-px md:top-1 md:mr-4`}
                            />
                        </Pressable>
                        <Heading style={tw`mt-1.5 md:mt-0 leading-4 mb-0 text-white md:text-ink`} hlevel={1} styleHlevel={tw.prefixMatch('md') ? 2 : 4}>{ ( 0 !== updateDocumentID ? labels.current?.EDIT : labels.current?.ADD ) +' '+ labels.current?.DOCUMENT }</Heading>
                    </View>

                    <View style={tw`bg-[${themes[theme].brand_primary}] h-8 z-0 -mb-3 md:hidden`}>
                        <View style={tw`bg-gray0 h-full w-full rounded-t-3xl`}></View>
                    </View>

                    <View style={[tw`bg-gray0 grow md:pr-4 md:pb-5 md:pt-8 md:pl-4 z-20 md:rounded-b-md w-full`, { height: tw.prefixMatch('md') ? 'max-content' : formHeight}]}>
                        <View style={tw`h-full md:h-max`}>
                            <ScrollView
                                style={{width:'100%',overflowX:tw.prefixMatch('md') ? 'visible' : 'hidden'}}
                                contentContainerStyle={tw`w-full justify-start px-5 pb-5 md:pb-0`}
                            >
                                { '' !== newAssetMsg &&
                                    <View style={tw`w-full md:pr-2`}>
                                        <Notification
                                            style={tw`my-2`}
                                            type={newAssetMsgType}
                                        >{newAssetMsg}</Notification>
                                    </View>
                                }

                                <View style={tw`md:flex-row md:flex-wrap md:justify-between`}>

                                    <Pressable
                                        style={tw`mt-2 w-full`}
                                        onPress={()=>{setDocumentTypeSelectVisible(true)}}
                                    >
                                        <TextInput
                                            style={tw`pointer-events-none`}
                                            placeholder={ labels.current?.TYPE +'*' }
                                            image={ documentType ? { uri: require('../svg/'+theme+'/attachment.svg') } : { uri: require('../svg/attachment_gray.svg') }}
                                            // defaultValue={documentType}
                                            value={documentType ? documentTypeOptions.find(obj => {return obj.value === documentType})?.label : ''}
                                            validateGroup={newAssetValidation}
                                            required
                                        />

                                        <Image
                                            accessibilityHidden={true}
                                            source={{ uri: require('../svg/'+theme+'/chevron_down_blue.svg') }}
                                            resizeMode="contain"
                                            style={tw`h-3 w-3 absolute top-1/2 -translate-y-1/2 right-4`}
                                        />
                                    </Pressable>

                                    <TextInput
                                        style={tw`mt-2 w-full`}
                                        placeholder={ labels.current?.NAME +'*' }
                                        image={ documentNameInput.current?.value ? { uri: require('../svg/'+theme+'/name.svg') } : { uri: require('../svg/name_gray.svg') }}
                                        validatedValue={(value) => {setDocumentName(value)}}
                                        validateOnChange={true}
                                        validateGroup={newAssetValidation}
                                        stateValue={documentName}
                                        passedRef={documentNameInput}
                                        required
                                    />

                                    <Span style={tw`text-sm text-gray6 my-2 w-full`}>{ '* ' + labels.current?.REQUIRED }</Span>

                                    <TextInput
                                        style={tw`mt-2 w-full`}
                                        autoCapitalize="on"
                                        placeholder={ labels.current?.DETAIL }
                                        image={ documentDescInput.current?.value ? { uri: require('../svg/'+theme+'/description.svg') } : { uri: require('../svg/description_gray.svg') }}
                                        pattern="^.{2,}$"
                                        validatedValue={(value) => {setDocumentDesc(value)}}
                                        validateOnChange={true}
                                        validateGroup={newAssetValidation}
                                        stateValue={documentDesc}
                                        passedRef={documentDescInput}
                                    />

                                    { 0 === updateDocumentID &&
                                        <Pressable
                                            style={tw`mt-2 w-full`}
                                            onPress={()=>{documentInput.current.click();}}
                                        >
                                            <TextInput
                                                style={tw`pointer-events-none`}
                                                placeholder={ labels.current?.SELECT +' '+ labels.current?.DOCUMENT_FILE }
                                                image={ documentData ? { uri: require('../svg/'+theme+'/upload.svg') } : { uri: require('../svg/upload_gray.svg') }}
                                                // defaultValue={documentType}
                                                passedRef={documentFileNameInput}
                                            />

                                            <Image
                                                accessibilityHidden={true}
                                                source={{ uri: require('../svg/'+theme+'/chevron_down_blue.svg') }}
                                                resizeMode="contain"
                                                style={tw`h-3 w-3 absolute top-1/2 -translate-y-1/2 right-4`}
                                            />
                                        </Pressable>
                                    }

                                    <SimpleTextInput
                                        type="date"
                                        style={tw`mt-2 w-full`}
                                        autoCapitalize="off"
                                        prefix={ labels.current?.EXPIRES +"*" }
                                        placeholder={labels.current?.DATE +" "+ labels.current?.EXPIRES +"*"}
                                        image={ documentExpiration ? { uri: require('../svg/'+theme+'/calendar_blue.svg') } : { uri: require('../svg/calendar_gray.svg') }}
                                        validateGroup={newAssetValidation}
                                        onChange={(e) => {setDocumentExpiration(e.target.value)}}
                                        onFocus={(e) => {documentExpirationInput?.current && documentExpirationInput.current.showPicker()}}
                                        onKeyDown={(e) => {e.preventDefault();}}
                                        passedRef={documentExpirationInput}
                                        required
                                    />

                                </View>

                            </ScrollView>

                            <HorizontalRule style={tw`md:hidden`} />

                            <Button
                                style={tw`m-5`}
                                title={0 !== updateDocumentID ? labels.current?.UPDATE : labels.current?.ADD}
                                disabled={documentName && documentType ? false : true}
                                validateGroup={newAssetValidation}
                                onPress={()=>{

                                    if ( newAssetValidation ) { return; }

                                    doNewAssetValidation(true);
                                    setNewAssetMsg('');

                                    setTimeout(()=>
                                    {
                                        // check if fields are valid

                                        if ( ! documentType
                                            || ! documentName
                                        ){
                                            let errorMsg = [];

                                            if ( ! documentType )
                                                { errorMsg.push(labels.current?.REQUEST +' '+labels.current?.DOCUMENT +' '+labels.current?.TYPE); }

                                            if ( ! documentName )
                                                { errorMsg.push(labels.current?.REQUEST +' '+labels.current?.DOCUMENT +' '+labels.current?.NAME); }

                                            setNewAssetMsgType('warning');
                                            setNewAssetMsg(errorMsg.join('\n'));
                                            doNewAssetValidation(false);
                                        }
                                        else
                                        {
                                            if ( 0 === updateDocumentID )
                                            {
                                                uploadDocument(
                                                    documentData,
                                                    documentExt.current,
                                                    documentSize.current,
                                                    documentType,
                                                    documentName,
                                                    documentDesc || '',
                                                    documentExpiration,
                                                    photoObjectType.current,
                                                    updateID,
                                                    newTaskYacht,
                                                    toast,
                                                    0 === updateDocumentID ? labels.current?.TOAST_ADD.replace('{object}',labels.current?.DOCUMENT) : labels.current?.TOAST_UPDATE.replace('{object}',labels.current?.DOCUMENT),
                                                    {apiUrl:apiUrl, apiVersion:apiVersion, setLoggedIn:setLoggedIn},
                                                    (status)=>{
                                                        doNewAssetValidation(false);
                                                        if ( 'success' === status )
                                                        {
                                                            setLastUpdate(now());

                                                            setUpdateDocument(false);
                                                            setTimeout(()=>{
                                                                setUpdateDocumentID(0);
                                                                setDocumentData('');
                                                                if ( documentFileNameInput.current?.value ) { documentFileNameInput.current.value = ''; }
                                                                setDocumentType(0);
                                                                documentMimeType.current = '';
                                                                setDocumentName('');
                                                                if ( documentNameInput.current?.value ) { documentNameInput.current.value = ''; }
                                                                setDocumentDesc('');
                                                                if ( documentDescInput.current?.value ) { documentDescInput.current.value = ''; }
                                                                setDocumentExpiration('');
                                                                if ( documentExpirationInput.current?.value ) { documentExpirationInput.current.value = ''; }
                                                            }, 1500);
                                                        }
                                                    }
                                                );
                                            }
                                            else {
                                                let payload = {
                                                    id: updateDocumentID,
                                                    name: documentName,
                                                    type_id: documentType,
                                                    details: documentDesc,
                                                    expiration_date: documentExpiration
                                                };

                                                fetch(apiUrl, {
                                                    method: 'POST',
                                                    cache: 'no-cache',
                                                    headers: {
                                                        'Content-Type': 'application/json'
                                                    },
                                                    body: JSON.stringify({
                                                        endpoint: 'documents/update', v: apiVersion,
                                                        device_id: localStorage.getItem('deviceID'),
                                                        client_key: localStorage.getItem('clientKey'),
                                                        payload: payload
                                                    }),
                                                })
                                                .then((response) => {
                                                    return response.text().then(function(text) {
                                                        const result = JSON.parse(text);
                                                        if ( result.error )
                                                        {
                                                            if ( '401' === result.error ){ setLoggedIn(false); result.error = 'Your session token has expired, please log in again.'; }
                                                            setNewAssetMsgType('warning');
                                                            setNewAssetMsg(result.error);
                                                            doNewAssetValidation(false);
                                                        }
                                                        else
                                                        {
                                                            setUpdateDocument(false);
                                                            toast.show((0 !== updateDocumentID ? labels.current?.TOAST_UPDATE : labels.current?.TOAST_ADD).replace('{object}',labels.current?.DOCUMENT), {type: 'success', duration:2000});

                                                            setTimeout(()=>{
                                                                setLastUpdate(now()); // forces app to refetch all account data
                                                            }, 500);

                                                            setTimeout(()=>{
                                                                doNewAssetValidation(false);
                                                                setUpdateDocumentID(0);
                                                                setDocumentData('');
                                                                if ( documentFileNameInput.current?.value ) { documentFileNameInput.current.value = ''; }
                                                                setDocumentType(0);
                                                                documentMimeType.current = '';
                                                                setDocumentName('');
                                                                if ( documentNameInput.current?.value ) { documentNameInput.current.value = ''; }
                                                                setDocumentDesc('');
                                                                if ( documentDescInput.current?.value ) { documentDescInput.current.value = ''; }
                                                                setDocumentExpiration('');
                                                                if ( documentExpirationInput.current?.value ) { documentExpirationInput.current.value = ''; }
                                                            }, 1500);
                                                        }
                                                    });
                                                })
                                                .catch(error => {
                                                    console.warn(error);
                                                    setNewAssetMsgType('warning');
                                                    if ( ! window.navigator.onLine ) { setNewAssetMsg('Internet connection failed. Please try again.'); }
                                                    else { setNewAssetMsg('An error ocurred. See console log.'); }
                                                    doNewAssetValidation(false);
                                                });
                                            }
                                        }
                                    }, 250);


                                }}
                            />
                        </View>
                    </View>
                </View>
            </AnimatedView>

            <AnimatedView style={viewDocumentAnimation}>

                <Pressable style={tw`hidden md:flex bg-[${themes[theme].brand_primary}]/75 w-screen h-screen left-0 top-0 fixed`}
                    onPress={() => {setViewingDocument(false)}}
                ></Pressable>

                <View style={tw`md:w-172 md:rounded-md md:shadow-black md:shadow-opacity-25 md:shadow-radius-8`}>

                    <View style={tw`bg-[${themes[theme].brand_primary}] md:bg-gray0 md:border-b-2 md:border-gray1 md:items-center h-20 p-5 md:px-9 pt-6.5 flex-row md:rounded-t-md`}>
                        <Pressable
                            style={tw`p-2 -ml-2 -mt-2`}
                            onPress={() => {setViewingDocument(false)}}
                        >
                            <Image
                                accessibilityLabel={ labels.current?.CANCEL }
                                source={{ uri: tw.prefixMatch('md') ? require('../svg/'+theme+'/back.svg') : require('../svg/back_white.svg') }}
                                resizeMode="contain"
                                style={tw`h-6 w-6 top-px md:top-1 md:mr-4`}
                            />
                        </Pressable>
                        <Heading style={tw`mt-1.5 leading-4 mb-0 text-white md:hidden`} hlevel={1} styleHlevel={tw.prefixMatch('md') ? 2 : 4}>{ labels.current?.DOCUMENT }</Heading>
                        <View style={tw`justify-between hidden md:flex md:flex-row grow mt-0 mb-0 items-start h-full`}>
                            <View style={tw`self-center`}>
                                <Heading style={tw`leading-4 mt-0 mb-0 text-ink`} hlevel={1} styleHlevel={2}>{ viewingDocumentObj.type_id ? documentTypeOptions.find(obj => {return obj.value == viewingDocumentObj.type_id})?.label : '' }</Heading>
                            </View>

                            <View style={tw`self-center`}>
                                <Tag style={tw`self-end mb-0`} color={viewingDocumentObj.expiration_color}>{ viewingDocumentObj.expiration_tag }</Tag>

                                { null !== viewingDocumentObj.expiration_date &&
                                    <View style={tw`flex-row items-center`}>
                                        <Image
                                            accessibilityHidden={true}
                                            source={{ uri: require('../svg/date.svg') }}
                                            resizeMode="contain"
                                            style={tw`h-4 w-4 mr-2`}
                                        />
                                        <Span style={tw`text-gray6 text-sm leading-6 text-right`}>{ ( is_date_in_past(viewingDocumentObj.expiration_tag) ? labels?.current?.EXPIRED : labels?.current?.EXPIRES ) + ' ' + YYYYMMDD_to_DMJYYYY( viewingDocumentObj.expiration_date ) }</Span>
                                    </View>
                                }
                            </View>
                        </View>
                    </View>

                    <View style={tw`bg-[${themes[theme].brand_primary}] h-8 z-0 -mb-3 md:hidden`}>
                        <View style={tw`bg-gray0 h-full w-full rounded-t-3xl`}></View>
                    </View>

                    <View style={[tw`bg-gray0 grow md:pr-4 md:pb-5 md:pt-8 md:pl-4 z-20 md:rounded-b-md w-full`, { height: tw.prefixMatch('md') ? 'max-content' : 'calc(100vh - 100px)'}]}>
                        <View style={tw`h-full md:h-max`}>
                            <View style={tw`w-full justify-between px-5 mb-4 flex-row md:hidden`}>
                                <View style={tw`flex-col`}>
                                    <Span style={tw`font-bold`}>{ viewingDocumentObj.type_id ? documentTypeOptions.find(obj => {return obj.value == viewingDocumentObj.type_id})?.label : '' }</Span>

                                    { null !== viewingDocumentObj.expiration_date &&
                                        <View style={tw`flex-row items-center`}>
                                            <Image
                                                accessibilityHidden={true}
                                                source={{ uri: require('../svg/date.svg') }}
                                                resizeMode="contain"
                                                style={tw`h-4 w-4 mr-2`}
                                            />
                                            <Span style={tw`text-gray6 text-sm leading-6 text-right`}>{ ( is_date_in_past(viewingDocumentObj.expiration_tag) ? labels?.current?.EXPIRED : labels?.current?.EXPIRES ) + ' ' + YYYYMMDD_to_DMJYYYY( viewingDocumentObj.expiration_date ) }</Span>
                                        </View>
                                    }
                                </View>

                                <View style={tw`self-center`}>
                                    <Tag style={tw`self-end mb-0`} color={viewingDocumentObj.expiration_color}>{ viewingDocumentObj.expiration_tag }</Tag>
                                </View>
                            </View>
                            <HorizontalRule style={tw`mb-4 md:hidden`} />

                            <ScrollView
                                style={{width:'100%',overflowX:tw.prefixMatch('md') ? 'visible' : 'hidden'}}
                                contentContainerStyle={tw`w-full justify-start px-5`}
                            >
                                <View style={tw`md:flex-row md:flex-wrap md:justify-between content-start md:min-h-[300px]`}>
                                    <View style={tw`md:flex-row md:flex-wrap md:px-1 w-full max-w-full`}>
                                        <SpecsCard
                                            style={[tw`md:mr-2`, {width: tw.prefixMatch('md') ? 'calc(50% - 8px)' : '100%'}]}
                                            label={ labels.current?.NAME }
                                            labelImage={{ uri: require('../svg/'+theme+'/name.svg') }}
                                            value={viewingDocumentObj.name}
                                        />
                                        <SpecsCard
                                            style={[tw`md:mr-2`, {width: tw.prefixMatch('md') ? 'calc(50% - 8px)' : '100%'}]}
                                            label={ labels.current?.TYPE }
                                            labelImage={{ uri: require('../svg/'+theme+'/attachment.svg') }}
                                            value={ viewingDocumentObj.type_id ? documentTypeOptions.find(obj => {return obj.value == viewingDocumentObj.type_id})?.label : '' }
                                        />
                                        <SpecsCard
                                            style={[tw`md:mr-2`, {width: tw.prefixMatch('md') ? 'calc(50% - 8px)' : '100%'}]}
                                            label={ labels.current?.DATE +" "+ labels.current?.EXPIRES }
                                            labelImage={{ uri: require('../svg/'+theme+'/calendar_blue.svg') }}
                                            value={ viewingDocumentObj.expiration_date ? formatDateToLocal(viewingDocumentObj.expiration_date) : 'N/A' }
                                        />
                                        { '' !== viewingDocumentObj.uri && null !== viewingDocumentObj.uri &&
                                            <SpecsCard
                                                style={[tw`md:mr-2`, {width: tw.prefixMatch('md') ? 'calc(50% - 8px)' : '100%'}]}
                                                label={ labels.current?.VIEW +' '+ labels.current?.DOCUMENT_FILE }
                                                labelImage={{ uri: require('../svg/'+theme+'/url.svg') }}
                                                onPress={() => {  window.open(viewingDocumentObj.uri, '_blank'); }}
                                            />
                                        }
                                    </View>

                                    <View style={tw`w-full p-1 max-w-full`}>
                                        <Heading hlevel={2} styleHlevel={tw.prefixMatch('md') ? 3 : 5} style={tw`mt-5`}>{ labels.current?.DETAILS }</Heading>
                                        <Span>{ viewingDocumentObj.details }</Span>
                                    </View>
                                </View>

                            </ScrollView>
                        </View>
                    </View>
                </View>
            </AnimatedView>

            <SelectMenu
                badge={false}
                title={ labels.current?.SELECT +' '+ labels.current?.TYPE }
                optionSet="radio"
                buttonTitle={ labels.current?.SELECT }
                active={documentTypeSelectVisible}
                setActive={setDocumentTypeSelectVisible}
                selectedValue={documentType}
                options={documentTypeOptions}
                onValueChange={(value) => {setDocumentType(value)}}
                scrollable={documentTypeOptions.length > 8}
                searchable={documentTypeOptions.length > 8}
            />
                    
            <SelectMenu
                badge={false}
                title={ labels.current?.VIEW }
                optionSet="radio"
                buttonTitle={ labels.current?.SORT }
                active={filterVisible}
                setActive={setFilterVisible}
                selectedValue={getFilter}
                options={ true === enabledFeatures.sharing ? [
                    labels.current?.ALL,
                    labels.current?.TODO,
                    labels.current?.OVERDUE,
                    labels.current?.COMPLETED,
                    labels.current?.LOW_PRIORITY,
                    labels.current?.MEDIUM_PRIORITY,
                    labels.current?.HIGH_PRIORITY,
                    labels.current?.CRITICAL_PRIORITY,
                    labels.current?.ASSIGNED_BY_ME,
                    labels.current?.ASSIGNED_TO_ME
                ] : [
                    labels.current?.ALL,
                    labels.current?.TODO,
                    labels.current?.OVERDUE,
                    labels.current?.COMPLETED,
                    labels.current?.LOW_PRIORITY,
                    labels.current?.MEDIUM_PRIORITY,
                    labels.current?.HIGH_PRIORITY,
                    labels.current?.CRITICAL_PRIORITY
                ]}
                onValueChange={(value) => {setFilter(value)}}
            />
                        
            <SelectMenu
                badge={false}
                title={ labels.current?.SELECT +' '+ labels.current?.YACHT }
                optionSet="radio"
                buttonTitle="Select"
                active={yachtSelectVisible}
                setActive={setYachtSelectVisible}
                selectedValue={newTaskYacht}
                options={yachtOptions}
                onValueChange={(value) => {setNewTaskYacht(value)}}
                scrollable={yachtOptions.length > 8}
                searchable={yachtOptions.length > 16}
            />

            <SelectMenu
                badge={false}
                title={ labels.current?.ASSIGN_TO }
                optionSet="radio"
                buttonTitle={ labels.current?.ASSIGN }
                // quickLink={()=>{console.log('Add new user')}}
                // quickLinkTitle="+ New User"
                active={ownerSelectVisible}
                setActive={setOwnerSelectVisible}
                selectedValue={newTaskOwner}
                options={ownerOptions}
                onValueChange={(value) => {setNewTaskOwner(value)}}
                scrollable={ownerOptions.length > 8}
                searchable={ownerOptions.length > 16}
            />

            <SelectMenu
                badge={false}
                title={ labels.current?.SELECT +' '+ labels.current?.TYPE }
                optionSet="radio"
                buttonTitle={ labels.current?.SELECT }
                active={recurrenceTypeSelectVisible}
                setActive={setRecurrenceTypeSelectVisible}
                selectedValue={newRecurrenceType}
                options={recurrenceTypeOptions}
                onValueChange={(value) => {setNewRecurrenceType(value)}}
                scrollable={recurrenceTypeOptions.length > 8}
                searchable={recurrenceTypeOptions.length > 16}
            />

            <SelectMenu
                badge={false}
                title={ labels.current?.SELECT +' '+ labels.current?.PRIORITY }
                optionSet="radio"
                buttonTitle={ labels.current?.SELECT }
                active={prioritySelectVisible}
                setActive={setPrioritySelectVisible}
                selectedValue={newPriority}
                options={priorityOptions}
                onValueChange={(value) => {setNewPriority(value)}}
                scrollable={priorityOptions.length > 8}
                searchable={priorityOptions.length > 16}
            />

            <SelectMenu
                badge={false}
                title={ labels.current?.SELECT +' '+ labels.current?.INTERVAL }
                optionSet="radio"
                buttonTitle={ labels.current?.SELECT }
                active={recurrenceIntervalSelectVisible}
                setActive={setRecurrenceIntervalSelectVisible}
                selectedValue={newRecurrenceInterval}
                options={recurrenceIntervalOptions}
                onValueChange={(value) => {setNewRecurrenceInterval(value)}}
                scrollable={recurrenceIntervalOptions.length > 8}
                searchable={recurrenceIntervalOptions.length > 16}
            />

            <SelectMenu
                badge={false}
                title={ labels.current?.SELECT +' '+ labels.current?.TYPE }
                optionSet="radio"
                buttonTitle={ labels.current?.SELECT }
                active={typeSelectVisible}
                setActive={setTypeSelectVisible}
                selectedValue={newType}
                options={typeOptions}
                onValueChange={(value) => {setNewType(value)}}
                scrollable={typeOptions.length > 8}
                searchable={typeOptions.length > 8}
            />

            <SelectMenu
                badge={false}
                title={ labels.current?.SELECT +' '+ labels.current?.LOCATION }
                optionSet="radio"
                buttonTitle={ labels.current?.SELECT }
                active={locationSelectVisible}
                setActive={setLocationSelectVisible}
                selectedValue={newLocation}
                options={locationOptions}
                onValueChange={(value) => {setNewLocation(value); setNewMechanicalID(0);}}
                scrollable={locationOptions.length > 8}
                searchable={locationOptions.length > 8}
            />

            <SelectMenu
                badge={false}
                title={ labels.current?.SELECT +' '+ labels.current?.ENGINE }
                optionSet="radio"
                buttonTitle={ labels.current?.SELECT }
                active={mechanicalSelectVisible}
                setActive={setMechanicalSelectVisible}
                selectedValue={newMechanicalID}
                options={mechanicalOptions}
                onValueChange={(value) => {setNewMechanicalID(value); setNewLocation(0); setNewEquipmentID(0);}}
                scrollable={mechanicalOptions.length > 8}
                searchable={mechanicalOptions.length > 8}
            />

            <SelectMenu
                badge={false}
                title={ labels.current?.SELECT +' '+ labels.current?.EQUIPMENT }
                optionSet="radio"
                buttonTitle={ labels.current?.SELECT }
                active={equipmentSelectVisible}
                setActive={setEquipmentSelectVisible}
                selectedValue={newEquipmentID}
                options={equipmentOptions}
                onValueChange={(value) => {setNewEquipmentID(value); setNewLocation(getLocationFromAssetID(value, newTaskYacht, 'equipment', 'types.inventory.locations')); setNewMechanicalID(0);}}
                scrollable={equipmentOptions.length > 6}
                searchable={equipmentOptions.length > 6}
            />

            <Modal
                active={quickCompleteModalActive}
                setActive={setQuickCompleteModalActive}
                doWhileExit={()=>{doNewAssetValidation(false)}}
            >
                <Heading hlevel={2} styleHlevel={3}>{ labels.current?.TASK_MARK_COMPLETE_TITLE }</Heading>
                <Span>{ labels.current?.TASK_MARK_COMPLETE_BODY }</Span>
                <View style={tw`flex-row mt-5`}>
                    <Button
                        title={ labels.current?.CANCEL }
                        style={tw`w-full shrink py-2 bg-white border border-[${themes[theme].brand_primary}]`}
                        styleHover={tw`bg-white`}
                        styleText={tw`text-[${themes[theme].brand_primary}]`}
                        styleHoverText={tw`text-[${themes[theme].brand_primary}]`}
                        onPress={()=>{setQuickCompleteModalActive(false); doNewAssetValidation(false)}}
                    />
                    <Button
                        title={ labels.current?.TASK_MARK_COMPLETE }
                        image={{ uri: require('../svg/secure_white.svg') }}
                        style={tw`ml-2 w-full shrink py-2 bg-[${themes[theme].brand_primary}] border border-[${themes[theme].brand_primary}]`}
                        styleHover={tw`bg-[${themes[theme].brand_secondary}]`}
                        styleText={tw`text-white`}
                        styleHoverText={tw`text-white`}
                        onPress={()=>{
                            setPrimaryMsg('');

                            let payload = {
                                vessel_id: quickCompleteModalAffectObject.vessel_id,
                                id: quickCompleteModalAffectObject.id,
                                assignee_user_id: quickCompleteModalAffectObject.assignee_user_id,
                                name : quickCompleteModalAffectObject.name,
                                details : quickCompleteModalAffectObject.details || '',
                                due_date : quickCompleteModalAffectObject.due_date,
                                is_complete : 1,
                                is_recurring: quickCompleteModalAffectObject.is_recurring,
                                is_recurrence_locked: quickCompleteModalAffectObject.is_recurrence_locked,
                                recurrence_days : quickCompleteModalAffectObject.recurrence_days,
                            };

                            fetch(apiUrl, {
                                method: 'POST',
                                cache: 'no-cache',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    endpoint: 'tasks/update', v: apiVersion,
                                    device_id: localStorage.getItem('deviceID'),
                                    client_key: localStorage.getItem('clientKey'),
                                    payload: payload
                                }),
                            })
                            .then((response) => {
                                return response.text().then(function(text) {
                                    const result = JSON.parse(text);
                                    if ( result.error )
                                    {
                                        if ( '401' === result.error ){ setLoggedIn(false); result.error = 'Your session token has expired, please log in again.'; }
                                        // setNewAssetMsgType('warning');
                                        // setNewAssetMsg(result.error);
                                        toast.show(result.error, {type: 'danger', duration:10000});
                                        doNewAssetValidation(false);
                                    }
                                    else
                                    {
                                        setQuickCompleteModalAffectObject({});
                                        setQuickCompleteModalActive(false);
                                        setTimeout(()=>{
                                            toast.show((labels.current?.TOAST_UPDATE).replace('{object}',labels.current?._ASSET), {type: 'success', duration:2000});
                                            if ( quickCompleteModalAffectObject.is_recurring && parseInt(quickCompleteModalAffectObject.due_hours) > 0 )
                                            {
                                                // toast.show(labels.current?.TASK_RECURRING_CREATE_TITLE, {type: 'success', duration:10000});
                                                toast.show(labels.current?.TASK_RECURRING_CREATE_HOURS, {type: 'success', duration:10000});
                                            }
                                            else if ( quickCompleteModalAffectObject.is_recurring )
                                            {
                                                // toast.show(labels.current?.TASK_RECURRING_CREATE_TITLE, {type: 'success', duration:10000});
                                                toast.show(labels.current?.TASK_RECURRING_CREATE, {type: 'success', duration:10000});
                                            }
                                            doNewAssetValidation(false);
                                            setViewingAsset(false);
                                            setLastUpdate(now()); // forces app to refetch all account data
                                        }, 250);
                                    }
                                });
                            })
                            .catch(error => {
                                console.warn(error);
                                setNewAssetMsgType('warning');
                                if ( ! window.navigator.onLine ) { setNewAssetMsg('Internet connection failed. Please try again.'); }
                                else { setNewAssetMsg('An error ocurred. See console log.'); }
                                doNewAssetValidation(false);
                            });
                        }}
                    />
                </View>
            </Modal>

            <Modal
                active={modalActive}
                setActive={setModalActive}
            >
                <Heading hlevel={2} styleHlevel={3}>{ labels.current?.DELETE_CONFIRM_TITLE }</Heading>
                { recurringTaskIDs.current.includes(modalAffectID) &&
                    <Span>{ labels.current?.TASK_DELETE_CONFIRM_RECURRENCE }</Span>
                }
                <Span>{ iDsContainingFiles.current.includes(modalAffectID) ? labels.current?.DELETE_CONFIRM2_BODY : labels.current?.DELETE_CONFIRM_BODY }</Span>
                <Span style={tw`text-red mt-2`}>{ labels.current?.PERMANENT_ACTION }</Span>
                <View style={tw`flex-row mt-5`}>
                    <Button
                        title={ labels.current?.CANCEL }
                        style={tw`w-full shrink py-2 bg-white border border-[${themes[theme].brand_primary}]`}
                        styleHover={tw`bg-white`}
                        styleText={tw`text-[${themes[theme].brand_primary}]`}
                        styleHoverText={tw`text-[${themes[theme].brand_primary}]`}
                        onPress={()=>{setModalActive(false)}}
                    />
                    <Button
                        title={ labels.current?.DELETE }
                        image={{ uri: require('../svg/delete_white.svg') }}
                        style={tw`ml-2 w-full shrink py-2 bg-red border border-red`}
                        styleHover={tw`bg-red`}
                        styleText={tw`text-white`}
                        styleHoverText={tw`text-white`}
                        onPress={()=>{
                            setPrimaryMsg('');

                            fetch(apiUrl, {
                                method: 'POST',
                                cache: 'no-cache',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    endpoint: 'tasks/delete', v: apiVersion,
                                    client_key: localStorage.getItem('clientKey'),
                                    device_id: localStorage.getItem('deviceID'),
                                    payload: {
                                       id: modalAffectID,
                                    }
                                }),
                            })
                            .then((response) => {
                                return response.text().then(function(text) {
                                    const result = JSON.parse(text);
                                    if ( result.error )
                                    {
                                        if ( '401' === result.error ){ setLoggedIn(false); result.error = 'Your session token has expired, please log in again.'; }
                                        // setPrimaryMsgType('warning');
                                        // setPrimaryMsg(result.error);
                                        toast.show(result.error, {type: 'danger', duration:10000});
                                    }
                                    else
                                    {
                                        setModalAffectID(0);
                                        setModalActive(false);
                                        toast.show((labels.current?.TOAST_DELETE).replace('{object}',labels.current?._ASSET), {type: 'success', duration:2000});

                                        setTimeout(()=>{
                                            setLastUpdate(now()); // forces app to refetch all account data
                                        }, 250);
                                    }
                                });
                            })
                            .catch(error => {
                                console.warn(error);
                                setPrimaryMsgType('warning');
                                if ( ! window.navigator.onLine ) { setPrimaryMsg('Internet connection failed. Please try again.'); }
                                else { setPrimaryMsg('An error ocurred. See console log.'); }
                            });
                        }}
                    />
                </View>
            </Modal>

            <Modal
                active={deleteFileModalActive}
                setActive={setDeleteFileModalActive}
                doWhileExit={()=>{ documentMimeType.current = ''; }}
            >
                <Heading hlevel={2} styleHlevel={3}>{ labels.current?.DELETE_CONFIRM_TITLE }</Heading>
                <Span>{ labels.current?.DELETE_CONFIRM_BODY }</Span>
                <Span style={tw`text-red mt-2`}>{ labels.current?.PERMANENT_ACTION }</Span>
                <View style={tw`flex-row mt-5`}>
                    <Button
                        title={ labels.current?.CANCEL }
                        style={tw`w-full shrink py-2 bg-white border border-[${themes[theme].brand_primary}]`}
                        styleHover={tw`bg-white`}
                        styleText={tw`text-[${themes[theme].brand_primary}]`}
                        styleHoverText={tw`text-[${themes[theme].brand_primary}]`}
                        onPress={()=>{setDeleteFileModalActive(false)}}
                    />
                    <Button
                        title={ labels.current?.DELETE }
                        image={{ uri: require('../svg/delete_white.svg') }}
                        style={tw`ml-2 w-full shrink py-2 bg-red border border-red`}
                        styleHover={tw`bg-red`}
                        styleText={tw`text-white`}
                        styleHoverText={tw`text-white`}
                        onPress={()=>{
                            setPrimaryMsg('');
                            let payload = {
                                object_type: photoObjectType.current,
                                object_id: updateID,
                                vessel_id: newTaskYacht,
                                id: deleteFileModalAffectID,
                            };

                            fetch(apiUrl, {
                                method: 'POST',
                                cache: 'no-cache',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    endpoint: ( '' !== documentMimeType.current ? 'documents/delete' : 'pictures/delete'), v: apiVersion,
                                    client_key: localStorage.getItem('clientKey'),
                                    device_id: localStorage.getItem('deviceID'),
                                    payload: payload
                                }),
                            })
                            .then((response) => {
                                return response.text().then(function(text) {
                                    const result = JSON.parse(text);
                                    if ( result.error )
                                    {
                                        if ( '401' === result.error ){ setLoggedIn(false); result.error = 'Your session token has expired, please log in again.'; }
                                        toast.show(result.error, {type: 'danger', duration:10000});
                                    }
                                    else
                                    {
                                        setDeleteFileModalAffectID('');
                                        setDeleteFileModalActive(false);
                                        setViewingPhoto(false);
                                        toast.show((labels.current?.TOAST_DELETE).replace('{object}',labels.current?._ASSET+' '+('' !== documentMimeType.current ? labels.current?.DOCUMENT : labels.current?.PHOTO)), {type: 'success', duration:2000});
                                        documentMimeType.current = '';

                                        setTimeout(()=>{
                                            setLastUpdate(now()); // forces app to refetch all account data
                                        }, 250);
                                    }
                                });
                            })
                            .catch(error => {
                                console.warn(error);
                                if ( ! window.navigator.onLine ) { toast.show('Internet connection failed. Please try again.', {type: 'danger', duration:10000}); }
                                else { toast.show('An error ocurred. See console log.', {type: 'danger', duration:10000}); }
                            });
                        }}
                    />
                </View>
            </Modal>
        </View>
    );
}

export default MyTasksView;
