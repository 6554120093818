import React from 'react';
import { View, ScrollView, Image, Pressable/*, Switch*/, Linking, Platform, useWindowDimensions } from 'react-native';
import { create, useDeviceContext } from 'twrnc';
import { PrimaryNav, Modal, LargeModal, Heading, Span, HorizontalRule, Button, TextInput, PillMenu, SelectMenu, Notification } from '../elements';
import { getLabel, getSubscription, getYachtOptions, getEngineOptions, getEquipmentOptions } from '../helpers';
import { GlobalContext } from '../global';
import DOMPurify from 'dompurify';

function AIServiceView ({navigation})
{
    const tw = create( require(`../../tailwind.config.js`) );    
    useDeviceContext(tw);
    const {height, width} = useWindowDimensions();
    
    let {
        version,
        apiUrl,
        apiVersion,
        loggedIn, setLoggedIn,
        themes, theme
    } = React.useContext(GlobalContext);

    React.useEffect(()=> 
    {        
        if ( ! loggedIn ) { navigation.navigate('loading'); }
        // if ( ! getSubscription('feature_ai') ) { navigation.navigate('yachts'); }
    },[loggedIn, navigation]);

    const [newAssetMsg, setNewAssetMsg] = React.useState('');
    const [newAssetMsgType, setNewAssetMsgType] = React.useState('warning');
    const [newAiRequest, doNewAiRequest] = React.useState(false);
    
    const [requestType, setRequestType] = React.useState('');
    const [yachtID, setYachtID] = React.useState(0);
    const [yachtSelectVisible, setYachtSelectVisible] = React.useState(false);
    const [engineID, setEngineID] = React.useState(0);
    const [engineSelectVisible, setEngineSelectVisible] = React.useState(false);
    const [equipmentID, setEquipmentID] = React.useState(0);
    const [equipmentSelectVisible, setEquipmentSelectVisible] = React.useState(false);
    const [notes, setNotes] = React.useState(0);
    const [aiResponse, setAiResponse] = React.useState('');

    const notesInput = React.useRef(null);

    const yachtOptions = getYachtOptions();
    const [engineOptions, setEngineOptions] = React.useState([]);
    const [equipmentOptions, setEquipmentOptions] = React.useState([]);

    const [modalActive, setModalActive] = React.useState(false);
    const [disclaimerModalActive, setDisclaimerModalActive] = React.useState(false);

    const labels = React.useRef(null);
    React.useEffect(()=>
    {
        if ( ! labels.current )
        {
            labels.current = {
                _ASSETS: getLabel('AI_SERVICE'),
                EQUIPMENT: getLabel('EQUIPMENT'),
                ENGINES: getLabel('ENGINES'),
                ENGINE: getLabel('ENGINE'),
                YACHT: getLabel('YACHT'),
                TYPE: getLabel('TYPE'),
                NOTE: getLabel('NOTE'),
                RESET: getLabel('RESET'),
                REQUEST: getLabel('REQUEST'),
                CANCEL: getLabel('CANCEL'),
                SELECT: getLabel('SELECT'),
                AI_STEP_1: getLabel('AI_STEP_1'),
                AI_STEP_2: getLabel('AI_STEP_2'),
                AI_STEP_3: getLabel('AI_STEP_3'),
                AI_STEP_4: getLabel('AI_STEP_4'),
                AI_DISCLAIMER_BODY: getLabel('AI_DISCLAIMER_BODY'),
                AI_DISCLAIMER_TITLE: getLabel('AI_DISCLAIMER_TITLE'),
            };
        }
        setRequestType(labels.current?.EQUIPMENT);
    },[labels, setRequestType]);

    React.useEffect(()=>
    {
        if ( 1 === yachtOptions.length ) { setYachtID(yachtOptions[0].value); }

    },[yachtOptions, setYachtID]);

    React.useEffect(()=>
    {
        if ( ! yachtID ) { setEngineID(''); return; }

        let theseEngineOptions = getEngineOptions(yachtID);
        setEngineOptions(theseEngineOptions);

        // if newTaskOwner doesn't exist in the new ownerOptions, set it to an empty value
        if ( engineID && ! theseEngineOptions.find(obj => {return obj.value == engineID}) )
        {
            setEngineID('');
        }

    },[yachtID, setEngineOptions]);

    React.useEffect(()=>
    {
        if ( ! yachtID ) { setEquipmentID(''); return; }

        let theseEquipmentOptions = getEquipmentOptions(yachtID);
        setEquipmentOptions(theseEquipmentOptions);

        // if newTaskOwner doesn't exist in the new ownerOptions, set it to an empty value
        if ( equipmentID && ! theseEquipmentOptions.find(obj => {return obj.value == equipmentID}) )
        {
            setEquipmentID('');
        }

    },[yachtID, setEquipmentOptions]);

    const HtmlRenderer = ({ htmlContent }) => {
        const purifier = DOMPurify();

        // Hook: Add target="_blank" to all <a> tags
        purifier.addHook('afterSanitizeAttributes', (node) => {
            if (node.tagName === 'A') {
                node.setAttribute('target', '_blank');
                node.setAttribute('rel', 'noopener noreferrer');
            }
        });

        const sanitizedHtml = purifier.sanitize(htmlContent);

        return (
            <div
                dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
                style={{ width: '100%', marginTop: 0 }}
            />
        );
    };

    return (
        <View label="aiServiceView" style={tw`bg-gray0 h-screen md:flex-row overflow-hidden max-w-screen`}>

            <PrimaryNav navigation={navigation} setLoggedIn={setLoggedIn} />
        
            <View style={tw`bg-[${themes[theme].brand_primary}] h-8 md:h-full md:w-10 z-0 -mb-3 md:mb-0 md:-mr-3`}>
                <View style={tw`bg-gray0 h-full w-full rounded-t-3xl md:rounded-l-3xl md:rounded-r-0 z-10 md:shadow-black md:shadow-opacity-10 md:shadow-radius-4`}></View>
            </View>

            <View style={[tw`bg-gray0 flex-grow md:pr-8 md:pl-4 z-20`, {width: tw.prefixMatch('md') ? 'calc(100% - 320px)' : '100%', height: tw.prefixMatch('md') ? '100%' : 'calc(100% - 112px)'}]}>
                <View style={tw`h-13 px-5 pb-5 md:py-0 md:px-0 md:h-24 lg:h-33 flex-row md:items-center z-10`}>
                    <Heading style={tw`mt-1.5 md:mt-0 leading-4 mb-0`} hlevel={1} styleHlevel={tw.prefixMatch('md') ? 2 : 4}>
                        { labels.current?._ASSETS }
                    </Heading>
                </View>

                <ScrollView
                    style={{width:'100%',overflowX:tw.prefixMatch('md') ? 'visible' : 'hidden'}}
                    contentContainerStyle={tw`w-full justify-start px-5 pb-5 md:pb-0 md:px-0`}
                >
                    { '' !== newAssetMsg &&
                        <View style={tw`w-full md:pr-2`}>
                            <Notification
                                style={tw`my-2`}
                                type={newAssetMsgType}
                            >{newAssetMsg}</Notification>
                        </View>
                    }

                    <View style={tw`px-5 md:py-0 md:px-0 flex-col justify-start items-start z-10`}>

                        {/*<Heading style={tw`mt-0`} hlevel={2} styleHlevel={tw.prefixMatch('md') ? 3 : 4}>{ labels.current?.AI_STEP_1 }</Heading>*/}

                        <View style={tw`w-full lg:w-6/12`}>
                            <PillMenu
                                style={tw`mb-8`}
                                selected={ requestType }
                                menuSpacer={0}
                                menu={[
                                    { name: labels.current?.EQUIPMENT, onPress:()=>{ setRequestType(labels.current?.EQUIPMENT) } },
                                    { name: labels.current?.ENGINES, onPress:()=>{ setRequestType(labels.current?.ENGINES) } },
                                ]}
                            />

                            <Heading style={tw`mt-0 mb-0`} hlevel={2} styleHlevel={tw.prefixMatch('md') ? 3 : 4}>{ labels.current?.AI_STEP_2?.replace('Step 2: ','') }</Heading>

                            <Pressable
                                style={[tw`my-1 md:mr-2 w-full`]}
                                onPress={()=>{setYachtSelectVisible(true)}}
                            >
                                <TextInput
                                    style={tw`pointer-events-none`}
                                    image={yachtID ? { uri: require('../svg/'+theme+'/yacht_blue.svg') } : { uri: require('../svg/yacht_gray.svg') }}
                                    placeholder={ labels.current?.YACHT }
                                    value={yachtOptions.find(obj => {return obj.value == yachtID})?.label || ''}
                                />

                                <Image
                                    accessibilityHidden={true}
                                    source={{ uri: require('../svg/'+theme+'/chevron_down_blue.svg') }}
                                    resizeMode="contain"
                                    style={tw`h-3 w-3 absolute top-1/2 -translate-y-1/2 right-4`}
                                />
                            </Pressable>

                            <View style={[tw`mt-6 transition-opacity`, ! yachtID ? tw`pointer-events-none opacity-0` : tw`opacity-100`]}>
                                <Heading style={tw`mt-0 mb-0`} hlevel={2} styleHlevel={tw.prefixMatch('md') ? 3 : 4}>{ labels.current?.AI_STEP_3?.replace('Step 3: ','') }</Heading>

                                { labels.current?.EQUIPMENT === requestType &&
                                    <Pressable
                                        style={[tw`my-1 md:mr-2 w-full`, ! yachtID && tw`grayscale cursor-not-allowed`]}
                                        onPress={()=>{yachtID && setEquipmentSelectVisible(true)}}
                                    >
                                        <TextInput
                                            style={tw`pointer-events-none`}
                                            image={equipmentID ? { uri: require('../svg/'+theme+'/equipment.svg') } : { uri: require('../svg/equipment_gray.svg') }}
                                            placeholder={ labels.current?.EQUIPMENT }
                                            value={equipmentOptions.find(obj => {return obj.value == equipmentID})?.label || ''}
                                            validateGroup={newAiRequest}
                                        />

                                        <Image
                                            accessibilityHidden={true}
                                            source={{ uri: require('../svg/'+theme+'/chevron_down_blue.svg') }}
                                            resizeMode="contain"
                                            style={tw`h-3 w-3 absolute top-1/2 -translate-y-1/2 right-4`}
                                        />
                                    </Pressable>
                                }

                                { labels.current?.ENGINES === requestType &&
                                    <Pressable
                                        style={[tw`my-1 md:mr-2 w-full`, ! yachtID && tw`grayscale cursor-not-allowed`]}
                                        onPress={()=>{yachtID && setEngineSelectVisible(true)}}
                                    >
                                        <TextInput
                                            style={tw`pointer-events-none`}
                                            image={engineID ? { uri: require('../svg/'+theme+'/engine.svg') } : { uri: require('../svg/engine_gray.svg') }}
                                            placeholder={ labels.current?.ENGINE }
                                            value={engineOptions.find(obj => {return obj.value == engineID})?.label || ''}
                                            validateGroup={newAiRequest}
                                        />

                                        <Image
                                            accessibilityHidden={true}
                                            source={{ uri: require('../svg/'+theme+'/chevron_down_blue.svg') }}
                                            resizeMode="contain"
                                            style={tw`h-3 w-3 absolute top-1/2 -translate-y-1/2 right-4`}
                                        />
                                    </Pressable>
                                }
                            </View>

                            <View style={[tw`mt-6 transition-opacity`, ( ! engineID && ! equipmentID ) ? tw`pointer-events-none opacity-0` : tw`opacity-100`]}>
                                <Heading style={tw`mt-0 mb-0`} hlevel={2} styleHlevel={tw.prefixMatch('md') ? 3 : 4}>{ labels.current?.AI_STEP_4?.replace('Step 4: ','') }</Heading>

                                <TextInput
                                    style={[tw`my-1 w-full`]}
                                    autoCapitalize="on"
                                    placeholder={ labels.current?.NOTE +'*' }
                                    multiline={true}
                                    numberOfLines={10}
                                    textAlignVertical={true}
                                    image={ notes ? { uri: require('../svg/'+theme+'/notes_blue.svg') } : { uri: require('../svg/notes_gray.svg') }}
                                    pattern="^[\s\S]{0,500}$"
                                    validatedValue={(value) => {setNotes(value);}}
                                    validateOnChange={true}
                                    validateGroup={newAiRequest}
                                    stateValue={notes}
                                    passedRef={notesInput}
                                    required
                                />
                                <View style={tw`flex-row justify-end w-full mt-1 mr-1`}>
                                    <Span style={[tw`text-sm text-gray6`, notesInput.current?.value.length > 8100 && tw`text-red`]}>{`${notesInput.current?.value.length}/500`}</Span>
                                </View>

                            </View>
                        </View>
                    </View>
                </ScrollView>

                <HorizontalRule style={tw`md:hidden`} />

                <View style={tw`flex-row my-3 mx-10 md:mx-0 w-auto lg:max-w-6/12`}>
                    <Button
                        style={tw`w-full shrink my-5 py-3.5 md:my-6.5 md:py-5 bg-white border border-[${themes[theme].brand_primary}]`}
                        styleHover={tw`bg-white`}
                        styleText={tw`text-[${themes[theme].brand_primary}]`}
                        styleHoverText={tw`text-[${themes[theme].brand_primary}]`}
                        title={labels.current?.RESET}
                        onPress={()=>{
                            setEngineID(0);
                            setEquipmentID(0);
                            setNotes('');
                        }}
                    />

                    <Button
                        style={tw`ml-2 lg:ml-6 w-full shrink my-5 py-3.5 md:my-6.5 md:py-5`}
                        title={labels.current?.REQUEST}
                        disabled={requestType && yachtID && notes && (engineID || equipmentID) ? false : true}
                        onPress={()=>{ setDisclaimerModalActive(true); }}
                    />
                </View>
            </View>

            <SelectMenu
                badge={false}
                title={ labels.current?.SELECT +' '+ labels.current?.YACHT }
                optionSet="radio"
                buttonTitle="Select"
                active={yachtSelectVisible}
                setActive={setYachtSelectVisible}
                selectedValue={yachtID}
                options={yachtOptions}
                onValueChange={(value) => {setEngineID(0); setEquipmentID(0); setNotes(''); setYachtID(value)}}
                scrollable={yachtOptions.length > 8}
                searchable={yachtOptions.length > 16}
            />

            <SelectMenu
                badge={false}
                title={ labels.current?.SELECT +' '+ labels.current?.ENGINE }
                optionSet="radio"
                buttonTitle={ labels.current?.SELECT }
                active={engineSelectVisible}
                setActive={setEngineSelectVisible}
                selectedValue={engineID}
                options={engineOptions}
                onValueChange={(value) => {setNotes(''); setEngineID(value)}}
                scrollable={engineOptions.length > 8}
                searchable={engineOptions.length > 16}
            />

            <SelectMenu
                badge={false}
                title={ labels.current?.SELECT +' '+ labels.current?.EQUIPMENT }
                optionSet="radio"
                buttonTitle={ labels.current?.SELECT }
                active={equipmentSelectVisible}
                setActive={setEquipmentSelectVisible}
                selectedValue={equipmentID}
                options={equipmentOptions}
                onValueChange={(value) => {setNotes(''); setEquipmentID(value)}}
                scrollable={equipmentOptions.length > 8}
                searchable={equipmentOptions.length > 16}
            />

            <Modal
                active={disclaimerModalActive}
                setActive={setDisclaimerModalActive}
            >
                <Heading hlevel={2} styleHlevel={3}>{ labels.current?.AI_DISCLAIMER_TITLE }</Heading>
                <Span>{ labels.current?.AI_DISCLAIMER_BODY }</Span>
                <View style={tw`flex-row mt-5`}>
                    <Button
                        title={ labels.current?.CANCEL }
                        style={tw`w-full shrink py-2 bg-white border border-[${themes[theme].brand_primary}]`}
                        styleHover={tw`bg-white`}
                        styleText={tw`text-[${themes[theme].brand_primary}]`}
                        styleHoverText={tw`text-[${themes[theme].brand_primary}]`}
                        onPress={()=>{setDisclaimerModalActive(false)}}
                    />
                    <Button
                        title={ labels.current?.REQUEST }
                        style={tw`ml-2 w-full shrink py-2`}
                        styleLoadingImage={tw`h-5 -my-2`}
                        validateGroup={newAiRequest}
                        onPress={()=>{

                            const type = labels.current?.ENGINES === requestType ? 'mechanical' : 'equipment';
                            const object_id = labels.current?.ENGINES === requestType ? engineID : equipmentID;

                            console.log('AI Service:');
                            console.log({
                                vessel_id: yachtID,
                                type: type,
                                object_id: object_id,
                                request: notes
                            });

                            if ( newAiRequest ) { return; }

                            doNewAiRequest(true);

                            setTimeout(()=>
                            {
                                // check if fields are valid

                                // only required fields should be:
                                // name (thus initials)
                                // note

                                if ( ! type
                                    || ! yachtID
                                    || ! object_id
                                    || ( ! notes && notesInput.current?.value.length > 0 )
                                )
                                {
                                    let errorMsg = [];

                                    if ( ! type )
                                        { errorMsg.push(labels.current?.AI_STEP_1?.replace('Step 1: ','').replace(':', '')); }
                                    if ( ! yachtID )
                                        { errorMsg.push(labels.current?.AI_STEP_2?.replace('Step 2: ','').replace(':', '')); }
                                    if ( ! object_id )
                                        { errorMsg.push(labels.current?.AI_STEP_3?.replace('Step 3: ','').replace(':', '')); }
                                    if ( ! notes && notesInput.current?.value.length > 0 )
                                        { errorMsg.push(labels.current?.REQUEST_VALID +' '+ labels.current?.NOTE); }

                                    setNewAssetMsgType('warning');
                                    setNewAssetMsg(errorMsg.join('\n'));
                                    doNewAiRequest(false);
                                }
                                else
                                {
                                    let payload = {
                                        type: type,
                                        object_id: object_id,
                                        request: notes
                                    };

                                    fetch(apiUrl, {
                                        method: 'POST',
                                        cache: 'no-cache',
                                        headers: {
                                            'Content-Type': 'application/json'
                                        },
                                        body: JSON.stringify({
                                            endpoint: 'ai/chat', v: apiVersion,
                                            device_id: localStorage.getItem('deviceID'),
                                            client_key: localStorage.getItem('clientKey'),
                                            payload: payload
                                        }),
                                    })
                                    .then((response) => {
                                        return response.text().then(function(text) {
                                            const result = JSON.parse(text);
                                            if ( result.error )
                                            {
                                                if ( '401' === result.error ){ setLoggedIn(false); result.error = 'Your session token has expired, please log in again.'; }
                                                setNewAssetMsgType('warning');
                                                setNewAssetMsg(result.error);
                                                doNewAiRequest(false);
                                            }
                                            else
                                            {
                                                setAiResponse(result.chat);
                                                setDisclaimerModalActive(false);
                                                setModalActive(true);
                                                doNewAiRequest(false);
                                            }
                                        });
                                    })
                                    .catch(error => {
                                        console.warn(error);
                                        setNewAssetMsgType('warning');
                                        if ( ! window.navigator.onLine ) { setNewAssetMsg('Internet connection failed. Please try again.'); }
                                        else { setNewAssetMsg('An error ocurred. See console log.'); }
                                        doNewAiRequest(false);
                                    });
                                }
                            }, 250);
                        }}
                    />
                </View>
            </Modal>

            <LargeModal
                active={modalActive}
                setActive={setModalActive}
                title={labels.current?.EQUIPMENT === requestType ? equipmentOptions.find(obj => {return obj.value == equipmentID})?.label : engineOptions.find(obj => {return obj.value == engineID})?.label }
            >
                <HtmlRenderer htmlContent={('<div class="html-renderer">'+aiResponse+'</div>')} />

            </LargeModal>
        </View>
    )
}

export default AIServiceView;
