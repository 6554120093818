import React from 'react';
import { View, ScrollView, Pressable, Linking, Image } from 'react-native';
import { create, useDeviceContext } from 'twrnc';
import { Heading, PrimaryNav, Span, Button, HorizontalRule, SpecsCard, SectionPositionBar, AddQuickImportExportButtons, Timeline, AddNewAssetButtons } from '../elements';
import { getInitials, getLabel, getOptionsByLabelGroup, getPropertiesByGroup, getShareAmnt, getCurrentRoute, getColorIDByVesselStatusID, getColorByID, prepareSchedule, formatPhoneNumber, canIhazIcon } from '../helpers';
import { GlobalContext } from '../global';

function YachtProductionView ({route, navigation})
{
    const tw = create( require(`../../tailwind.config.js`) );
    useDeviceContext(tw);

    let {
        loggedIn, setLoggedIn,
        userYachts,
        enabledFeatures,
        navigationRef,
        themes, theme
    } = React.useContext(GlobalContext);

    React.useEffect(()=>
    {
        if ( ! loggedIn ) { navigation.navigate('loading'); }
    },[loggedIn, navigation]);

    const { yachtID } = route.params;

    // const [ pillMenuValue, setPillMenuValue ] = React.useState('Details');
    const [ scrollPosition, setScrollPosition ] = React.useState(0);
    const [ yacht, setYacht ] = React.useState(0);
    const [ shareAmount, setShareAmount ] = React.useState(getShareAmnt(yachtID));
    const [ timelineExpanded, setTimelineExpanded ] = React.useState(false);

    const labels = React.useRef(null);
    React.useEffect(()=>
    {
        if ( ! labels.current )
        {
            labels.current = {
                PRODUCTION_PHASE: getLabel('MDP_PRODUCTION_PHASE'),
                AWAITING_ACTIONS: getLabel('MDP_CUSTOMER_ACTIONS'),
                NO_PENDING_ACTIONS: getLabel('MDP_NO_PENDING_ACTIONS'),
                ABOUT_PHASE: getLabel('ABOUT_PHASE'),
                ABOUT_COMPLETING_ACTIONS: getLabel('ABOUT_COMPLETING_ACTIONS'),
                MDP_TASKS_NAME: getLabel('MDP_TASKS_NAME'),
                MDP_DEALER_CONTACT: getLabel('MDP_DEALER_CONTACT'),
            };
        }
    },[]);

    const sectionsSection = React.useRef(0);
    const generalSection = React.useRef(0);
    const credentialsSection = React.useRef(0);
    const dimensionsSection = React.useRef(0);
    const electricalSection = React.useRef(0);
    const structuralSection = React.useRef(0);

    let statusOptions = getOptionsByLabelGroup('types.vessel.status', true)
        .filter(value => parseInt(value.value) < 900)
        .map(value => {
            value.badge = { uri: require('../svg/status_' + value.label.toLowerCase().replace(/\s/g, '') + '.svg') };
            return value;
        });

    let countryFlags = getPropertiesByGroup( 'value', 'data.countries', 'img_flag', false, true );
    let shorepowerQtyOptions = getOptionsByLabelGroup('types.shorepower.quantity', true);
    let roleOptions = getPropertiesByGroup( 'identifier', 'types.security.roles', 'label', false, true );

    React.useEffect(() => {
        if ( yachtID )
        {
            userYachts.every((value, index) => {
                if ( yachtID == value.id ) {

                    value = prepareSchedule(value);

                    setYacht(value);
                    return false;
                }
                return true;
            });
        }
    },[yachtID, userYachts]);

    return (
        <View label="myYacht" style={tw`bg-gray0 h-screen md:flex-row overflow-hidden max-w-screen`}>

            <PrimaryNav navigation={navigation} setLoggedIn={setLoggedIn} yachtID={yachtID} />

            <View style={tw`bg-[${themes[theme].brand_primary}] h-8 md:h-full md:w-10 z-20 md:z-0 -mb-3 md:mb-0 md:-mr-3`}>
                <View style={tw`bg-gray0 h-full w-full rounded-t-3xl md:rounded-l-3xl md:rounded-r-0 z-10 md:shadow-black md:shadow-opacity-10 md:shadow-radius-4`}></View>
            </View>

            <View style={[tw`bg-gray0 flex-grow md:pr-0 md:pl-0 z-10`, {width: tw.prefixMatch('md') ? 'calc(100% - 320px)' : '100%', height: tw.prefixMatch('md') ? '100%' : 'calc(100% - 112px)'}]}>
                {
                    // <View style={tw`h-13 px-5 pb-5 md:py-0 md:px-0 md:h-33 flex-row md:items-center z-20 bg-gray0 md:hidden`}>
                    //     <PillMenu
                    //         selected={pillMenuValue}
                    //         menu={[
                    //             {name: 'Details', onPress: ()=> {setPillMenuValue('Details'); console.log('Clicked Details')} },
                    //             {name: 'Engines', onPress: ()=> {setPillMenuValue('Engines'); console.log('Clicked Engines')} },
                    //             {name: 'Other', onPress: ()=> {setPillMenuValue('Other'); console.log('Clicked Other')} },
                    //         ]}
                    //     />
                    // </View>

                    // <HorizontalRule style={tw`md:hidden`} />
                }

                { /* ! tw.prefixMatch('md') &&
                    <SectionPositionBar
                        style={tw`z-10 px-5 pt-3 pb-5 md:hidden`}
                        styleHR={tw`top-4 w-[69.75%] left-[15%]`}
                        scroll={scrollPosition}
                        menu={[
                            {name: 'General', offset: 0, onPress: ()=> { sectionsSection.current.scrollTo({y: generalSection.current.offsetTop, animated: true}) }},
                            {name: 'Dimensions', offset: dimensionsSection.current.offsetTop, onPress: ()=> { sectionsSection.current.scrollTo({y: dimensionsSection.current.offsetTop, animated: true}) }},
                            {name: 'Electrical', offset: electricalSection.current.offsetTop, onPress: ()=> { sectionsSection.current.scrollTo({y: electricalSection.current.offsetTop, animated: true}) }},
                            {name: 'Structural', offset: structuralSection.current.offsetTop, onPress: ()=> { sectionsSection.current.scrollTo({y: structuralSection.current.offsetTop, animated: true}) }},
                        ]}
                    />
                */ }

                <View style={tw`shrink`}>

                    <View style={tw`w-full justify-start px-5 pt-1 md:px-0 md:pt-0`}></View>

                    <ScrollView
                        ref={sectionsSection}
                        style={{width:'100%', overflowX:'visible'}}
                        contentContainerStyle={tw`w-full h-full justify-start px-5 md:pr-8 md:pl-4 relative`}
                        // onScroll={(e) => { setScrollPosition(e.nativeEvent.contentOffset.y) }}
                        // scrollEventThrottle="0"
                    >
                        <View style={tw`max-w-[1084px] pb-10 justify-start`}>
                            <View style={tw`flex-row w-full items-center justify-start mt-2 md:mt-6 lg:mt-10 mb-5 md:mb-1 lg:mb-7 md:px-1`}>
                                <Span style={tw`bg-${yacht.color || 'gray6'} text-white uppercase rounded-full text-lg font-semibold shrink-0 w-14 h-14 mr-3 text-center leading-[56px]`}>{yacht.initials}</Span>

                                <View>
                                    <Heading style={tw`leading-6`} hlevel={1} styleHlevel={tw.prefixMatch('md') ? 2 : 3}>
                                        {yacht.name + (yacht?.build?.length ? ' (#'+ yacht.build[0].build_no +')' : '')}
                                    </Heading>
                                </View>
                            </View>

                            <Span style={tw`ml-2.25 mt-2 text-sm uppercase font-sans font-semibold tracking-widest`}>{labels.current?.PRODUCTION_PHASE +' '+ yacht?.phaseProgress}</Span>
                            <Span style={tw`ml-2 mt-1.75 text-3xl md:text-4xl font-sans font-bold`}>{yacht?.currentPhase}</Span>


                            <View style={tw`ml-2 mt-2 mb-4 lg:mb-6 flex-row items-center`}>
                                <Span style={[tw`ml-px h-2 w-2 rounded-full mr-2`, yacht?.tasksComplete ? tw`bg-green` : tw`bg-red`]}></Span>
                                <Span style={tw`text-sm font-sans`}>{yacht?.tasksComplete ? labels.current?.NO_PENDING_ACTIONS : labels.current?.AWAITING_ACTIONS?.replace('%s%', yacht?.tasks?.filter((el)=>{ return ! el.is_complete})?.length) }</Span>
                            </View>

                            <Pressable style={tw`lg:pointer-events-none`} onPress={()=>{ setTimelineExpanded(! timelineExpanded) }}>
                                <Timeline schedule={yacht?.build?.length ? yacht.build[0].schedule : []} style={tw`mb-4 lg:mb-8`} open={timelineExpanded}></Timeline>
                            </Pressable>

                            <View style={tw`flex-col lg:flex-row max-w-full justify-between`}>
                                <View style={[tw`flex-col px-5 py-4 min-h-64 mb-6 bg-white rounded-md shadow-lg shadow-gray7 rounded-lg`, {width:tw.prefixMatch(`lg`) ? 'calc(33% - 12px)' : '100%'}]}>
                                    <Span style={tw`text-lg font-sans font-bold mb-4`}>{labels.current?.ABOUT_PHASE}</Span>
                                    <Span style={tw`text-sm font-sans`}>{yacht?.currentPhaseDescription}</Span>
                                </View>
                                <View style={[tw`flex-col px-5 py-4 min-h-64 mb-6 bg-white rounded-md shadow-lg shadow-gray7 rounded-lg justify-between items-start`, {width:tw.prefixMatch(`lg`) ? 'calc(33% - 12px)' : '100%'}]}>
                                    <View>
                                         <View style={tw`ml-2 mb-4 flex-row items-start`}>
                                            <Span style={[tw`h-2 w-2 rounded-full mr-2 -ml-2 relative top-2.5 shrink-0`, yacht?.tasksComplete ? tw`bg-green` : tw`bg-red`]}></Span>
                                            <Span style={tw`text-lg font-sans font-bold`}>{yacht?.tasksComplete ? labels.current?.NO_PENDING_ACTIONS : labels.current?.AWAITING_ACTIONS?.replace('%s%', yacht?.tasks?.filter((el)=>{ return ! el.is_complete})?.length) }</Span>
                                        </View>
                                        <Span style={tw`text-sm font-sans`}>{labels.current?.ABOUT_COMPLETING_ACTIONS}</Span>
                                    </View>
                                    <View style={tw`mb-1 flex-row`}>
                                        <Button
                                            title={ labels.current?.MDP_TASKS_NAME }
                                            style={tw`mb-1 mr-4 py-3 inline-block shrink`}
                                            styleText={tw`text-sm tracking-widest`}
                                            onPress={()=>{
                                                navigation.navigate('yachtTasks', {yachtID: yachtID})
                                            }}
                                        />
                                        {/*<Button
                                            title={ labels.current?.CHECKLISTS }
                                            style={tw`mb-1 py-3 inline-block shrink`}
                                            styleText={tw`text-sm tracking-widest`}
                                            onPress={()=>{
                                                navigation.navigate('checklists', {yachtID: yachtID})
                                            }}
                                        />*/}
                                    </View>
                                </View>
                                <View style={[tw`flex-col px-5 py-4 min-h-64 mb-6 bg-white rounded-md shadow-lg shadow-gray7 rounded-lg justify-between items-start`, {width:tw.prefixMatch(`lg`) ? 'calc(33% - 12px)' : '100%'}]}>
                                    {yacht?.build?.length &&
                                        <View>
                                            <Span style={tw`text-lg font-sans font-bold mb-4`}>{labels?.current?.MDP_DEALER_CONTACT}</Span>
                                            <Span style={tw`text-sm font-sans font-bold`}>{yacht.build[0].dealer[0].company_name}</Span>
                                            <Span style={tw`text-sm font-sans mb-5`}>{yacht.build[0].dealer[0].name}</Span>
                                            <Pressable
                                                style={tw`w-full flex-row mb-2`}
                                                onPress={()=>{ Linking.openURL('mailto:'+ yacht.build[0].dealer[0].email) }}
                                            >
                                                <Image
                                                    accessibilityHidden={true}
                                                    source={{ uri: require('../svg/'+theme+'/email_blue.svg') }}
                                                    resizeMode="contain"
                                                    style={tw`max-h-full max-w-full h-4 w-4 mt-.5 mr-2`}
                                                />
                                                <Span style={tw`text-sm font-sans text-[${themes[theme].brand_primary}]`}>{yacht.build[0].dealer[0].email}</Span>
                                            </Pressable>
                                            <Pressable
                                                style={tw`w-full flex-row mb-2`}
                                                onPress={()=>{ Linking.openURL('tel:+'+ yacht.build[0].dealer[0].country_code + yacht.build[0].dealer[0].mobile) }}
                                            >
                                                <Image
                                                    accessibilityHidden={true}
                                                    source={{ uri: require('../svg/'+theme+'/phone.svg') }}
                                                    resizeMode="contain"
                                                    style={tw`max-h-full max-w-full h-4 w-4 mt-.5 mr-2`}
                                                />
                                                <Span style={tw`text-sm font-sans text-[${themes[theme].brand_primary}]`}>{formatPhoneNumber('+'+ yacht.build[0].dealer[0].country_code + yacht.build[0].dealer[0].mobile)}</Span>
                                            </Pressable>
                                        </View>
                                    }
                                </View>
                            </View>

                        </View>

                    </ScrollView>
                </View>
                <AddNewAssetButtons hideAddNew={true} />
            </View>
        </View>
    )
}

export default YachtProductionView;
