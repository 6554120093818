import React from 'react';
import { View, ScrollView, Pressable, Image, useWindowDimensions } from 'react-native';
import { create, useDeviceContext } from 'twrnc';
import { Heading, PrimaryNav, Span, TextInput, MemoizedSimpleTextInput as SimpleTextInput, Button, AssetItem, SelectMenu, Modal, Notification, HorizontalRule, PillMenu, ImagesUploadInput, AddNewAssetButtons, AttachmentUploadInput, DocumentUploadInput, SpecsCard, Tag } from '../elements';
import { useSpring, animated } from 'react-spring';
import { goPrevScreen, getLabel, getOptionsByLabelGroup, getPropertiesByGroup, now, is_date_in_past, is_date_within_30_days, date_DMJYYYY, YYYYMMDD_to_DMJYYYY, formatDateToLocal, getUnreadNotificationsCount, sort_by, getRouteFilter, updateRouteFilter, processImagesInput, uploadImages, canIhazIcon, getAssetPictures, getEngineOptions, getEquipmentOptions } from '../helpers';
import { GlobalContext } from '../global';
import { useToast } from 'react-native-toast-notifications';

function YachtReadingsView ({route, navigation})
{
    const AnimatedView = animated(View);
    const tw = create( require(`../../tailwind.config.js`) );
    useDeviceContext(tw);
    const {height, width} = useWindowDimensions();
    const formHeight = height - 100;
    const toast = useToast();

    let {
        apiUrl,
        apiVersion,
        settings,
        setLastUpdate,
        loggedIn, setLoggedIn,
        userYachts,
        userNotifications,
        enabledFeatures,
        themes, theme
    } = React.useContext(GlobalContext);

    React.useEffect(()=>
    {
        if ( ! loggedIn ) { navigation.navigate('loading'); }
    },[loggedIn, navigation]);

    const [getFilter, setFilter] = React.useState(null);
    const [filterVisible, setFilterVisible] = React.useState(false);

    const labels = React.useRef(null);
    React.useEffect(()=>
    {
        if ( ! labels.current )
        {
            labels.current = {
                _ASSETS: getLabel('READINGS'),
                _ASSET: getLabel('READING'),
                _404_TEXT: getLabel('READINGS_404_TEXT'),
                VIEW: getLabel('VIEW'),
                SORT: getLabel('SORT'),
                EDIT: getLabel('EDIT'),
                DELETE: getLabel('DELETE'),
                CANCEL: getLabel('CANCEL'),
                NOTIFICATIONS: getLabel('NOTIFICATIONS'),
                CONTENT_ADD: getLabel('CONTENT_ADD'),
                ADD: getLabel('ADD'),
                UPDATE: getLabel('UPDATE'),
                TYPE: getLabel('TYPE'),
                VALUE: getLabel('VALUE'),
                EQUIPMENT: getLabel('EQUIPMENT'),
                ENGINE: getLabel('ENGINE'),
                LATITUDE: getLabel('LATITUDE'),
                LONGITUDE: getLabel('LONGITUDE'),
                ALL: getLabel('ALL'),
                SELECT: getLabel('SELECT'),
                SELECTED: getLabel('SELECTED'),
                VESSEL: getLabel('VESSEL'),
                /*REQUEST: getLabel('REQUEST'),
                REQUEST_VALID: getLabel('REQUEST_VALID'),
                DELETE_CONFIRM_TITLE: getLabel('DELETE_CONFIRM_TITLE'),
                DELETE_CONFIRM_BODY: getLabel('DELETE_CONFIRM_BODY'),
                DELETE_CONFIRM2_BODY: getLabel('DELETE_CONFIRM2_BODY'),
                PERMANENT_ACTION: getLabel('PERMANENT_ACTION'),
                REQUIRED: getLabel('REQUIRED'),*/
                FEATURE_MOBILE_ONLY_TITLE: getLabel('FEATURE_MOBILE_ONLY_TITLE'),
                FEATURE_MOBILE_ONLY_BODY: getLabel('FEATURE_MOBILE_ONLY_BODY'),
                BACK: getLabel('BACK'),

                DETAILS: getLabel('DETAILS'),
                PHOTOS: getLabel('PHOTOS'),
                PHOTO: getLabel('PHOTO'),
                _404_PHOTOS_TEXT: getLabel('PHOTOS_404_TEXT'),

                TOAST_UPDATE: getLabel('TOAST_UPDATE'),
                TOAST_ADD: getLabel('TOAST_ADD'),
                TOAST_DELETE: getLabel('TOAST_DELETE'),
            };
        }
        setFilter(getRouteFilter('readings') || getLabel('ALL'));
    },[]);

    const [notificationCount, setNotificationCount] = React.useState(0);
    React.useEffect(()=>
    {
        setNotificationCount(getUnreadNotificationsCount())
    },[userNotifications]);

    const { yachtID } = route.params;
    const [ yacht, setYacht ] = React.useState(0);

    const [primaryMsg, setPrimaryMsg] = React.useState('');
    const [primaryMsgType, setPrimaryMsgType] = React.useState('warning');
    const [modalActive, setModalActive] = React.useState(false);
    const [modalAffectID, setModalAffectID] = React.useState(0);

    const [mobileOnlyFeatureModalActive, setMobileOnlyFeatureModalActive] = React.useState(false);

    const [addingNew, setAddingNew] = React.useState(false);
    const [updateID, setUpdateID] = React.useState(0);

    const [viewingAsset, setViewingAsset] = React.useState(false);
    const [viewingAssetObj, setViewingAssetObj] = React.useState({});

    // const [newAssetMsg, setNewAssetMsg] = React.useState('');
    // const [newAssetMsgType, setNewAssetMsgType] = React.useState('warning');
    // const [newAssetValidation, doNewAssetValidation] = React.useState(false);
    const [getAssets, setAssets] = React.useState([]);

    // asset specific input parameters
    // const [newName, setNewName] = React.useState('');

    // input refs for asset specific inputs
    // const newNameInput = React.useRef(null);

    // select menus
    // const [typeSelectVisible, setTypeSelectVisible] = React.useState(false);

    const typeOptions = getOptionsByLabelGroup('types.reading.types', true, 'display_order');
    const typeFilterOptions = [{value: getLabel('ALL'), label: getLabel('ALL')}].concat(typeOptions);
    // const typeUnits = getPropertiesByGroup( 'value', 'types.reading.types', 'unit_id', false, true );
    const unitTypes = getOptionsByLabelGroup('types.reading.units', true, 'display_order');

    const engineOptions = getEngineOptions( yachtID, false, true );
    const equipmentOptions = getEquipmentOptions( yachtID );

    // refs and states for photos
    const photoObjectType = React.useRef('obj_note');
    const newPhotosInput = React.useRef(null);
    const [contentType, setContentType] = React.useState(getLabel('DETAILS'));
    const [photos, setPhotos] = React.useState([]);
    const [viewingPhoto, setViewingPhoto] = React.useState(false);
    const [viewingPhotoObj, setViewingPhotoObj] = React.useState({});
    const [deleteFileModalActive, setDeleteFileModalActive] = React.useState(false);
    const [deleteFileModalAffectID, setDeleteFileModalAffectID] = React.useState('');
    const [isImageUploading, setIsImageLoading] = React.useState(false);
    const iDsContainingFiles = React.useRef([]);
    const attachmentMimeType = React.useRef('');
    const prepareAssetPhotos = React.useCallback((asset)=>
    {
        let photoAssets = [];

        if ( getAssetPictures(yachtID, asset.id, userYachts).length )
        {
            let images = getAssetPictures(yachtID, asset.id, userYachts).sort(sort_by('modified_date', true));
            let imageWidth = (width - 75)/3;
            images.forEach((photo, index) => {
                photoAssets.push(
                    <Pressable
                        key={index}
                        style={tw`h-[${imageWidth}px] w-[${imageWidth}px] md:h-32 md:w-32`}
                        onPress={()=>{
                            // recalculate optimal image display width and height given the max height should be 80vh for md devices and height - 142 for sm devices, and a max width for md devices being 80vw and 100vw for sm devices
                            let displayWidth = width;
                            let displayHeight = height - 142;
                            if ( tw.prefixMatch('md') ) { displayWidth = Math.floor(width * 0.8); displayHeight = Math.floor(height * 0.8); }
                            if ( photo.width > displayWidth ) {
                                photo.height = Math.ceil((displayWidth / photo.width) * photo.height);
                                photo.width = displayWidth;
                            }
                            if ( photo.height > displayHeight ) {
                                photo.width = Math.floor((displayHeight / photo.height) * photo.width);
                                photo.height = displayHeight;
                            }
                            attachmentMimeType.current = '';
                            setViewingPhotoObj(photo);
                            setViewingPhoto(true);
                        }}
                    >
                        <Image
                            source={{ uri: photo.uri }}
                            resizeMode="cover"
                            style={[tw`bg-gray1 w-full h-full border-b border-white`, (index + 1) % 3 === 0 ? tw`border-r-0 md:border-r` : tw`border-r`]}
                        />
                    </Pressable>
                )
            });
        }

        return photoAssets;
    },[userYachts, width, height, labels, setViewingPhoto, setViewingPhotoObj]);
    let viewPhotoAnimation = useSpring(
    {
        from: {
            willChange: 'transform',
            zIndex: 91,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: tw.color('gray0'),
            boxShadow: 'rgb(0 0 0 / 50%) 0px 0px 24px',
            transform: 'translateX(110%)',
        },
        to: {
            transform: viewingPhoto ? 'translateX(0%)' : 'translateX(110%)',
        },
        config: viewingPhoto ? { mass: 1, tension: 190, friction: 30 } : { mass: 1, tension: 190, friction: 15 }
    });
    let viewPhotoAnimationDesktop = useSpring(
    {
        from: {
            willChange: 'opacity',
            zIndex: 91,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            opacity: 0,
            justifyContent: 'center',
            alignItems: 'center',
            pointerEvents: 'none',
        },
        to: {
            opacity: viewingPhoto ? 1 : 0,
            pointerEvents: viewingPhoto ? 'auto' : 'none',
        },
        config: viewingPhoto ? { mass: 1, tension: 190, friction: 30 } : { mass: 1, tension: 190, friction: 15 }
    });
    viewPhotoAnimation = tw.prefixMatch('md') ? viewPhotoAnimationDesktop : viewPhotoAnimation;

    React.useEffect(() => {
        let assets = [];
        console.log(engineOptions);

        if ( yachtID )
        {
            let yachtObj = false;
            userYachts.every((value, index) => {
                if ( yachtID == value.id ) { setYacht(value); yachtObj = value; return false; }
                return true;
            });

            let readings = [];

            if ( yachtObj.readings )
            {
                switch(getFilter)
                {
                    case labels.current?.ALL:
                        updateRouteFilter('readings', labels.current?.ALL);
                        readings = [...yachtObj.readings].sort(sort_by('modified_date', true));
                        break;

                    default:
                        updateRouteFilter('readings', getFilter);
                        readings = yachtObj.readings.filter(function (asset)
                        {
                            return asset.type_id == getFilter;
                        });
                        readings = [...readings].sort(sort_by('modified_date', true));
                        break;
                }

                readings.forEach((asset, index) => {
                    let icon = 'readings';
                    let icon_variant = '_white';
                    icon = icon + icon_variant;
                    icon = canIhazIcon( icon ) ? icon : 'miscellaneous' + icon_variant;
                    asset.name = asset.object_type === 'obj_engine' ? engineOptions.find(obj => { return parseInt(obj.value) === parseInt(asset.object_id) })?.label : ( asset.object_type === 'obj_equipment' ? equipmentOptions.find(obj => { return parseInt(obj.value) === parseInt(asset.object_id) })?.label : labels.current?.VESSEL );
                    asset.units = unitTypes.find(obj => { return obj.value === asset.unit_id; })?.label || '';

                    photos[asset.id] = prepareAssetPhotos(asset);
                    if ( asset.pictures?.length > 0 && ! iDsContainingFiles.current.includes(asset.id) ) { iDsContainingFiles.current.push(asset.id); }

                    assets.push(
                        <AssetItem
                            key={asset.id}
                            minHeight={100}
                            color='blue'
                            icon={{ uri: require('../svg/'+icon+'.svg') }}
                            title={ asset.name }
                            subtitle={ getAssetPictures(yachtID, asset.id, userYachts).length > 0 &&
                                <View style={tw`flex-row`}>
                                    { getAssetPictures(yachtID, asset.id, userYachts).length > 0 && enabledFeatures.photos &&
                                        <Image
                                            accessibilityHidden={true}
                                            source={{ uri: require('../svg/photos_lightgray.svg') }}
                                            resizeMode="contain"
                                            style={tw`h-4 w-4 mt-1 ml-2`}
                                        />
                                    }
                                </View>
                            }
                            description={ asset.type +': '+ asset.value +' '+ asset.units }
                            description2={ date_DMJYYYY( asset.modified_date ) }
                            menu={[
                                { name:labels.current?.DELETE, color:'red', onPress: ()=>{ setMobileOnlyFeatureModalActive(true); /*setModalAffectID(asset.id); setModalActive(true)*/ } }
                            ]}
                            onPress={() => {
                                setViewingAssetObj(asset);
                                setViewingAsset(true);
                            }}
                        />
                    )
                });
            }
        }

        setAssets(assets);
    },[getFilter, yachtID, userYachts, navigation, setAssets, labels]);

    let addNewPaneAnimation = useSpring(
    {
        from: {
            willChange: 'transform',
            zIndex: 91,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: tw.color('gray0'),
            boxShadow: 'rgb(0 0 0 / 50%) 0px 0px 24px',
            transform: 'translateX(110%)',
        },
        to: {
            transform: addingNew ? 'translateX(0%)' : 'translateX(110%)',
        },
        config: addingNew ? { mass: 1, tension: 190, friction: 30 } : { mass: 1, tension: 190, friction: 15 }
    });

    let addNewPaneAnimationDesktop = useSpring(
    {
        from: {
            willChange: 'opacity',
            zIndex: 91,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            opacity: 0,
            justifyContent: 'center',
            alignItems: 'center',
            pointerEvents: 'none',
            backdropFilter: 'blur(3px)',
        },
        to: {
            opacity: addingNew ? 1 : 0,
            pointerEvents: addingNew ? 'auto' : 'none',
        },
        config: addingNew ? { mass: 1, tension: 190, friction: 30 } : { mass: 1, tension: 190, friction: 15 }
    });

    addNewPaneAnimation = tw.prefixMatch('md') ? addNewPaneAnimationDesktop : addNewPaneAnimation;

    let viewPaneAnimation = useSpring(
    {
        from: {
            willChange: 'transform',
            zIndex: 91,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: tw.color('gray0'),
            boxShadow: 'rgb(0 0 0 / 50%) 0px 0px 24px',
            transform: 'translateX(110%)',
        },
        to: {
            transform: viewingAsset ? 'translateX(0%)' : 'translateX(110%)',
        },
        config: viewingAsset ? { mass: 1, tension: 190, friction: 30 } : { mass: 1, tension: 190, friction: 15 }
    });

    let viewPaneAnimationDesktop = useSpring(
    {
        from: {
            willChange: 'opacity',
            zIndex: 91,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            opacity: 0,
            justifyContent: 'center',
            alignItems: 'center',
            pointerEvents: 'none',
            backdropFilter: 'blur(3px)',
        },
        to: {
            opacity: viewingAsset ? 1 : 0,
            pointerEvents: viewingAsset ? 'auto' : 'none',
        },
        config: viewingAsset ? { mass: 1, tension: 190, friction: 30 } : { mass: 1, tension: 190, friction: 15 }
    });

    viewPaneAnimation = tw.prefixMatch('md') ? viewPaneAnimationDesktop : viewPaneAnimation;

    return (
        <View label="yachtNotes" style={tw`bg-gray0 h-screen md:flex-row overflow-hidden max-w-screen`}>

            <PrimaryNav navigation={navigation} setLoggedIn={setLoggedIn} hideOnMobile={true} yachtID={yachtID} />

            <View style={tw`bg-[${themes[theme].brand_primary}] h-20 flex-row md:hidden`}>
                <View style={tw`bg-[${themes[theme].brand_primary}] h-20 p-5 pt-6.5 flex-row`}>
                    <Pressable
                        style={tw`p-2 -ml-2 -mt-2`}
                        onPress={() => { goPrevScreen(navigation, 'yacht', {yachtID: yachtID}) }}
                    >
                        <Image
                            accessibilityLabel={ labels.current?.CANCEL }
                            source={{ uri: require('../svg/back_white.svg') }}
                            resizeMode="contain"
                            style={tw`h-6 w-6 top-px mr-1`}
                        />
                    </Pressable>
                    <Span style={tw`bg-${yacht.color || 'gray6'} text-white uppercase rounded-full text-sm font-semibold shrink-0 w-8 h-8 mr-3 text-center leading-[31px] -mt-[2px]`}>{yacht.initials}</Span>
                    <Heading style={tw`mt-1.5 leading-4 mb-0 text-white`} hlevel={1} styleHlevel={4}>
                        { labels.current?._ASSETS }
                    </Heading>
                </View>

                <View style={tw`grow`}></View>

                <View style={tw`w-18 justify-center md:order-2 md:w-full`}>
                    <Pressable
                        onPress={()=>{navigation.navigate('notifications')}}
                        style={tw`p-5 relative md:flex-row md:pl-15 md:py-3 md:my-2`}
                    >
                        { notificationCount > 0 &&
                            <Span style={tw`bg-white text-[${themes[theme].brand_primary}] rounded-full min-w-4 h-4 text-center text-xs font-bold absolute bottom-1/2 left-1/2 md:left-18 z-10 pointer-events-none`}>
                                { notificationCount }
                            </Span>
                        }

                        <Image
                            accessibilityLabel={ labels.current?.NOTIFICATIONS }
                            source={{ uri: require('../svg/notifications.svg') }}
                            resizeMode="contain"
                            style={tw`h-5 md:h-8 w-full md:w-8 relative z-0 -left-1`}
                        />

                        <Span style={tw`hidden md:flex font-bold text-base text-white leading-8 ml-4 tracking-wide`}>{ labels.current?.NOTIFICATIONS }</Span>

                    </Pressable>
                </View>
            </View>

            <View style={tw`bg-[${themes[theme].brand_primary}] h-8 md:h-full md:w-10 z-0 -mb-3 md:mb-0 md:-mr-3`}>
                <View style={tw`bg-gray0 h-full w-full rounded-t-3xl md:rounded-l-3xl md:rounded-r-0 z-10 md:shadow-black md:shadow-opacity-10 md:shadow-radius-4`}></View>
            </View>

            <View style={[tw`bg-gray0 grow md:pr-8 md:pl-4 z-20`, {width: tw.prefixMatch('md') ? 'calc(100% - 320px)' : '100%', height: tw.prefixMatch('md') ? '100%' : 'calc(100% - 112px)'}]}>

                { ! tw.prefixMatch('md') && 'object' === typeof yacht && yacht.readings.length > 0 &&
                    <View style={tw`h-13 px-5 pb-5 md:py-0 md:px-0 md:h-24 lg:h-33 flex-row justify-end md:items-center z-10`}>

                        { /* <TextInput
                            style={tw`shrink-1 mr-5 p-1.5 max-w-60`}
                            image={{ uri: require('../svg/search_gray.svg') }}
                            placeholder="Search"
                            // value={filterText}
                            // onChangeText={(value)=>{setFilterText(value)}}
                        /> */ }

                        <Pressable
                            style={tw`border border-gray3 py-1 px-2 md:py-1.5 md:px-3 rounded flex-row md:mr-4 md:min-w-60`}
                            onPress={()=>{setFilterVisible(true)}}
                        >
                            <Image
                                accessibilityHidden={true}
                                source={{ uri: require('../svg/sort.svg') }}
                                resizeMode="contain"
                                style={tw`h-4 w-4 top-0.5 md:top-1 mr-2`}
                            />

                            <Span style={tw`text-sm md:text-base leading-4`}>{ labels.current?.VIEW }: <Span style={tw`text-sm md:text-base font-medium`}>{labels.current?.ALL !== getFilter ? typeFilterOptions.find(obj => {return obj.value == getFilter})?.label : getFilter}</Span></Span>

                        </Pressable>

                    </View>
                }

                <View style={! getAssets.length ? tw`grow` : tw`shrink`}>
                    <ScrollView
                        centerContent={getAssets.length ? false : true}
                        style={{width:'100%',overflowX:'visible'}}
                        contentContainerStyle={tw`w-full min-h-full justify-start`}
                    >
                        <View style={tw`hidden md:flex`}>
                            <View style={tw`flex-row flex-wrap w-full items-center justify-between lg:justify-start mt-2 md:mt-10 mb-0 md:mb-1 lg:mb-5 md:px-1`}>
                                <View style={tw`flex-row w-full mb-4 items-center lg:w-auto lg:grow lg:max-w-full`}>
                                    <Span style={tw`bg-${yacht.color || 'gray6'} text-white uppercase rounded-full text-lg font-semibold shrink-0 w-14 h-14 mr-3 text-center leading-[56px]`}>{yacht.initials}</Span>

                                    <Heading style={tw`leading-6 hidden md:flex`} hlevel={1} styleHlevel={2}>
                                        {yacht.name + (yacht?.build?.length ? ' (#'+ yacht.build[0].build_no +')' : '')}
                                    </Heading>
                                </View>

                                { 'object' === typeof yacht && yacht.readings.length > 0 &&
                                    <View style={tw`px-5 md:py-0 md:px-0 mb-4 flex-row md:items-center z-10 lg:shrink-0`}>

                                        { /* <TextInput
                                            style={tw`shrink-1 mr-5 p-1.5 max-w-60`}
                                            image={{ uri: require('../svg/search_gray.svg') }}
                                            placeholder="Search"
                                            // value={filterText}
                                            // onChangeText={(value)=>{setFilterText(value)}}
                                        /> */ }

                                        <Pressable
                                            style={tw`border border-gray3 py-1 px-2 md:py-1.5 md:px-3 rounded flex-row md:mr-4 md:min-w-60`}
                                            onPress={()=>{setFilterVisible(true)}}
                                        >
                                            <Image
                                                accessibilityHidden={true}
                                                source={{ uri: require('../svg/sort.svg') }}
                                                resizeMode="contain"
                                                style={tw`h-4 w-4 top-0.5 md:top-1 mr-2`}
                                            />

                                            <Span style={tw`text-sm md:text-base leading-4`}>{ labels.current?.VIEW }: <Span style={tw`text-sm md:text-base font-medium`}>{labels.current?.ALL !== getFilter ? typeFilterOptions.find(obj => {return obj.value == getFilter})?.label : getFilter}</Span></Span>

                                        </Pressable>

                                    </View>
                                }

                            </View>
                        </View>

                        { '' !== primaryMsg &&
                            <View style={tw`w-full px-4 md:px-1`}>
                                <Notification
                                    style={tw`my-4`}
                                    type={primaryMsgType}
                                >{primaryMsg}</Notification>
                            </View>
                        }
                        { ! getAssets.length &&
                            <View style={tw`justify-center grow items-center`}>

                                <Image
                                    accessibilityHidden={true}
                                    source={{ uri: require('../svg/notes_lightgray.svg') }}
                                    resizeMode="contain"
                                    style={tw`h-32 w-32 md:h-48 md:w-48`}
                                />
                                <Heading style={tw`text-gray5 text-center p-5 mb-26`} hlevel={tw.prefixMatch('md') ? 2 : 3}>{ yacht?.readings?.length > 0 ? getLabel('SEARCH_NOTFOUND_TITLE') : labels.current?._404_TEXT }</Heading>

                                <View style={tw`fixed bottom-9 md:bottom-18 right-18 pointer-events-none`}>

                                    <Span style={tw`text-sm md:text-base font-medium font-sans mb-2 mr-6`}>{ labels.current?.CONTENT_ADD }</Span>

                                    <Image
                                        accessibilityHidden={true}
                                        source={{ uri: require('../svg/'+theme+'/arrow_organic.svg') }}
                                        resizeMode="contain"
                                        style={tw`h-9 w-18 left-14 md:left-16`}
                                    />
                                </View>
                            </View>
                        }


                        { getAssets.length > 0 &&
                            <View>
                                <Heading hlevel={2} styleHlevel={4} style={tw`hidden md:flex pl-1`}>{ labels.current?._ASSETS }</Heading>

                                <View style={tw`md:pl-1 md:flex-wrap md:flex-row mb-20 md:justify-start md:items-start`}>
                                    {getAssets}
                                </View>
                            </View>
                        }

                    </ScrollView>

                    <AddNewAssetButtons addNewLabel={ labels.current?.ADD +' '+ labels.current?._ASSET } onAddNew={()=>{
                        setMobileOnlyFeatureModalActive(true);

                        /*setUpdateID(0);
                        setContentType(labels.current?.DETAILS);
                        setNewAssetMsg('');

                        setNewName(''); newNameInput.current.value = '';
                        setNewNotes(''); newNotesInput.current.value = '';
                        setNewType('');
                        // setNewIsPrivate(false);

                        setAddingNew(true);*/
                    }} />
                </View>
            </View>

            {/*<AnimatedView style={addNewPaneAnimation}>

                <Pressable style={tw`hidden md:flex bg-[${themes[theme].brand_primary}]/75 w-screen h-screen left-0 top-0 fixed`}
                    onPress={() => {setNewAssetMsg(''); setAddingNew(false)}}
                ></Pressable>

                <View style={tw`md:w-188 md:rounded-md md:shadow-black md:shadow-opacity-25 md:shadow-radius-8`}>

                    <View style={tw`bg-[${themes[theme].brand_primary}] md:bg-gray0 md:border-b-2 md:border-gray1 md:items-center h-20 p-5 md:px-9 pt-6.5 flex-row md:rounded-t-md`}>
                        <Pressable
                            style={tw`p-2 -ml-2 -mt-2`}
                            onPress={() => {setNewAssetMsg(''); setAddingNew(false)}}
                        >
                            <Image
                                accessibilityLabel={ labels.current?.CANCEL }
                                source={{ uri: tw.prefixMatch('md') ? require('../svg/'+theme+'/back.svg') : require('../svg/back_white.svg') }}
                                resizeMode="contain"
                                style={tw`h-6 w-6 top-px md:top-1 md:mr-4`}
                            />
                        </Pressable>
                        <Heading style={tw`mt-1.5 md:mt-0 leading-4 mb-0 text-white md:text-ink`} hlevel={1} styleHlevel={tw.prefixMatch('md') ? 2 : 4}>{ ( 0 !== updateID ? labels.current?.EDIT : labels.current?.ADD ) +' '+ labels.current?._ASSET }</Heading>
                    </View>

                    <View style={tw`bg-[${themes[theme].brand_primary}] h-8 z-0 -mb-3 md:hidden`}>
                        <View style={tw`bg-gray0 h-full w-full rounded-t-3xl`}></View>
                    </View>

                    <View style={[tw`bg-gray0 grow md:pr-4 md:pb-5 md:pt-8 md:pl-4 z-20 md:rounded-b-md w-full`, { height: tw.prefixMatch('md') ? 'max-content' : formHeight}]}>
                        <View style={tw`h-full md:h-max`}>

                            { 0 !== updateID &&
                                <PillMenu
                                    style={tw`w-auto mx-5.5 mb-3 md:mb-4 md:-mt-2`}
                                    selected={ contentType }
                                    menu={[
                                        { name:labels.current?.DETAILS, icon:{ uri: require('../svg/notes_white.svg') }, inactiveIcon:{ uri: require('../svg/'+theme+'/notes.svg') }, onPress:()=>{ setContentType(labels.current?.DETAILS); } },
                                        ...(enabledFeatures.photos ? [{ name:labels.current?.PHOTOS, icon:{ uri: require('../svg/photos_white.svg') }, inactiveIcon:{ uri: require('../svg/'+theme+'/photos.svg') }, onPress:()=>{ setContentType(labels.current?.PHOTOS); } }] : []),
                                    ]}
                                />
                            }

                            <ScrollView
                                style={{width:'100%',overflowX:tw.prefixMatch('md') ? 'visible' : 'hidden'}}
                                contentContainerStyle={tw`w-full justify-start px-5 pb-5 md:pb-0`}
                            >
                                { '' !== newAssetMsg &&
                                    <View style={tw`w-full md:pr-2`}>
                                        <Notification
                                            style={tw`my-2`}
                                            type={newAssetMsgType}
                                        >{newAssetMsg}</Notification>
                                    </View>
                                }

                                <View style={[tw`md:flex-row md:flex-wrap md:justify-between`, labels?.current?.DETAILS !== contentType && tw`hidden`]}>
                                    <TextInput
                                        style={tw`mt-2 md:w-48.5/100`}
                                        autoCapitalize="on"
                                        placeholder={ labels.current?.NAME +'*' }
                                        image={ newName ? { uri: require('../svg/'+theme+'/name_blue.svg') } : { uri: require('../svg/name_gray.svg') }}
                                        pattern="^.{2,}$"
                                        validatedValue={(value) => {setNewName(value)}}
                                        validateOnChange={true}
                                        validateGroup={newAssetValidation}
                                        stateValue={newName}
                                        passedRef={newNameInput}
                                        required
                                    />

                                    <Pressable
                                        style={tw`mt-2 md:w-48.5/100`}
                                        onPress={()=>{setTypeSelectVisible(true)}}
                                    >
                                        <TextInput
                                            style={tw`pointer-events-none`}
                                            placeholder={ labels.current?.TYPE +'*' }
                                            image={ newType && '' !== newType ? { uri: require('../svg/'+theme+'/invtype_blue.svg') } : { uri: require('../svg/invtype_gray.svg') }}
                                            // defaultValue={newType}
                                            value={newType ? typeOptions.find(obj => {return obj.value == newType})?.label : ''}
                                            validateGroup={newAssetValidation}
                                            required
                                        />

                                        <Image
                                            accessibilityHidden={true}
                                            source={{ uri: require('../svg/'+theme+'/chevron_down_blue.svg') }}
                                            resizeMode="contain"
                                            style={tw`h-3 w-3 absolute top-1/2 -translate-y-1/2 right-4`}
                                        />
                                    </Pressable>

                                    <Span style={tw`text-sm text-gray6 my-2 w-full`}>{ '* ' + labels.current?.REQUIRED }</Span>

                                    <TextInput
                                        style={tw`mt-2 md:mt-4 w-full`}
                                        autoCapitalize="on"
                                        placeholder={ labels.current?.DETAILS }
                                        multiline={true}
                                        numberOfLines={10}
                                        textAlignVertical={true}
                                        image={ newNotes ? { uri: require('../svg/'+theme+'/notes_blue.svg') } : { uri: require('../svg/notes_gray.svg') }}
                                        pattern="^[\s\S]{0,8100}$"
                                        validatedValue={(value) => {setNewNotes(value);}}
                                        validateOnChange={true}
                                        validateGroup={newAssetValidation}
                                        stateValue={newNotes}
                                        passedRef={newNotesInput}
                                    />
                                    <View style={tw`flex-row justify-end w-full mt-1 mr-1`}>
                                        <Span style={[tw`text-sm text-gray6`, newNotesInput.current?.value.length > 8100 && tw`text-red`]}>{`${newNotesInput.current?.value.length}/8100`}</Span>
                                    </View>
                                </View>

                                <View style={[tw`md:flex-row md:flex-wrap md:justify-between md:pb-5`, ( labels?.current?.PHOTOS !== contentType || ! updateID ) && tw`hidden`]}>

                                    <ScrollView
                                        style={[{width:'100%',overflowX:tw.prefixMatch('md') ? 'visible' : 'hidden'}, tw`pt-4 rounded bg-white border border-gray4 mt-2`, photos[updateID]?.length && tw`pl-4`]}
                                        contentContainerStyle={tw`w-full justify-start h-[${height - 200}px] md:h-[272px]`}
                                    >
                                        { photos[updateID]?.length ? <View style={tw`flex-wrap flex-row justify-start items-start`}>{ photos[updateID] }</View> : <View></View> }
                                        { ! photos[updateID]?.length &&
                                            <View style={tw`justify-center grow items-center mt-4`}>
                                                <Image
                                                    accessibilityHidden={true}
                                                    source={{ uri: require('../svg/photos_lightgray.svg') }}
                                                    resizeMode="contain"
                                                    style={tw`h-28 w-28 md:h-40 md:w-40`}
                                                />
                                                <Heading style={tw`text-gray5 text-center p-5`} hlevel={tw.prefixMatch('md') ? 2 : 3}>{ labels.current?._404_PHOTOS_TEXT }</Heading>
                                            </View>
                                        }
                                    </ScrollView>

                                    <ImagesUploadInput
                                        passedRef={newPhotosInput}
                                        onChange={(e)=>{
                                            if ( ! e.target.files[0] ) { return; }

                                            let imagesArray = processImagesInput(e.target.files, settings.max_size_image).then((imagesArray)=>{
                                                if ( 'string' === typeof imagesArray )
                                                {
                                                    toast.show(imagesArray, {type: 'danger', duration:10000});
                                                    return;
                                                }
                                                setIsImageLoading(true);
                                                uploadImages(
                                                    imagesArray,
                                                    photoObjectType.current,
                                                    updateID,
                                                    toast,
                                                    labels.current?.TOAST_ADD.replace('{object}',labels.current?.PHOTOS),
                                                    {apiUrl:apiUrl, apiVersion:apiVersion},
                                                    function (status) {
                                                        setIsImageLoading(false);
                                                        if ( 'success' === status )
                                                        {
                                                            setLastUpdate(now());
                                                        }
                                                    }
                                                );
                                            })
                                        }}
                                    />

                                    <AddNewAssetButtons style={tw`bottom-10 right-9 md:right-10`} hideRefresh={true} hideTooltips={true} isLoading={isImageUploading} addNewLabel={ labels.current?.ADD +' '+ labels.current?.PHOTOS } onAddNew={()=>{
                                        // trigger click on #image-picker
                                        newPhotosInput.current.click();
                                    }} />

                                </View>

                            </ScrollView>

                            <HorizontalRule style={tw`md:hidden`} />

                            <Button
                                style={[tw`m-5`, labels?.current?.DETAILS !== contentType && tw`hidden`]}
                                title={0 !== updateID ? labels.current?.UPDATE : labels.current?.ADD}
                                disabled={newName ? false : true}
                                validateGroup={newAssetValidation}
                                onPress={()=>{
                                    console.log('Note:');
                                    console.log({
                                        vessel_id: yachtID,
                                        name: newName,
                                        content: newNotes || '',
                                        type_id: newType,
                                        private: false, // newIsPrivate,
                                    });

                                    if ( newAssetValidation ) { return; }

                                    doNewAssetValidation(true);
                                    setNewAssetMsg('');

                                    setTimeout(()=>
                                    {
                                        // check if fields are valid

                                        // only required fields should be:
                                        // name (thus initials)
                                        // note

                                        if ( ! newName
                                            || ! newType
                                            || ( ! newNotes && newNotesInput.current?.value.length > 0 )
                                        )
                                        {
                                            let errorMsg = [];

                                            if ( ! newName )
                                                { errorMsg.push(labels.current?.REQUEST +' '+labels.current?.ATTACHMENT +' '+labels.current?.NAME); }
                                            if ( ! newType )
                                                { errorMsg.push(labels.current?.REQUEST +' '+labels.current?.ATTACHMENT +' '+labels.current?.TYPE); }
                                            if ( ! newNotes && newNotesInput.current?.value.length > 0 )
                                                { errorMsg.push(labels.current?.REQUEST_VALID +' '+ labels.current?.ATTACHMENT); }

                                            setNewAssetMsgType('warning');
                                            setNewAssetMsg(errorMsg.join('\n'));
                                            doNewAssetValidation(false);
                                        }
                                        else
                                        {
                                            let payload = {
                                                vessel_id: yachtID,
                                                name: newName,
                                                content: newNotes || '',
                                                type_id: newType,
                                                private: false, // newIsPrivate,
                                            };
                                            if ( 0 !== updateID ) { payload.id = updateID; }

                                            fetch(apiUrl, {
                                                method: 'POST',
                                                cache: 'no-cache',
                                                headers: {
                                                    'Content-Type': 'application/json'
                                                },
                                                body: JSON.stringify({
                                                    endpoint: 0 !== updateID ? 'notes/update' : 'notes/add', v: apiVersion,
                                                    device_id: localStorage.getItem('deviceID'),
                                                    client_key: localStorage.getItem('clientKey'),
                                                    payload: payload
                                                }),
                                            })
                                            .then((response) => {
                                                return response.text().then(function(text) {
                                                    const result = JSON.parse(text);
                                                    if ( result.error )
                                                    {
                                                        if ( '401' === result.error ){ setLoggedIn(false); result.error = 'Your session token has expired, please log in again.'; }
                                                        setNewAssetMsgType('warning');
                                                        setNewAssetMsg(result.error);
                                                        doNewAssetValidation(false);
                                                    }
                                                    else
                                                    {
                                                        setAddingNew(false);
                                                        toast.show((0 !== updateID ? labels.current?.TOAST_UPDATE : labels.current?.TOAST_ADD).replace('{object}',labels.current?._ASSET), {type: 'success', duration:2000});

                                                        setTimeout(()=>{
                                                            setLastUpdate(now()); // forces app to refetch all account data
                                                        }, 500);

                                                        setTimeout(()=>{
                                                            doNewAssetValidation(false);
                                                            setNewName(''); newNameInput.current.value = '';
                                                            setNewType('');
                                                            setNewNotes(''); newNotesInput.current.value = '';
                                                            // setNewIsPrivate(false);
                                                        }, 1500);
                                                    }
                                                });
                                            })
                                            .catch(error => {
                                                console.warn(error);
                                                setNewAssetMsgType('warning');
                                                if ( ! window.navigator.onLine ) { setNewAssetMsg('Internet connection failed. Please try again.'); }
                                                else { setNewAssetMsg('An error ocurred. See console log.'); }
                                                doNewAssetValidation(false);
                                            });
                                        }
                                    }, 250);


                                }}
                            />
                        </View>
                    </View>
                </View>
            </AnimatedView>*/}

            <AnimatedView style={viewPaneAnimation}>

                <Pressable style={tw`hidden md:flex bg-[${themes[theme].brand_primary}]/75 w-screen h-screen left-0 top-0 fixed`}
                    onPress={() => {setViewingAsset(false)}}
                ></Pressable>

                <View style={tw`md:w-188 md:rounded-md md:shadow-black md:shadow-opacity-25 md:shadow-radius-8`}>

                    <View style={tw`bg-[${themes[theme].brand_primary}] md:bg-gray0 md:border-b-2 md:border-gray1 md:items-center h-20 p-5 md:px-9 pt-6.5 flex-row md:rounded-t-md`}>
                        <Pressable
                            style={tw`p-2 -ml-2 -mt-2`}
                            onPress={() => {setViewingAsset(false)}}
                        >
                            <Image
                                accessibilityLabel={ labels.current?.CANCEL }
                                source={{ uri: tw.prefixMatch('md') ? require('../svg/'+theme+'/back.svg') : require('../svg/back_white.svg') }}
                                resizeMode="contain"
                                style={tw`h-6 w-6 top-px md:top-1 md:mr-4`}
                            />
                        </Pressable>
                        <Heading style={tw`mt-1.5 leading-4 mb-0 text-white md:hidden`} hlevel={1} styleHlevel={tw.prefixMatch('md') ? 2 : 4}>{ labels.current?._ASSET }</Heading>
                        <View style={tw`justify-between hidden md:flex md:flex-row grow mt-0 mb-0 items-start h-full`}>
                            <View style={tw`self-center`}>
                                <Heading style={tw`leading-4 mt-0 mb-0 text-ink`} hlevel={1} styleHlevel={2}>{ viewingAssetObj.name }</Heading>
                            </View>

                            <View style={tw`self-center`}>
                                <View style={tw`flex-row items-center`}>
                                    <Image
                                        accessibilityHidden={true}
                                        source={{ uri: require('../svg/date.svg') }}
                                        resizeMode="contain"
                                        style={tw`h-4 w-4 mr-2`}
                                    />
                                    <Span style={tw`text-gray6 text-sm leading-6 text-right`}>{ date_DMJYYYY( viewingAssetObj.modified_date ) }</Span>
                                </View>
                            </View>
                        </View>
                    </View>

                    <View style={tw`bg-[${themes[theme].brand_primary}] h-8 z-0 -mb-3 md:hidden`}>
                        <View style={tw`bg-gray0 h-full w-full rounded-t-3xl`}></View>
                    </View>

                    <View style={[tw`bg-gray0 grow md:pr-4 md:pb-5 md:pt-8 md:pl-4 z-20 md:rounded-b-md w-full`, { height: tw.prefixMatch('md') ? 'max-content' : 'calc(100vh - 100px)'}]}>
                        <View style={tw`h-full md:h-max`}>
                            <View style={tw`w-full justify-between px-5 mb-4 flex-row md:hidden`}>
                                <View>
                                    <Span style={tw`font-bold`}>{ ('2' === String(viewingAssetObj.type_id) || '7' === String(viewingAssetObj.type_id) ) ? typeOptions.find(obj => {return obj.value == viewingAssetObj.type_id})?.label : viewingAssetObj.name }</Span>
                                    <View style={tw`flex-row items-center`}>
                                        <Image
                                            accessibilityHidden={true}
                                            source={{ uri: require('../svg/date.svg') }}
                                            resizeMode="contain"
                                            style={tw`h-4 w-4 mr-2`}
                                        />
                                        <Span style={tw`text-gray6 text-sm leading-6`}>{ date_DMJYYYY( viewingAssetObj.modified_date ) }</Span>
                                    </View>
                                </View>

                                { /* false !== viewingAssetObj.is_private &&
                                    <View>
                                        <Span style={tw`text-gray6 text-sm md:mr-5 shrink-0`}>{ labels.current?.PRIVATE }</Span>
                                    </View>
                                */ }
                            </View>
                            <HorizontalRule style={tw`mb-4 md:hidden`} />

                            { ( 0 !== photos[viewingAssetObj.id]?.length ) &&
                                <PillMenu
                                    style={tw`w-auto mx-5.5 mb-3 md:mb-4 md:-mt-2`}
                                    selected={ contentType }
                                    menu={[
                                        { name:labels.current?.DETAILS, icon:{ uri: require('../svg/notes_white.svg') }, inactiveIcon:{ uri: require('../svg/'+theme+'/notes.svg') }, onPress:()=>{ setContentType(labels.current?.DETAILS); } },
                                        ...(enabledFeatures.photos && 0 !== photos[viewingAssetObj.id]?.length ? [{ name:labels.current?.PHOTOS, icon:{ uri: require('../svg/photos_white.svg') }, inactiveIcon:{ uri: require('../svg/'+theme+'/photos.svg') }, onPress:()=>{ setContentType(labels.current?.PHOTOS); } }] : []),
                                    ]}
                                />
                            }

                            <ScrollView
                                style={{width:'100%',overflowX:tw.prefixMatch('md') ? 'visible' : 'hidden'}}
                                contentContainerStyle={tw`w-full justify-start px-5`}
                            >
                                <View style={[tw`md:flex-row md:flex-wrap md:justify-between content-start`, tw`md:min-h-[300px]`, labels?.current?.DETAILS !== contentType && tw`hidden`]}>
                                    <View style={tw`md:flex-row md:flex-wrap md:px-1 w-full max-w-full`}>
                                        <SpecsCard
                                            style={[tw`md:mr-2`, {width: tw.prefixMatch('md') ? 'calc(50% - 8px)' : '100%'}]}
                                            label={ labels.current?.TYPE }
                                            labelImage={{ uri: require('../svg/'+theme+'/type.svg') }}
                                            value={viewingAssetObj.type}
                                        />
                                        { 'obj_engine' === viewingAssetObj.object_type &&
                                            <SpecsCard
                                                style={[tw`md:mr-2`, {width: tw.prefixMatch('md') ? 'calc(50% - 8px)' : '100%'}]}
                                                label={ labels.current?.ENGINE }
                                                labelImage={{ uri: require('../svg/'+theme+'/engine.svg') }}
                                                value={ viewingAssetObj.name }
                                                onPress={()=>{setViewingAsset(false); navigation.navigate('engines', {yachtID:viewingAssetObj.vessel_id}) }}
                                            />
                                        }
                                        { 'obj_equipment' === viewingAssetObj.object_type &&
                                            <SpecsCard
                                                style={[tw`md:mr-2`, {width: tw.prefixMatch('md') ? 'calc(50% - 8px)' : '100%'}]}
                                                label={ labels.current?.EQUIPMENT }
                                                labelImage={{ uri: require('../svg/'+theme+'/equipment.svg') }}
                                                value={ viewingAssetObj.name }
                                                onPress={()=>{setViewingAsset(false); navigation.navigate('equipment', {yachtID:viewingAssetObj.vessel_id}) }}
                                            />
                                        }
                                        <SpecsCard
                                            style={[tw`md:mr-2`, {width: tw.prefixMatch('md') ? 'calc(50% - 8px)' : '100%'}]}
                                            label={ labels.current?.VALUE }
                                            labelImage={{ uri: require('../svg/'+theme+'/calendar_blue.svg') }}
                                            value={ viewingAssetObj.value +' '+ viewingAssetObj.units }
                                        />
                                    </View>

                                    {/*<View style={tw`w-full p-1 max-w-full`}>
                                        <Heading hlevel={2} styleHlevel={tw.prefixMatch('md') ? 3 : 5} style={tw`mt-5`}>{ labels.current?.DETAILS }</Heading>
                                        <Span>{ viewingAssetObj.details }</Span>
                                    </View>*/}
                                </View>

                                <View style={[tw`md:flex-row md:flex-wrap md:justify-between md:min-h-[300px]`, labels?.current?.PHOTOS !== contentType && tw`hidden`]}>
                                    <ScrollView
                                        style={[{width:'100%',overflowX:tw.prefixMatch('md') ? 'visible' : 'hidden'}, tw`px-4 max-w-full`, photos[updateID]?.length && tw`pl-4`]}
                                        contentContainerStyle={tw`w-full justify-start h-[${height - 290}px] md:h-[272px] mt-2`}
                                    >
                                        { photos[viewingAssetObj.id]?.length ? <View style={tw`flex-wrap flex-row justify-start items-start`}>{ photos[viewingAssetObj.id] }</View> : <View></View> }
                                    </ScrollView>
                                </View>
                            </ScrollView>
                        </View>
                    </View>
                </View>
            </AnimatedView>

            <AnimatedView style={viewPhotoAnimation}>

                <Pressable style={tw`hidden md:flex w-screen h-screen left-0 top-0 fixed`}
                    onPress={() => {setViewingPhoto(false)}}
                ></Pressable>

                <View style={tw`w-full md:w-[${viewingPhotoObj.width}px] md:max-w-[80vw] md:rounded-md md:shadow-black md:shadow-opacity-25 md:shadow-radius-8`}>

                    <View style={tw`bg-[${themes[theme].brand_primary}] md:bg-gray0 md:border-b-2 md:border-gray1 md:items-center h-20 p-5 md:pl-9 pt-6.5 flex-row md:rounded-t-md`}>
                        <Pressable
                            style={tw`p-2 -ml-2 -mt-2`}
                            onPress={() => {setViewingPhoto(false)}}
                        >
                            <Image
                                accessibilityLabel={ labels.current?.CANCEL }
                                source={{ uri: tw.prefixMatch('md') ? require('../svg/'+theme+'/back.svg') : require('../svg/back_white.svg') }}
                                resizeMode="contain"
                                style={tw`h-6 w-6 top-px md:top-1 md:mr-4`}
                            />
                        </Pressable>
                        <Heading style={tw`mt-1.5 leading-4 mb-0 text-white md:hidden`} hlevel={1} styleHlevel={tw.prefixMatch('md') ? 2 : 4}>{ labels.current?._ASSET +' '+ labels.current?.PHOTOS }</Heading>
                        <View style={tw`justify-between hidden md:flex md:flex-row grow mt-0 mb-0 items-start h-full`}>
                            <View style={tw`self-center`}>
                                <Heading style={tw`leading-4 mt-0 mb-0 text-ink`} hlevel={1} styleHlevel={4}>{ viewingPhotoObj.description }</Heading>
                            </View>

                            <View style={tw`self-center`}>
                                <View style={tw`flex-row items-center`}>
                                    <Image
                                        accessibilityHidden={true}
                                        source={{ uri: require('../svg/date.svg') }}
                                        resizeMode="contain"
                                        style={tw`h-4 w-4 mr-2`}
                                    />
                                    <Span style={tw`text-gray6 text-sm leading-6 text-right`}>{ date_DMJYYYY( viewingPhotoObj.modified_date ) }</Span>

                                    { 0 !== updateID && true === addingNew && ! viewingPhotoObj.is_restricted && ! yacht?.is_restricted && ! viewingAssetObj?.is_static &&
                                        <Pressable
                                            style={(state) => [tw`p-2 rounded bg-gray2 ml-5`, state.hovered && tw`bg-red`]}
                                            onPress={() => {
                                                setDeleteFileModalAffectID(viewingPhotoObj.id);
                                                setDeleteFileModalActive(true);
                                            }}
                                        >
                                            {( state ) => (
                                                <Image
                                                    accessibilityLabel={labels.current?.DELETE}
                                                    source={ state.hovered ? { uri: require('../svg/delete_white.svg') } : { uri: require('../svg/delete.svg') }}
                                                    resizeMode="contain"
                                                    style={tw`h-6 w-6`}
                                                />
                                            )}
                                        </Pressable>
                                    }
                                </View>
                            </View>
                        </View>
                    </View>

                    <View style={tw`bg-[${themes[theme].brand_primary}] h-8 z-0 -mb-3 md:hidden`}>
                        <View style={tw`bg-gray0 h-full w-full rounded-t-3xl`}></View>
                    </View>

                    <View style={[tw`bg-gray0 grow md:pr-0 md:pb-0 md:pt-0 md:pl-0 z-20 md:rounded-b-md w-full`, { height: tw.prefixMatch('md') ? 'max-content' : 'calc(100vh - 100px)'}]}>
                        <View style={tw`h-full md:h-max`}>
                            <View style={tw`w-full justify-between px-5 mb-4 flex-row md:hidden`}>
                                <View style={tw`w-full`}>
                                    <Span style={tw`font-bold`}>{ viewingPhotoObj.description }</Span>
                                    <View style={tw`flex-row items-center w-full justify-between`}>
                                        <View style={tw`flex-row items-center`}>
                                            <Image
                                                accessibilityHidden={true}
                                                source={{ uri: require('../svg/date.svg') }}
                                                resizeMode="contain"
                                                style={tw`h-4 w-4 mr-2`}
                                            />
                                            <Span style={tw`text-gray6 text-sm leading-6`}>{ date_DMJYYYY( viewingPhotoObj.modified_date ) }</Span>
                                        </View>

                                        { 0 !== updateID && true === addingNew &&
                                            <Pressable
                                                style={tw`p-2 rounded bg-gray2 ml-5`}
                                                onPress={() => {
                                                    setDeleteFileModalAffectID(viewingPhotoObj.id);
                                                    setDeleteFileModalActive(true);
                                                }}
                                            >
                                                <Image
                                                    accessibilityLabel={labels.current?.DELETE}
                                                    source={{ uri: require('../svg/delete.svg') }}
                                                    resizeMode="contain"
                                                    style={tw`h-6 w-6`}
                                                />
                                            </Pressable>
                                        }
                                    </View>
                                </View>
                            </View>
                            <HorizontalRule style={tw`md:hidden`} />
                            <ScrollView
                                style={{width:'100%',overflowX:tw.prefixMatch('md') ? 'visible' : 'hidden'}}
                                contentContainerStyle={tw`w-full justify-start px-0`}
                            >
                                <View style={tw`md:flex-row md:flex-wrap md:justify-between h-[${viewingPhotoObj.height}px] max-h-[${height - 142}px] md:max-h-[80vh]`}>
                                    { 'undefined' !== typeof viewingPhotoObj?.uri &&
                                        <Image
                                            source={{ uri: viewingPhotoObj.uri }}
                                            resizeMode="contain"
                                            style={tw`w-full max-w-full h-auto min-h-full md:rounded-b-md`}
                                        />
                                    }
                                </View>
                            </ScrollView>
                        </View>
                    </View>
                </View>
            </AnimatedView>

            {/*<SelectMenu
                badge={false}
                title={ labels.current?.SELECT +' '+ labels.current?.TYPE }
                optionSet="radio"
                buttonTitle={ labels.current?.SELECT }
                active={documentTypeSelectVisible}
                setActive={setDocumentTypeSelectVisible}
                selectedValue={documentType}
                options={documentTypeOptions}
                onValueChange={(value) => {setDocumentType(value)}}
                scrollable={documentTypeOptions.length > 8}
                searchable={documentTypeOptions.length > 8}
            />

            <SelectMenu
                badge={false}
                title={ labels.current?.SELECT +' '+ labels.current?.TYPE }
                optionSet="radio"
                buttonTitle={ labels.current?.SELECT }
                active={attachmentTypeSelectVisible}
                setActive={setAttachmentTypeSelectVisible}
                selectedValue={attachmentType}
                options={attachmentTypeOptions}
                onValueChange={(value) => {setAttachmentType(value)}}
                scrollable={attachmentTypeOptions.length > 8}
                searchable={attachmentTypeOptions.length > 8}
            />*/}

            <SelectMenu
                badge={false}
                title={ labels.current?.VIEW }
                optionSet="radio"
                buttonTitle={ labels.current?.SORT }
                active={filterVisible}
                setActive={setFilterVisible}
                selectedValue={getFilter}
                options={typeFilterOptions}
                onValueChange={(value) => {setFilter(value)}}
                scrollable={typeFilterOptions.length > 8}
                searchable={typeFilterOptions.length > 8}
            />

            {/*<SelectMenu
                badge={false}
                title={ labels.current?.SELECT +' '+ labels.current?.TYPE }
                optionSet="radio"
                buttonTitle={ labels.current?.SELECT }
                active={typeSelectVisible}
                setActive={setTypeSelectVisible}
                selectedValue={newType}
                options={typeOptions}
                onValueChange={(value) => {setNewType(value)}}
                scrollable={typeOptions.length > 8}
            />*/}

            {/*<Modal
                active={modalActive}
                setActive={setModalActive}
            >
                <Heading hlevel={2} styleHlevel={3}>{ labels.current?.DELETE_CONFIRM_TITLE }</Heading>
                <Span>{ iDsContainingFiles.current.includes(modalAffectID) ? labels.current?.DELETE_CONFIRM2_BODY : labels.current?.DELETE_CONFIRM_BODY }</Span>
                <Span style={tw`text-red mt-2`}>{ labels.current?.PERMANENT_ACTION }</Span>
                <View style={tw`flex-row mt-5`}>
                    <Button
                        title={ labels.current?.CANCEL }
                        style={tw`w-full shrink py-2 bg-white border border-[${themes[theme].brand_primary}]`}
                        styleHover={tw`bg-white`}
                        styleText={tw`text-[${themes[theme].brand_primary}]`}
                        styleHoverText={tw`text-[${themes[theme].brand_primary}]`}
                        onPress={()=>{setModalActive(false)}}
                    />
                    <Button
                        title={ labels.current?.DELETE }
                        image={{ uri: require('../svg/delete_white.svg') }}
                        style={tw`ml-2 w-full shrink py-2 bg-red border border-red`}
                        styleHover={tw`bg-red`}
                        styleText={tw`text-white`}
                        styleHoverText={tw`text-white`}
                        onPress={()=>{
                            setPrimaryMsg('');

                            fetch(apiUrl, {
                                method: 'POST',
                                cache: 'no-cache',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    endpoint: 'notes/delete', v: apiVersion,
                                    client_key: localStorage.getItem('clientKey'),
                                    device_id: localStorage.getItem('deviceID'),
                                    payload: {
                                       id: modalAffectID,
                                    }
                                }),
                            })
                            .then((response) => {
                                return response.text().then(function(text) {
                                    const result = JSON.parse(text);
                                    if ( result.error )
                                    {
                                        if ( '401' === result.error ){ setLoggedIn(false); result.error = 'Your session token has expired, please log in again.'; }
                                        setPrimaryMsgType('warning');
                                        setPrimaryMsg(result.error);
                                    }
                                    else
                                    {
                                        setModalAffectID(0);
                                        setModalActive(false);
                                        toast.show((labels.current?.TOAST_DELETE).replace('{object}',labels.current?._ASSET), {type: 'success', duration:2000});

                                        setTimeout(()=>{
                                            setLastUpdate(now()); // forces app to refetch all account data
                                        }, 250);
                                    }
                                });
                            })
                            .catch(error => {
                                console.warn(error);
                                setPrimaryMsgType('warning');
                                if ( ! window.navigator.onLine ) { setPrimaryMsg('Internet connection failed. Please try again.'); }
                                else { setPrimaryMsg('An error ocurred. See console log.'); }
                            });
                        }}
                    />
                </View>
            </Modal>*/}

            <Modal
                active={mobileOnlyFeatureModalActive}
                setActive={setMobileOnlyFeatureModalActive}
            >
                <Heading hlevel={2} styleHlevel={3}>{ labels.current?.FEATURE_MOBILE_ONLY_TITLE }</Heading>
                <Span>{ labels.current?.FEATURE_MOBILE_ONLY_BODY }</Span>
                <View style={tw`flex-row mt-5`}>
                    <Button
                        title={ labels.current?.BACK }
                        style={tw`w-full shrink py-2 bg-white border border-[${themes[theme].brand_primary}]`}
                        styleHover={tw`bg-white`}
                        styleText={tw`text-[${themes[theme].brand_primary}]`}
                        styleHoverText={tw`text-[${themes[theme].brand_primary}]`}
                        onPress={()=>{setMobileOnlyFeatureModalActive(false)}}
                    />
                </View>
            </Modal>
        </View>
    )
}

export default YachtReadingsView;
