import React from 'react';
import { View, ScrollView, Image, Pressable } from 'react-native';
import { create, useDeviceContext } from 'twrnc';
import { Heading, MemoizedPrimaryNav as PrimaryNav, Modal, Span, Button, SectionPositionBar, MemoizedSimpleTextInput as SimpleTextInput, TextInput, MemoizedSelectMenu as SelectMenu, HorizontalRule, Notification } from '../elements';
import { getInitials, goPrevScreen, getLabel, getOptionsByLabelGroup, getPropertiesByGroup, now, getUnreadNotificationsCount, feetMeters, lbsTonnes, processSingleVesselData, getColorIDByVesselStatusID, getColorByID } from '../helpers';
import RegExHelper from '../regex';
import { GlobalContext } from '../global';
import { useToast } from 'react-native-toast-notifications';

function EditYachtView ({route, navigation})
{
    const tw = create( require(`../../tailwind.config.js`) );    
    useDeviceContext(tw);
    const toast = useToast();
    
    let {
        apiUrl,
        apiVersion,
        setLastUpdate,
        loggedIn, setLoggedIn,
        userID,
        userYachts, setUserYachts,
        userNotifications,
        themes, theme
    } = React.useContext(GlobalContext);

    React.useEffect(()=> 
    {
        if ( ! loggedIn ) { navigation.navigate('loading'); }
    },[loggedIn, navigation]);
    
    const { yachtID } = route.params;
    const [ yacht, setYacht ] = React.useState(0);
    
    // const [ scrollPosition, setScrollPosition ] = React.useState(0);

    const notificationCount = React.useCallback(()=>
    {
        return getUnreadNotificationsCount();
    },[]);
    
    const labels = React.useRef(null);
    React.useEffect(()=> 
    {
        if ( ! labels.current )
        {
            labels.current = {
                _ASSET: getLabel('YACHT'),
                CANCEL: getLabel('CANCEL'),
                YACHT_EDIT: getLabel('YACHT_EDIT'),
                NOTIFICATIONS: getLabel('NOTIFICATIONS'),
                GENERAL: getLabel('GENERAL'),
                CREDENTIALS: getLabel('CREDENTIALS'),
                DIMENSIONS: getLabel('DIMENSIONS'),
                ELECTRICAL: getLabel('ELECTRICAL'),
                STRUCTURAL: getLabel('STRUCTURAL'),
                NAME: getLabel('NAME'),
                YEAR: getLabel('YEAR'),
                MANUFACTURER: getLabel('MANUFACTURER'),
                STATUS: getLabel('STATUS'),
                TYPE: getLabel('TYPE'),
                HIN: getLabel('HIN'),
                VESSEL_ADD_INSTRUCT: getLabel('VESSEL_ADD_INSTRUCT'),
                HIN_VALID: getLabel('HIN_VALID'),
                HIN_INVALID: getLabel('HIN_INVALID'),
                BRAND: getLabel('BRAND'),
                MODEL: getLabel('MODEL'),
                FLAG: getLabel('FLAG'),
                PORT: getLabel('PORT'),
                USCG: getLabel('USCG'),
                LOCAL_REGISTRATION: getLabel('LOCAL_REGISTRATION'),
                IMO: getLabel('IMO'),
                MMSI: getLabel('MMSI'),
                CALLSIGN: getLabel('CALL_SIGN'),
                EPIRB: getLabel('EPIRB'),
                IMPERIAL: getLabel('IMPERIAL'),
                METRIC: getLabel('METRIC'),
                METERS_SHORT: getLabel('METERS_SHORT'),
                FEET_SHORT: getLabel('FEET_SHORT'),
                BEAM: getLabel('BEAM'),
                LENGTH: getLabel('LENGTH'),
                DRAFT: getLabel('DRAFT'),
                CLEARANCE: getLabel('CLEARANCE'),
                TONS: getLabel('TONS'),
                DISPLACEMENT: getLabel('DISPLACEMENT'),
                SHOREPOWER: getLabel('SHOREPOWER'),
                SHOREPOWER_QTY: getLabel('SHOREPOWER') +' '+ getLabel('QUANTITY_SHORT'),
                VOLTAGE: getLabel('VOLTAGE'),
                HULL_TYPE: getLabel('HULL_TYPE'),
                HULL_MATERIAL: getLabel('HULL_MATERIAL'),
                SAVE: getLabel('SAVE'),
                YACHT_NAME_REQUIRED: getLabel('YACHT_NAME_REQUIRED'),
                REQUEST: getLabel('REQUEST'),
                REQUEST_VALID: getLabel('REQUEST_VALID'),
                SELECT: getLabel('SELECT'),
                TASKS_SUSPEND_REMINDERS_TITLE: getLabel('TASKS_SUSPEND_REMINDERS_TITLE'),
                TASKS_SUSPEND_REMINDERS_BODY: getLabel('TASKS_SUSPEND_REMINDERS_BODY'),

                TOAST_UPDATE: getLabel('TOAST_UPDATE'),
                TOAST_ADD: getLabel('TOAST_ADD'),
                TOAST_DELETE: getLabel('TOAST_DELETE'),
            };
        }
    },[]);

    const sectionsSection = React.useRef();
    const generalSection = React.useRef();
    const dimensionsSection = React.useRef();
    const electricalSection = React.useRef();
    const structuralSection = React.useRef();

    const [yachtValidation, doYachtValidation] = React.useState(false);
    const [yachtErrorMsg, setYachtErrorMsg] = React.useState('');

    const [yachtDetails, setYachtDetails] = React.useState({});

    // input refs for asset specific inputs
    const newYachtNameInput = React.useRef('');
    const newYachtYearInput = React.useRef('');
    const newYachtHINInput = React.useRef('');
    const newYachtModelInput = React.useRef('');
    const newYachtPortInput = React.useRef('');
    const newYachtUSCGInput = React.useRef('');
    const newYachtLocalRegInput = React.useRef('');
    const newYachtIMOInput = React.useRef('');
    const newYachtMMSIInput = React.useRef('');
    const newYachtCallSignInput = React.useRef('');
    const newYachtEPIRBInput = React.useRef('');
    const newYachtBeamInput = React.useRef('');
    const newYachtLengthInput = React.useRef('');
    const newYachtDraftInput = React.useRef('');
    const newYachtClearanceInput = React.useRef('');
    const newYachtDisplacementInput = React.useRef('');

    React.useEffect(() => {
        if ( yachtID && ! yacht )
        {
            userYachts.every((yachtData, index) => {
                if ( yachtID == yachtData.id )
                {
                    // if yachtData.build_id then redirect to yacht
                    // if ( yachtData.build_id ) { navigation.navigate('yacht', {yachtID: yachtData.id}); }

                    setYacht(yachtData);
                    setYachtDetails({
                        'name': yachtData.name || '',
                        'year': yachtData.year || '',
                        'status': yachtData.status_id || 1,
                        'statusSelectVisible': false,
                        'manufacturer': yachtData.manufacturer_id || '',
                        'manufacturerSelectVisible': false,
                        'type': yachtData.type_id || '',
                        'typeSelectVisible': false,
                        'hin': yachtData.hin || '',
                        'brand': yachtData.brand_id || 0,
                        'brandSelectVisible': false,
                        'model': yachtData.model || '',
                        'flag': yachtData.flag_country_id || 0,
                        'flagSelectVisible': false,
                        'port': yachtData.port || '',
                        'uscg': yachtData.uscg || '',
                        'localReg': yachtData.local_registration || '',
                        'imo': yachtData.imo || '',
                        'mmsi': yachtData.mmsi || '',
                        'callSign': yachtData.call_sign || '',
                        'epirb': yachtData.epirb_beacon_id || '',
                        'isMetric': yachtData.is_metric || 0,
                        'beam': yachtData.beam || '',
                        'length': yachtData.length || '',
                        'draft': yachtData.draft || '',
                        'clearance': yachtData.clearance || '',
                        'displacement': yachtData.displacement || '',
                        'shorepower': yachtData.shorepower_id || 0,
                        'shorepowerSelectVisible': false,
                        'shorepowerQty': yachtData.shorepower_qty_id || 0,
                        'shorepowerQtySelectVisible': false,
                        'voltage': yachtData.voltage_id || 0,
                        'voltageSelectVisible': false,
                        'hullType': yachtData.hull_type_id || 0,
                        'hullTypeSelectVisible': false,
                        'material': yachtData.hull_material_id || 0,
                        'materialSelectVisible': false,
                    });

                    newYachtNameInput.current.value = yachtData.name || '';
                    newYachtYearInput.current.value = yachtData.year || '';
                    newYachtHINInput.current.value = yachtData.hin || '';
                    newYachtModelInput.current.value = yachtData.model || '';
                    newYachtPortInput.current.value = yachtData.port || '';
                    newYachtUSCGInput.current.value = yachtData.uscg || '';
                    newYachtLocalRegInput.current.value = yachtData.local_registration || '';
                    newYachtIMOInput.current.value = yachtData.imo || '';
                    newYachtMMSIInput.current.value = yachtData.mmsi || '';
                    newYachtCallSignInput.current.value = yachtData.call_sign || '';
                    newYachtEPIRBInput.current.value = yachtData.epirb_beacon_id || '';
                    newYachtBeamInput.current.value = yachtData.beam || '';
                    newYachtLengthInput.current.value = yachtData.length || '';
                    newYachtDraftInput.current.value = yachtData.draft || '';
                    newYachtClearanceInput.current.value = yachtData.clearance || '';
                    newYachtDisplacementInput.current.value = yachtData.displacement || '';

                    return false;
                }
                return true;
            });
        }
    },[yachtID, navigation]);

    const updateYachtDetails = React.useCallback((key, value) => {
        setYachtDetails(prevDetails => ({
            ...prevDetails,
            [key]: value
        }));
    }, [setYachtDetails]);


    const validateInput = React.useCallback((inputRef, regex, required) => {
        // Immediate return if the ref is not set or the value is undefined
        if (!inputRef?.current || typeof inputRef.current?.value === 'undefined') {
            return false;
        }

        // If the field is not required and is empty, it's considered valid
        if (!required && inputRef.current.value === '') {
            return true;
        }

        // Initialize result
        let result = false;

        // Helper function to test a single pattern
        const testPattern = (pattern, value) => {
            const condition = new RegExp(pattern, 'g');
            return condition.test(value);
        };

        // If 'regex' is a string or RegExp instance, test the value against it
        if (typeof regex === 'string') {
            result = testPattern(regex, inputRef.current.value);
        } else if (regex instanceof RegExp) {
            result = regex.test(inputRef.current.value);
        } else if (typeof regex === 'object' && Array.isArray(regex)) {
            // If an array of patterns is provided, every pattern must match (logical AND)
            result = regex.every(pattern => testPattern(pattern, inputRef.current.value));
        }

        return result;
    }, []);

    const [modalActive, setModalActive] = React.useState(false);

    const closeYachtStatusSelect = React.useCallback((visible) => { setYachtDetails(prevDetails => ({...prevDetails, 'statusSelectVisible': visible})); }, [setYachtDetails]);
    const closeYachtTypeSelect = React.useCallback((visible) => { setYachtDetails(prevDetails => ({...prevDetails, 'typeSelectVisible': visible})); }, [setYachtDetails]);
    const closeYachtBrandSelect = React.useCallback((visible) => { setYachtDetails(prevDetails => ({...prevDetails, 'brandSelectVisible': visible})); }, [setYachtDetails]);
    const closeYachtManufacturerSelect = React.useCallback((visible) => { setYachtDetails(prevDetails => ({...prevDetails, 'manufacturerSelectVisible': visible})); }, [setYachtDetails]);
    const closeYachtFlagSelect = React.useCallback((visible) => { setYachtDetails(prevDetails => ({...prevDetails, 'flagSelectVisible': visible})); }, [setYachtDetails]);
    const closeYachtShorepowerSelect = React.useCallback((visible) => { setYachtDetails(prevDetails => ({...prevDetails, 'shorepowerSelectVisible': visible})); }, [setYachtDetails]);
    const closeYachtShorepowerQtySelect = React.useCallback((visible) => { setYachtDetails(prevDetails => ({...prevDetails, 'shorepowerQtySelectVisible': visible})); }, [setYachtDetails]);
    const closeYachtVoltageSelect = React.useCallback((visible) => { setYachtDetails(prevDetails => ({...prevDetails, 'voltageSelectVisible': visible})); }, [setYachtDetails]);
    const closeYachtHullTypeSelect = React.useCallback((visible) => { setYachtDetails(prevDetails => ({...prevDetails, 'hullTypeSelectVisible': visible})); }, [setYachtDetails]);
    const closeYachtMaterialSelect = React.useCallback((visible) => { setYachtDetails(prevDetails => ({...prevDetails, 'materialSelectVisible': visible})); }, [setYachtDetails]);

    const manufacturerOptions = React.useRef(getOptionsByLabelGroup('data.vessel.manufacturers', true));
    const typeOptions = React.useRef(getOptionsByLabelGroup('types.vessel.types', true));
    const brandOptions = React.useRef(getOptionsByLabelGroup('data.vessel.brands', true));
    const shorepowerOptions = React.useRef(getOptionsByLabelGroup('types.shorepower', true));
    const shorepowerQtyOptions = React.useRef(getOptionsByLabelGroup('types.shorepower.quantity', true));
    const voltageOptions = React.useRef(getOptionsByLabelGroup('types.voltage', true));
    const hullTypeOptions = React.useRef(getOptionsByLabelGroup('types.hull.types', true));
    const materialOptions = React.useRef(getOptionsByLabelGroup('types.hull.materials', true));

    let statusDisplayOptions = getOptionsByLabelGroup('types.vessel.status', true);
    let statusOptions = getOptionsByLabelGroup('types.vessel.status', true)
        .filter(value => parseInt(value.value) < 900)
        .map(value => {
            value.badge = 'color';
            value.color = '[#'+getColorByID(getColorIDByVesselStatusID(value.value))+']';
            return value;
        });
    let statusReminders = getPropertiesByGroup( 'value', 'types.vessel.status', 'enable_reminders', false, true );

    let countryOptions = getOptionsByLabelGroup('data.countries', true);
    countryOptions.push({value:0, label:''});

    const countryFlags = React.useRef(getPropertiesByGroup( 'value', 'data.countries', 'img_flag', false, true ));
    countryOptions.forEach((value, index)=>
    {
        let foundFlag = countryFlags.current.find(obj => {return obj.value == value.value});
        value.badge = foundFlag ? { uri: require(`../images/flags/iso2/${foundFlag.img_flag}`) } : { uri: require('../svg/BlankFlag.svg') }
    });

    return (
        <View label="editYacht" style={tw`bg-gray0 h-screen md:flex-row overflow-hidden max-w-screen`}>

            <PrimaryNav navigation={navigation} setLoggedIn={setLoggedIn} hideOnMobile={true} yachtID={yachtID} />

            <View style={tw`bg-[${themes[theme].brand_primary}] h-20 flex-row md:hidden`}>
                <View style={tw`bg-[${themes[theme].brand_primary}] h-20 p-5 pt-6.5 flex-row`}>
                    <Pressable
                        style={tw`p-2 -ml-2 -mt-2`}
                        onPress={() => { goPrevScreen(navigation, 'yacht', {yachtID:yachtID}) }}
                    >
                        <Image
                            accessibilityLabel={ labels.current?.CANCEL }
                            source={{ uri: require('../svg/back_white.svg') }}
                            resizeMode="contain"
                            style={tw`h-6 w-6 top-px mr-1`}
                        />
                    </Pressable>
                    <Heading style={tw`mt-1.5 leading-4 mb-0 text-white`} hlevel={1} styleHlevel={4}>
                        { labels.current?.YACHT_EDIT }
                    </Heading>
                </View>

                <View style={tw`grow`}></View>
                
                <View style={tw`w-18 justify-center md:order-2 md:w-full`}>
                    <Pressable
                        onPress={()=>{navigation.navigate('notifications')}}
                        style={tw`p-5 relative md:flex-row md:pl-15 md:py-3 md:my-2`}
                    >
                        { notificationCount > 0 &&
                            <Span style={tw`bg-white text-[${themes[theme].brand_primary}] rounded-full min-w-4 h-4 text-center text-xs font-bold absolute bottom-1/2 left-1/2 md:left-18 z-10 pointer-events-none`}>
                                { notificationCount }
                            </Span>
                        }
                        
                        <Image
                            accessibilityLabel={ labels.current?.NOTIFICATIONS }
                            source={{ uri: require('../svg/notifications.svg') }}
                            resizeMode="contain"
                            style={tw`h-5 md:h-8 w-full md:w-8 relative z-0 -left-1`}
                        />
                        
                        <Span style={tw`hidden md:flex font-bold text-base text-white leading-8 ml-4 tracking-wide`}>{ labels.current?.NOTIFICATIONS }</Span>
                        
                    </Pressable>
                </View>
            </View>
        
            <View style={tw`bg-[${themes[theme].brand_primary}] h-8 md:h-full md:w-10 z-30 md:z-0 -mb-3 md:mb-0 md:-mr-3`}>
                <View style={tw`bg-gray0 h-full w-full rounded-t-3xl md:rounded-l-3xl md:rounded-r-0 z-10 md:shadow-black md:shadow-opacity-10 md:shadow-radius-4`}></View>
            </View>

            <View style={[tw`bg-gray0 flex-grow md:pr-0 md:pl-0 z-20`, {width: tw.prefixMatch('md') ? 'calc(100% - 320px)' : '100%', height: tw.prefixMatch('md') ? '100%' : 'calc(100% - 112px)'}]}>
                { /* ! tw.prefixMatch('md') &&
                    <SectionPositionBar
                        style={tw`z-10 px-5 pt-3 pb-5 md:hidden`}
                        styleHR={tw`top-4 w-[69.75%] left-[15%]`}
                        scroll={scrollPosition}
                        menu={[
                            {name: labels.current?.GENERAL, offset: 0, onPress: ()=> { sectionsSection.current.scrollTo({y: generalSection.current.offsetTop, animated: true}); }},
                            {name: labels.current?.DIMENSIONS, offset: dimensionsSection.current.offsetTop, onPress: ()=> { sectionsSection.current.scrollTo({y: dimensionsSection.current.offsetTop, animated: true}); }},
                            {name: labels.current?.ELECTRICAL, offset: electricalSection.current.offsetTop, onPress: ()=> { sectionsSection.current.scrollTo({y: electricalSection.current.offsetTop, animated: true}); }},
                            {name: labels.current?.STRUCTURAL, offset: structuralSection.current.offsetTop, onPress: ()=> { sectionsSection.current.scrollTo({y: structuralSection.current.offsetTop, animated: true}); }},
                        ]}
                    />
                */ }

                <View style={tw`shrink`}>

                    <View style={tw`w-full justify-start pt-3 px-5 md:px-0`}></View>

                    <ScrollView
                        ref={sectionsSection}
                        style={{width:'100%', overflowX:'visible'}}
                        contentContainerStyle={tw`w-full h-full overflow-x-hidden justify-start px-5 md:pr-8 md:pl-4 relative`}
                        // onScroll={(e) => { setScrollPosition(e.nativeEvent.contentOffset.y) }}
                        scrollEventThrottle="16"
                    >
                        <View style={tw`max-w-[1084px] pb-10 justify-start`}>
                            <View style={tw`flex-row w-full items-center justify-start mt-2 md:mt-8 mb-0`}>
                                <Span style={tw`shrink-0 bg-${yacht.color || 'gray6'} text-white uppercase rounded-full text-3xl font-semibold mr-4 w-24 h-24 text-center leading-[96px]`}>{getInitials(newYachtNameInput?.current?.value || '')}</Span>

                                <SimpleTextInput
                                    style={tw`w-full shrink md:grow`}
                                    autoCapitalize="on"
                                    placeholder={ labels.current?.NAME +'*' }
                                    onBlur={(e)=>{updateYachtDetails('name', e.target.value)}}
                                    passedRef={newYachtNameInput}
                                    required
                                />
                            </View>

                            <View ref={generalSection} style={tw`items-center mt-0 mb-0 md:flex-row md:flex-wrap md:items-start`}>

                                { '' !== yachtErrorMsg &&
                                    <View style={tw`w-full md:pr-2`}>
                                        <Notification
                                            style={tw`my-2`}
                                            type="warning"
                                        >{yachtErrorMsg}</Notification>
                                    </View>
                                }

                                <Heading style={tw`leading-4 mt-8 md:mt-8`} hlevel={2} styleHlevel={tw.prefixMatch('md') ? 3 : 4}>
                                    { labels.current?.GENERAL }
                                </Heading>

                                <SimpleTextInput
                                    style={[tw`my-1 md:mr-2`, {width: tw.prefixMatch('lg') ? 'calc(33.33% - 8px)' : '100%'}]}
                                    image={{ uri: require('../svg/'+theme+'/year.svg') }}
                                    keyboardType="numeric"
                                    placeholder={ labels.current?.YEAR }
                                    passedRef={newYachtYearInput}
                                    disabled={yacht.is_build ? true : false}
                                />

                                <Pressable
                                    style={[tw`my-1 md:mr-2`, {width: tw.prefixMatch('lg') ? 'calc(33.33% - 8px)' : '100%'}, yacht.is_build && tw`grayscale cursor-not-allowed`]}
                                    onPress={()=>{!yacht.is_build && updateYachtDetails('manufacturerSelectVisible', true)}}
                                >
                                    <TextInput
                                        style={tw`pointer-events-none`}
                                        image={{ uri: require('../svg/'+theme+'/manufacturer.svg') }}
                                        placeholder={ labels.current?.MANUFACTURER }
                                        value={yachtDetails.manufacturer ? ( manufacturerOptions.current.find(obj => {return obj.value == yachtDetails.manufacturer})?.label || '' ) : ''}
                                        disabled={yacht.is_build ? true : false}
                                    />

                                    <Image
                                        accessibilityHidden={true}
                                        source={{ uri: require('../svg/'+theme+'/chevron_down_blue.svg') }}
                                        resizeMode="contain"
                                        style={tw`h-3 w-3 absolute top-1/2 -translate-y-1/2 right-4`}
                                    />
                                </Pressable>

                                <Pressable
                                    style={[tw`my-1 md:mr-2`, {width: tw.prefixMatch('lg') ? 'calc(33.33% - 8px)' : '100%'}, yacht.is_build && tw`grayscale cursor-not-allowed`]}
                                    onPress={()=>{!yacht.is_build && updateYachtDetails('statusSelectVisible', true)}}
                                >
                                    <TextInput
                                        style={tw`pointer-events-none`}
                                        image={{ uri: require('../svg/'+theme+'/status.svg') }}
                                        color={ getColorByID(getColorIDByVesselStatusID(yachtDetails.status)) }
                                        placeholder={ labels.current?.STATUS +'*' }
                                        value={ yachtDetails.status ? ( statusDisplayOptions.find(obj => {return obj.value == yachtDetails.status})?.label || '' ) : '' }
                                        disabled={yacht.is_build ? true : false}
                                        required
                                    />

                                    <Image
                                        accessibilityHidden={true}
                                        source={{ uri: require('../svg/'+theme+'/chevron_down_blue.svg') }}
                                        resizeMode="contain"
                                        style={tw`h-3 w-3 absolute top-1/2 -translate-y-1/2 right-4`}
                                    />
                                </Pressable>

                                <Pressable
                                    style={[tw`my-1 md:mr-2`, {width: tw.prefixMatch('lg') ? 'calc(33.33% - 8px)' : '100%'}, yacht.is_build && tw`grayscale cursor-not-allowed`]}
                                    onPress={()=>{!yacht.is_build && updateYachtDetails('typeSelectVisible', true)}}
                                >
                                    <TextInput
                                        style={tw`pointer-events-none`}
                                        image={{ uri: require('../svg/'+theme+'/vesseltype.svg') }}
                                        placeholder={ labels.current?.TYPE +'*' }
                                        value={yachtDetails.type ? ( typeOptions.current.find(obj => {return obj.value == yachtDetails.type})?.label || '' ) : ''}
                                        disabled={yacht.is_build ? true : false}
                                        required
                                    />

                                    <Image
                                        accessibilityHidden={true}
                                        source={{ uri: require('../svg/'+theme+'/chevron_down_blue.svg') }}
                                        resizeMode="contain"
                                        style={tw`h-3 w-3 absolute top-1/2 -translate-y-1/2 right-4`}
                                    />
                                </Pressable>

                                <SimpleTextInput
                                    style={[tw`my-1 md:mr-2`, {width: tw.prefixMatch('lg') ? 'calc(33.33% - 8px)' : '100%'}]}
                                    image={{ uri: require('../svg/'+theme+'/hin.svg') }}
                                    autoCapitalize="on"
                                    placeholder={ labels.current?.HIN }
                                    passedRef={newYachtHINInput}
                                    onBlur={(e)=>{updateYachtDetails('hin', e.target.value)}}
                                />

                                { ! RegExHelper.validators.hin.test(yachtDetails.hin || '') &&
                                    <Span style={tw`md:hidden text-sm text-ink my-2 w-full`}>{ labels.current?.HIN_INVALID }</Span>
                                }

                                <Pressable
                                    style={[tw`my-1 md:mr-2`, {width: tw.prefixMatch('lg') ? 'calc(33.33% - 8px)' : '100%'}, yacht.is_build && tw`grayscale cursor-not-allowed`]}
                                    onPress={()=>{!yacht.is_build && updateYachtDetails('brandSelectVisible', true)}}
                                >
                                    <TextInput
                                        style={tw`pointer-events-none`}
                                        image={{ uri: require('../svg/'+theme+'/brand.svg') }}
                                        placeholder={ labels.current?.BRAND }
                                        value={yachtDetails.brand ? ( brandOptions.current.find(obj => {return obj.value == yachtDetails.brand})?.label || '' ) : ''}
                                        disabled={yacht.is_build ? true : false}
                                    />

                                    <Image
                                        accessibilityHidden={true}
                                        source={{ uri: require('../svg/'+theme+'/chevron_down_blue.svg') }}
                                        resizeMode="contain"
                                        style={tw`h-3 w-3 absolute top-1/2 -translate-y-1/2 right-4`}
                                    />
                                </Pressable>

                                { ! RegExHelper.validators.hin.test(yachtDetails.hin || '') &&
                                    <View style={tw`hidden md:flex w-full items-center`}>
                                        <Span style={[tw`text-sm text-ink my-2 mx-6`, {width: 'calc(33.33% - 8px)' }]}>{ labels.current?.HIN_INVALID }</Span>
                                    </View>
                                }

                                <SimpleTextInput
                                    style={[tw`my-1 md:mr-2`, {width: tw.prefixMatch('lg') ? 'calc(33.33% - 8px)' : '100%'}]}
                                    image={{ uri: require('../svg/'+theme+'/model.svg') }}
                                    autoCapitalize="on"
                                    placeholder={ labels.current?.MODEL }
                                    passedRef={newYachtModelInput}
                                    disabled={yacht.is_build ? true : false}
                                />

                                <Pressable
                                    style={[tw`my-1 md:mr-2`, {width: tw.prefixMatch('lg') ? 'calc(33.33% - 8px)' : '100%'}]}
                                    onPress={()=>{updateYachtDetails('flagSelectVisible', true)}}
                                >
                                    <TextInput
                                        style={tw`pointer-events-none`}
                                        image={{ uri: require('../svg/'+theme+'/flag.svg') }}
                                        image2={countryOptions.find(obj => {return obj.value == yachtDetails.flag})?.badge}
                                        placeholder={ labels.current?.FLAG }
                                        value={countryOptions.find(obj => {return obj.value == yachtDetails.flag})?.label || ''}
                                    />

                                    <Image
                                        accessibilityHidden={true}
                                        source={{ uri: require('../svg/'+theme+'/chevron_down_blue.svg') }}
                                        resizeMode="contain"
                                        style={tw`h-3 w-3 absolute top-1/2 -translate-y-1/2 right-4`}
                                    />
                                </Pressable>

                                <SimpleTextInput
                                    style={[tw`my-1 md:mr-2`, {width: tw.prefixMatch('lg') ? 'calc(33.33% - 8px)' : '100%'}]}
                                    image={{ uri: require('../svg/'+theme+'/location_blue.svg') }}
                                    autoCapitalize="on"
                                    placeholder={ labels.current?.PORT }
                                    passedRef={newYachtPortInput}
                                />

                                <Heading style={tw`leading-4 mt-8 md:mt-8`} hlevel={2} styleHlevel={tw.prefixMatch('md') ? 3 : 4}>
                                    { labels.current?.CREDENTIALS }
                                </Heading>

                                <SimpleTextInput
                                    style={[tw`my-1 md:mr-2`, {width: tw.prefixMatch('lg') ? 'calc(33.33% - 8px)' : '100%'}]}
                                    image={{ uri: require('../svg/'+theme+'/hin.svg') }}
                                    autoCapitalize="on"
                                    prefix={ labels.current?.USCG }
                                    stylePrefix={tw`w-23 md:w-auto`}
                                    passedRef={newYachtUSCGInput}
                                />

                                <SimpleTextInput
                                    style={[tw`my-1 md:mr-2`, {width: tw.prefixMatch('lg') ? 'calc(33.33% - 8px)' : '100%'}]}
                                    image={{ uri: require('../svg/'+theme+'/hin.svg') }}
                                    autoCapitalize="on"
                                    prefix={ labels.current?.LOCAL_REGISTRATION }
                                    stylePrefix={tw`w-23 md:w-auto`}
                                    passedRef={newYachtLocalRegInput}
                                />

                                <SimpleTextInput
                                    style={[tw`my-1 md:mr-2`, {width: tw.prefixMatch('lg') ? 'calc(33.33% - 8px)' : '100%'}]}
                                    image={{ uri: require('../svg/'+theme+'/hin.svg') }}
                                    autoCapitalize="on"
                                    prefix={ labels.current?.IMO }
                                    stylePrefix={tw`w-23 md:w-auto`}
                                    passedRef={newYachtIMOInput}
                                />

                                <SimpleTextInput
                                    style={[tw`my-1 md:mr-2`, {width: tw.prefixMatch('lg') ? 'calc(33.33% - 8px)' : '100%'}]}
                                    image={{ uri: require('../svg/'+theme+'/hin.svg') }}
                                    autoCapitalize="on"
                                    prefix={ labels.current?.MMSI }
                                    stylePrefix={tw`w-23 md:w-auto`}
                                    passedRef={newYachtMMSIInput}
                                />

                                <SimpleTextInput
                                    style={[tw`my-1 md:mr-2`, {width: tw.prefixMatch('lg') ? 'calc(33.33% - 8px)' : '100%'}]}
                                    image={{ uri: require('../svg/'+theme+'/hin.svg') }}
                                    autoCapitalize="on"
                                    prefix={ labels.current?.CALLSIGN }
                                    stylePrefix={tw`w-23 md:w-auto`}
                                    passedRef={newYachtCallSignInput}
                                />

                                <SimpleTextInput
                                    style={[tw`my-1 md:mr-2`, {width: tw.prefixMatch('lg') ? 'calc(33.33% - 8px)' : '100%'}]}
                                    image={{ uri: require('../svg/'+theme+'/hin.svg') }}
                                    autoCapitalize="on"
                                    prefix={ labels.current?.EPIRB }
                                    stylePrefix={tw`w-23 md:w-auto`}
                                    passedRef={newYachtEPIRBInput}
                                />
                            </View>

                            <View ref={dimensionsSection} style={tw`items-center mt-0 mb-0 md:flex-row md:flex-wrap md:items-start`}>

                                <View style={tw`flex-row items-start w-full`}>
                                    <Heading style={tw`leading-4 w-1/2 mt-8 md:mt-12`} hlevel={2} styleHlevel={tw.prefixMatch('md') ? 3 : 4}>
                                        { labels.current?.DIMENSIONS }
                                    </Heading>

                                    <View style={tw`flex-row w-1/2 mb-2 self-end justify-end`}>
                                        <Pressable
                                            style={[tw`py-2 px-3 rounded-l-xl border border-[${themes[theme].brand_primary}] bg-gray0`, ! yachtDetails.isMetric && tw`bg-[${themes[theme].brand_primary}]`, yacht.is_build && tw`grayscale cursor-not-allowed`]}
                                            onPress={()=>{
                                                if ( ! yacht.is_build && 1 === yachtDetails.isMetric )
                                                {
                                                    updateYachtDetails('isMetric', 0);
                                                    newYachtBeamInput.current.value = feetMeters(null, newYachtBeamInput.current.value);
                                                    newYachtLengthInput.current.value = feetMeters(null, newYachtLengthInput.current.value);
                                                    newYachtDraftInput.current.value = feetMeters(null, newYachtDraftInput.current.value);
                                                    newYachtClearanceInput.current.value = feetMeters(null, newYachtClearanceInput.current.value);
                                                    newYachtDisplacementInput.current.value = lbsTonnes(null, newYachtDisplacementInput.current.value);
                                                }
                                            }}
                                        >
                                            <Span style={[tw`text-xs text-[${themes[theme].brand_primary}]`, ! yachtDetails.isMetric && tw`text-gray0`]}>{ labels.current?.IMPERIAL }</Span>
                                        </Pressable>
                                        <Pressable
                                            style={[tw`py-2 px-3 md:mr-2 rounded-r-xl border border-[${themes[theme].brand_primary}] bg-[${themes[theme].brand_primary}]`, ! yachtDetails.isMetric && tw`bg-gray0`, yacht.is_build && tw`grayscale cursor-not-allowed`]}
                                            onPress={()=>{

                                                if ( ! yacht.is_build && 0 === yachtDetails.isMetric )
                                                {
                                                    updateYachtDetails('isMetric', 1);
                                                    newYachtBeamInput.current.value = feetMeters(newYachtBeamInput.current.value, null);
                                                    newYachtLengthInput.current.value = feetMeters(newYachtLengthInput.current.value, null);
                                                    newYachtDraftInput.current.value = feetMeters(newYachtDraftInput.current.value, null);
                                                    newYachtClearanceInput.current.value = feetMeters(newYachtClearanceInput.current.value, null);
                                                    newYachtDisplacementInput.current.value = lbsTonnes(newYachtDisplacementInput.current.value, null);
                                                }
                                            }}
                                        >
                                            <Span style={[tw`text-xs text-gray0`, ! yachtDetails.isMetric && tw`text-[${themes[theme].brand_primary}]`]}>{ labels.current?.METRIC }</Span>
                                        </Pressable>
                                    </View>
                                </View>

                                <SimpleTextInput
                                    style={[tw`my-1 md:mr-2`, {width: tw.prefixMatch('lg') ? 'calc(33.33% - 8px)' : '100%'}]}
                                    image={{ uri: require('../svg/'+theme+'/beam.svg') }}
                                    suffix={ yachtDetails.isMetric ? labels.current?.METERS_SHORT : labels.current?.FEET_SHORT }
                                    keyboardType="numeric"
                                    stylePrefix={tw`w-20 md:w-auto`}
                                    prefix={ labels.current?.BEAM }
                                    passedRef={newYachtBeamInput}
                                    disabled={yacht.is_build ? true : false}
                                />

                                <SimpleTextInput
                                    style={[tw`my-1 md:mr-2`, {width: tw.prefixMatch('lg') ? 'calc(33.33% - 8px)' : '100%'}]}
                                    image={{ uri: require('../svg/'+theme+'/lenght.svg') }}
                                    suffix={ yachtDetails.isMetric ? labels.current?.METERS_SHORT : labels.current?.FEET_SHORT }
                                    keyboardType="numeric"
                                    stylePrefix={tw`w-20 md:w-auto`}
                                    prefix={ labels.current?.LENGTH }
                                    passedRef={newYachtLengthInput}
                                    disabled={yacht.is_build ? true : false}
                                />

                                <SimpleTextInput
                                    style={[tw`my-1 md:mr-2`, {width: tw.prefixMatch('lg') ? 'calc(33.33% - 8px)' : '100%'}]}
                                    image={{ uri: require('../svg/'+theme+'/draft.svg') }}
                                    suffix={ yachtDetails.isMetric ? labels.current?.METERS_SHORT : labels.current?.FEET_SHORT }
                                    keyboardType="numeric"
                                    stylePrefix={tw`w-20 md:w-auto`}
                                    prefix={ labels.current?.DRAFT }
                                    passedRef={newYachtDraftInput}
                                    disabled={yacht.is_build ? true : false}
                                />

                                <SimpleTextInput
                                    style={[tw`my-1 md:mr-2`, {width: tw.prefixMatch('lg') ? 'calc(33.33% - 8px)' : '100%'}]}
                                    image={{ uri: require('../svg/'+theme+'/clearance_blue.svg') }}
                                    suffix={ yachtDetails.isMetric ? labels.current?.METERS_SHORT : labels.current?.FEET_SHORT }
                                    keyboardType="numeric"
                                    stylePrefix={tw`w-20 md:w-auto`}
                                    prefix={ labels.current?.CLEARANCE }
                                    passedRef={newYachtClearanceInput}
                                    disabled={yacht.is_build ? true : false}
                                />

                                <SimpleTextInput
                                    style={[tw`my-1 md:mr-2`, {width: tw.prefixMatch('lg') ? 'calc(33.33% - 8px)' : '100%'}]}
                                    image={{ uri: require('../svg/'+theme+'/displacement.svg') }}
                                    suffix={ yachtDetails.isMetric ? labels.current?.TONS : 'lbs' }
                                    keyboardType="numeric"
                                    stylePrefix={tw`w-20 md:w-auto`}
                                    prefix={ labels.current?.DISPLACEMENT }
                                    passedRef={newYachtDisplacementInput}
                                    disabled={yacht.is_build ? true : false}
                                />
                            </View>

                            <View ref={electricalSection} style={tw`items-center mt-0 mb-0 md:flex-row md:flex-wrap md:items-start`}>
                                <Heading style={tw`leading-4 mt-8 md:mt-12`} hlevel={2} styleHlevel={tw.prefixMatch('md') ? 3 : 4}>
                                    { labels.current?.ELECTRICAL }
                                </Heading>

                                <Pressable
                                    style={[tw`my-1 md:mr-2`, {width: tw.prefixMatch('lg') ? 'calc(33.33% - 8px)' : '100%'}, yacht.is_build && tw`grayscale cursor-not-allowed`]}
                                    onPress={()=>{!yacht.is_build && updateYachtDetails('voltageSelectVisible', true)}}
                                >
                                    <TextInput
                                        style={tw`pointer-events-none`}
                                        image={{ uri: require('../svg/'+theme+'/voltage.svg') }}
                                        prefix={ labels.current?.VOLTAGE }
                                        stylePrefix={tw`w-19 md:w-auto`}
                                        value={yachtDetails.voltage ? ( voltageOptions.current.find(obj => {return obj.value == yachtDetails.voltage})?.label || '' ) : ''}
                                        disabled={yacht.is_build ? true : false}
                                    />

                                    <Image
                                        accessibilityHidden={true}
                                        source={{ uri: require('../svg/'+theme+'/chevron_down_blue.svg') }}
                                        resizeMode="contain"
                                        style={tw`h-3 w-3 absolute top-1/2 -translate-y-1/2 right-4`}
                                    />
                                </Pressable>

                                <Pressable
                                    style={[tw`my-1 md:mr-2`, {width: tw.prefixMatch('lg') ? 'calc(33.33% - 8px)' : '100%'}, yacht.is_build && tw`grayscale cursor-not-allowed`]}
                                    onPress={()=>{!yacht.is_build && updateYachtDetails('shorepowerSelectVisible', true)}}
                                >
                                    <TextInput
                                        style={tw`pointer-events-none`}
                                        image={{ uri: require('../svg/'+theme+'/shorepower.svg') }}
                                        prefix={ labels.current?.SHOREPOWER }
                                        stylePrefix={tw`w-19 md:w-auto`}
                                        value={yachtDetails.shorepower ? ( shorepowerOptions.current.find(obj => {return obj.value == yachtDetails.shorepower})?.label || '' ) : ''}
                                        disabled={yacht.is_build ? true : false}
                                    />

                                    <Image
                                        accessibilityHidden={true}
                                        source={{ uri: require('../svg/'+theme+'/chevron_down_blue.svg') }}
                                        resizeMode="contain"
                                        style={tw`h-3 w-3 absolute top-1/2 -translate-y-1/2 right-4`}
                                    />
                                </Pressable>

                                <Pressable
                                    style={[tw`my-1 md:mr-2`, {width: tw.prefixMatch('lg') ? 'calc(33.33% - 8px)' : '100%'}, yacht.is_build && tw`grayscale cursor-not-allowed`]}
                                    onPress={()=>{!yacht.is_build && updateYachtDetails('shorepowerQtySelectVisible', true)}}
                                >
                                    <TextInput
                                        style={tw`pointer-events-none`}
                                        image={{ uri: require('../svg/'+theme+'/electrical_blue.svg') }}
                                        prefix={ labels.current?.SHOREPOWER_QTY }
                                        stylePrefix={tw`w-23 md:w-auto`}
                                        value={yachtDetails.shorepowerQty ? ( shorepowerQtyOptions.current.find(obj => {return obj.value == yachtDetails.shorepowerQty})?.label || '' ) : ''}
                                        disabled={yacht.is_build ? true : false}
                                    />

                                    <Image
                                        accessibilityHidden={true}
                                        source={{ uri: require('../svg/'+theme+'/chevron_down_blue.svg') }}
                                        resizeMode="contain"
                                        style={tw`h-3 w-3 absolute top-1/2 -translate-y-1/2 right-4`}
                                    />
                                </Pressable>
                            </View>

                            <View ref={structuralSection} style={tw`items-center mt-0 mb-0 md:flex-row md:flex-wrap md:items-start`}>
                                <Heading style={tw`leading-4 mt-8 md:mt-12`} hlevel={2} styleHlevel={tw.prefixMatch('md') ? 3 : 4}>
                                    { labels.current?.STRUCTURAL }
                                </Heading>

                                <Pressable
                                    style={[tw`my-1 md:mr-2`, {width: tw.prefixMatch('lg') ? 'calc(33.33% - 8px)' : '100%'}, yacht.is_build && tw`grayscale cursor-not-allowed`]}
                                    onPress={()=>{!yacht.is_build && updateYachtDetails('hullTypeSelectVisible', true)}}
                                >
                                    <TextInput
                                        style={tw`pointer-events-none`}
                                        image={{ uri: require('../svg/'+theme+'/hull type.svg') }}
                                        stylePrefix={tw`w-18 md:w-auto`}
                                        prefix={ labels.current?.HULL_TYPE }
                                        value={yachtDetails.hullType ? ( hullTypeOptions.current.find(obj => {return obj.value == yachtDetails.hullType})?.label || '' ) : ''}
                                        disabled={yacht.is_build ? true : false}
                                    />

                                    <Image
                                        accessibilityHidden={true}
                                        source={{ uri: require('../svg/'+theme+'/chevron_down_blue.svg') }}
                                        resizeMode="contain"
                                        style={tw`h-3 w-3 absolute top-1/2 -translate-y-1/2 right-4`}
                                    />
                                </Pressable>

                                <Pressable
                                    style={[tw`my-1 md:mr-2`, {width: tw.prefixMatch('lg') ? 'calc(33.33% - 8px)' : '100%'}, yacht.is_build && tw`grayscale cursor-not-allowed`]}
                                    onPress={()=>{!yacht.is_build && updateYachtDetails('materialSelectVisible', true)}}
                                >
                                    <TextInput
                                        style={tw`pointer-events-none`}
                                        image={{ uri: require('../svg/'+theme+'/material.svg') }}
                                        stylePrefix={tw`w-18 md:w-auto`}
                                        prefix={ labels.current?.HULL_MATERIAL }
                                        value={yachtDetails.material ? ( materialOptions.current.find(obj => {return obj.value == yachtDetails.material})?.label || '' ) : ''}
                                        disabled={yacht.is_build ? true : false}
                                    />

                                    <Image
                                        accessibilityHidden={true}
                                        source={{ uri: require('../svg/'+theme+'/chevron_down_blue.svg') }}
                                        resizeMode="contain"
                                        style={tw`h-3 w-3 absolute top-1/2 -translate-y-1/2 right-4`}
                                    />
                                </Pressable>
                            </View>

                            {/*<View style={tw`h-100 md:hidden`}></View>*/}

                        </View>

                    </ScrollView>

                    <HorizontalRule style={tw`md:hidden`} />

                    <Button
                        title={ labels.current?.SAVE }
                        style={tw`my-5 py-3.5 md:my-6.5 md:py-5 mx-2 md:top-1 md:right-2`}
                        styleText={tw`text-sm tracking-[0.75]`}
                        styleLoading={tw`md:py-3`}
                        validateGroup={yachtValidation}
                        onPress={()=>{
                            if ( yachtValidation ) { return; }

                            doYachtValidation(true);
                            setYachtErrorMsg('');

                            setTimeout(()=>
                            {
                                // check if fields are valid

                                let errorMsg = [];

                                if ( ! validateInput( newYachtNameInput, "^.{2,}$", true ) )
                                    { errorMsg.push(labels.current?.YACHT_NAME_REQUIRED); }
                                if ( ! yachtDetails.status )
                                    { errorMsg.push(labels.current?.REQUEST +' '+labels.current?.STATUS); }
                                if ( ! yachtDetails.type )
                                    { errorMsg.push(labels.current?.REQUEST +' '+labels.current?.TYPE); }
                                if ( ! validateInput( newYachtYearInput, "^(?:(?:19|20)[0-9]{2})$" ) )
                                    { errorMsg.push(labels.current?.REQUEST_VALID +' '+labels.current?.YEAR); }
                                if ( ! validateInput( newYachtModelInput, "^.{2,}$" ) )
                                    { errorMsg.push(labels.current?.REQUEST_VALID +' '+labels.current?.MODEL); }
                                if ( ! validateInput( newYachtBeamInput, "^([0-9]*[.])?[0-9]+$" ) )
                                    { errorMsg.push(labels.current?.REQUEST_VALID +' '+labels.current?.BEAM); }
                                if ( ! validateInput( newYachtLengthInput, "^([0-9]*[.])?[0-9]+$" ) )
                                    { errorMsg.push(labels.current?.REQUEST_VALID +' '+labels.current?.LENGTH); }
                                if ( ! validateInput( newYachtDraftInput, "^([0-9]*[.])?[0-9]+$" ) )
                                    { errorMsg.push(labels.current?.REQUEST_VALID +' '+labels.current?.DRAFT); }
                                if ( ! validateInput( newYachtClearanceInput, "^([0-9]*[.])?[0-9]+$" ) )
                                    { errorMsg.push(labels.current?.REQUEST_VALID +' '+labels.current?.CLEARANCE); }
                                if ( ! validateInput( newYachtDisplacementInput, "^([0-9]*[.])?[0-9]+$" ) )
                                    { errorMsg.push(labels.current?.REQUEST_VALID +' '+labels.current?.DISPLACEMENT); }
                                if ( ! validateInput( newYachtPortInput, "^.{2,}$" ) )
                                    { errorMsg.push(labels.current?.REQUEST_VALID +' '+labels.current?.PORT); }

                                /*if ( ! validateInput( newYachtHINInput, RegExHelper.validators.hin ) )
                                    { errorMsg.push(labels.current?.REQUEST_VALID +' '+labels.current?.HIN); }
                                if ( ! validateInput( newYachtUSCGInput, RegExHelper.validators.uscg ) )
                                    { errorMsg.push(labels.current?.REQUEST_VALID +' '+labels.current?.USCG); }
                                if ( ! validateInput( newYachtLocalRegInput, RegExHelper.validators.registration ) )
                                    { errorMsg.push(labels.current?.REQUEST_VALID +' '+labels.current?.LOCAL_REGISTRATION); }
                                if ( ! validateInput( newYachtIMOInput, RegExHelper.validators.imo ) )
                                    { errorMsg.push(labels.current?.REQUEST_VALID +' '+labels.current?.IMO); }
                                if ( ! validateInput( newYachtMMSIInput, RegExHelper.validators.mmsi ) )
                                    { errorMsg.push(labels.current?.REQUEST_VALID +' '+labels.current?.MMSI); }
                                if ( ! validateInput( newYachtCallSignInput, RegExHelper.validators.callsign ) )
                                    { errorMsg.push(labels.current?.REQUEST_VALID +' '+labels.current?.CALLSIGN); }
                                if ( ! validateInput( newYachtEPIRBInput, RegExHelper.validators.eprib ) )
                                    { errorMsg.push(labels.current?.REQUEST_VALID +' '+labels.current?.EPIRB); }*/

                                if ( errorMsg.length > 0 )
                                {
                                    toast.show(errorMsg.join('\n'), {type: 'danger', duration:10000});
                                    doYachtValidation(false);
                                }
                                else
                                {
                                    let today = now();

                                    fetch(apiUrl, {
                                        method: 'POST',
                                        cache: 'no-cache',
                                        headers: {
                                            'Content-Type': 'application/json'
                                        },
                                        body: JSON.stringify({
                                            endpoint: 'vessels/update', v: apiVersion,
                                            client_key: localStorage.getItem('clientKey'),
                                            device_id: localStorage.getItem('deviceID'),
                                            payload: {
                                                id: yachtID,
                                                name: newYachtNameInput.current.value,
                                                initials: getInitials(newYachtNameInput.current.value),
                                                year: newYachtYearInput.current.value,
                                                manufacturer_id: yachtDetails.manufacturer,
                                                status_id: yachtDetails.status,
                                                type_id: yachtDetails.type,
                                                hin: newYachtHINInput.current.value,
                                                brand_id: yachtDetails.brand,
                                                model: newYachtModelInput.current.value,
                                                flag_country_id: yachtDetails.flag,
                                                is_metric: yachtDetails.isMetric,
                                                beam: newYachtBeamInput.current.value,
                                                length: newYachtLengthInput.current.value,
                                                draft: newYachtDraftInput.current.value,
                                                clearance: newYachtClearanceInput.current.value,
                                                displacement: newYachtDisplacementInput.current.value,
                                                shorepower_id: yachtDetails.shorepower,
                                                shorepower_qty_id: yachtDetails.shorepowerQty,
                                                voltage_id: yachtDetails.voltage,
                                                hull_type_id: yachtDetails.hullType,
                                                hull_material_id: yachtDetails.material,
                                                port: newYachtPortInput.current.value,
                                                uscg: newYachtUSCGInput.current.value,
                                                local_registration: newYachtLocalRegInput.current.value,
                                                imo: newYachtIMOInput.current.value,
                                                mmsi: newYachtMMSIInput.current.value,
                                                call_sign: newYachtCallSignInput.current.value,
                                                epirb_beacon_id: newYachtEPIRBInput.current.value,

                                                description: null,
                                                date_acquired: yacht.date_acquired, // today.split('T')[0],
                                                modified_date: today.replace('T', ' ').replace('Z',''),
                                            }
                                        }),
                                    })
                                    .then((response) => {
                                        return response.text().then(function(text) {
                                            const result = JSON.parse(text);
                                            if ( result.error )
                                            {
                                                if ( '401' === result.error ){ setLoggedIn(false); result.error = 'Your session token has expired, please log in again.'; }

                                                // setYachtErrorMsg(result.error);
                                                // sectionsSection.current.scrollTo({y: 0, animated: true});

                                                toast.show(result.error, {type: 'danger', duration:10000});
                                                doYachtValidation(false);
                                            }
                                            else
                                            {
                                                // OLD UPDATE METHOD:
                                                // setTimeout(()=>{setLastUpdate(today);},2000); // forces app to refetch all account data

                                                // NEW UPDATE METHOD:
                                                // Manually update segment of user data, updating only the specific vessel data this form manipulates
                                                
                                                // START UPDATE METHOD
                                                
                                                let updatedVesselData = processSingleVesselData(result.obj_vessel[0], userID);
                                                let currentYachtData = userYachts.find(obj => {return obj.id == yachtID});

                                                // NOW GET THE FOLLOWING ATTRIBUTES FROM updatedVesselData AND UPDATE localStorage AND setUserYachts

                                                let updatedVesselDataAttributes = {
                                                    name: updatedVesselData.name,
                                                    year: updatedVesselData.year,
                                                    status_id: updatedVesselData.status_id,
                                                    tag: updatedVesselData.tag,
                                                    color: updatedVesselData.color,
                                                    tagColor: updatedVesselData.tagColor,
                                                    manufacturer_id: updatedVesselData.manufacturer_id,
                                                    mfg_name: updatedVesselData.mfg_name,
                                                    type_id: updatedVesselData.type_id,
                                                    type_name: updatedVesselData.type_name,
                                                    hin: updatedVesselData.hin,
                                                    brand_id: updatedVesselData.brand_id,
                                                    brand_name: updatedVesselData.brand_name,
                                                    model: updatedVesselData.model,
                                                    flag_country_id: updatedVesselData.flag_country_id,
                                                    flag_country_name: updatedVesselData.flag_country_name,
                                                    port: updatedVesselData.port,
                                                    uscg: updatedVesselData.uscg,
                                                    local_registration: updatedVesselData.local_registration,
                                                    imo: updatedVesselData.imo,
                                                    mmsi: updatedVesselData.mmsi,
                                                    call_sign: updatedVesselData.call_sign,
                                                    epirb_beacon_id: updatedVesselData.epirb_beacon_id,
                                                    is_metric: updatedVesselData.is_metric,
                                                    beam: updatedVesselData.beam,
                                                    length: updatedVesselData.length,
                                                    draft: updatedVesselData.draft,
                                                    clearance: updatedVesselData.clearance,
                                                    displacement: updatedVesselData.displacement,
                                                    shorepower_id: updatedVesselData.shorepower_id,
                                                    shorepower: updatedVesselData.shorepower,
                                                    shorepower_qty_id: updatedVesselData.shorepower_qty_id,
                                                    voltage_id: updatedVesselData.voltage_id,
                                                    voltage: updatedVesselData.voltage,
                                                    hull_type_id: updatedVesselData.hull_type_id,
                                                    hull_type: updatedVesselData.hull_type,
                                                    hull_material_id: updatedVesselData.hull_material_id,
                                                    hull_material: updatedVesselData.hull_material,
                                                    description: updatedVesselData.description,
                                                    location: updatedVesselData.location,
                                                    pending: updatedVesselData.pending,
                                                    owner: updatedVesselData.owner,
                                                    owner_id: updatedVesselData.owner_id,
                                                    owner_name: updatedVesselData.owner_name,
                                                    whoami: updatedVesselData.whoami,
                                                    is_owned_by_me: updatedVesselData.is_owned_by_me,
                                                };
                                                // NOW MERGE OUR NEW updatedVesselDataAttributes INTO currentYachtData OVERWRITING ANY EXISTING VALUES
                                                let updatedYachtData = {...currentYachtData, ...updatedVesselDataAttributes};
                                                // NOW UPDATE localStorage AND setUserYachts
                                                let updatedUserYachts = userYachts.map((vessel) => {
                                                    if (parseInt(vessel.id) === parseInt(updatedYachtData.id)) {
                                                        return updatedYachtData;
                                                    }
                                                    return vessel;
                                                });
                                                localStorage.setItem( 'yachts', JSON.stringify(updatedUserYachts) );
                                                setUserYachts(updatedUserYachts);


                                                // END UPDATE METHOD

                                                toast.show((labels.current?.TOAST_UPDATE).replace('{object}',labels.current?._ASSET), {type: 'success', duration:2000});

                                                setTimeout(()=>{
                                                    doYachtValidation(false);
                                                }, 1000);
                                                setTimeout(()=>{
                                                    goPrevScreen(navigation, 'yacht', {yachtID:yachtID});
                                                }, 1250);
                                            }
                                        });
                                    })
                                    .catch(error => {
                                        console.warn(error);
                                        if ( ! window.navigator.onLine ) { setYachtErrorMsg('Internet connection failed. Please try again.'); }
                                        else { setYachtErrorMsg('An error ocurred. See console log.'); }
                                        sectionsSection.current.scrollTo({y: 0, animated: true});
                                        doYachtValidation(false);
                                    });
                                }
                            }, 500)
                        }}
                    />
                </View>
            </View>

            <Modal
                active={modalActive}
                setActive={setModalActive}
            >
                <Heading hlevel={2} styleHlevel={3}>{ labels.current?.TASKS_SUSPEND_REMINDERS_TITLE }</Heading>
                <Span>{ labels.current?.TASKS_SUSPEND_REMINDERS_BODY }</Span>
                <View style={tw`flex-row mt-5`}>
                    <Button
                        title={getLabel('OK')}
                        style={tw`w-full shrink py-2 bg-white border border-[${themes[theme].brand_primary}]`}
                        styleHover={tw`bg-white`}
                        styleText={tw`text-[${themes[theme].brand_primary}]`}
                        styleHoverText={tw`text-[${themes[theme].brand_primary}]`}
                        onPress={()=>{setModalActive(false)}}
                    />
                </View>
            </Modal>

            <SelectMenu
                badge={false}
                title={ labels.current?.SELECT +' '+ labels.current?.STATUS }
                buttonTitle={ labels.current?.SELECT }
                active={yachtDetails.statusSelectVisible}
                setActive={closeYachtStatusSelect}
                selectedValue={yachtDetails.status}
                options={statusOptions}
                onValueChange={(value) => {
                    updateYachtDetails('status', value);

                    if (statusReminders.some(item => item.value === value && item.enable_reminders === 0))
                    {
                        setModalActive(true);
                    }
                }}
                scrollable={false}
            />

            <SelectMenu
                badge={false}
                title={ labels.current?.SELECT +' '+ labels.current?.TYPE }
                buttonTitle={ labels.current?.SELECT }
                active={yachtDetails.typeSelectVisible}
                setActive={closeYachtTypeSelect}
                selectedValue={yachtDetails.type}
                options={typeOptions.current}
                onValueChange={(value) => {updateYachtDetails('type', value)}}
                scrollable={true}
            />

            <SelectMenu
                badge={false}
                title={ labels.current?.SELECT +' '+ labels.current?.BRAND }
                buttonTitle={ labels.current?.SELECT }
                active={yachtDetails.brandSelectVisible}
                setActive={closeYachtBrandSelect}
                selectedValue={yachtDetails.brand}
                options={brandOptions.current}
                onValueChange={(value) => {updateYachtDetails('brand', value)}}
                scrollable={true}
                searchable={true}
            />

            <SelectMenu
                badge={false}
                title={ labels.current?.SELECT +' '+ labels.current?.MANUFACTURER }
                buttonTitle={ labels.current?.SELECT }
                active={yachtDetails.manufacturerSelectVisible}
                setActive={closeYachtManufacturerSelect}
                selectedValue={yachtDetails.manufacturer}
                options={manufacturerOptions.current}
                onValueChange={(value) => {updateYachtDetails('manufacturer', value)}}
                scrollable={true}
                searchable={true}
                requireSearch={true}
            />

            <SelectMenu
                badge={false}
                title={ labels.current?.SELECT +' '+ labels.current?.FLAG }
                buttonTitle={ labels.current?.SELECT }
                active={yachtDetails.flagSelectVisible}
                setActive={closeYachtFlagSelect}
                selectedValue={yachtDetails.flag}
                options={countryOptions}
                onValueChange={(value) => {updateYachtDetails('flag', value)}}
                scrollable={true}
                searchable={true}
            />

            <SelectMenu
                badge={false}
                title={ labels.current?.SELECT +' '+ labels.current?.SHOREPOWER }
                buttonTitle={ labels.current?.SELECT }
                active={yachtDetails.shorepowerSelectVisible}
                setActive={closeYachtShorepowerSelect}
                selectedValue={yachtDetails.shorepower}
                options={shorepowerOptions.current}
                onValueChange={(value) => {updateYachtDetails('shorepower', value)}}
            />

            <SelectMenu
                badge={false}
                title={ labels.current?.SELECT +' '+ labels.current?.SHOREPOWER_QTY }
                buttonTitle={ labels.current?.SELECT }
                active={yachtDetails.shorepowerQtySelectVisible}
                setActive={closeYachtShorepowerQtySelect}
                selectedValue={yachtDetails.shorepowerQty}
                options={shorepowerQtyOptions.current}
                onValueChange={(value) => {updateYachtDetails('shorepowerQty', value)}}
            />

            <SelectMenu
                badge={false}
                title={ labels.current?.SELECT +' '+ labels.current?.VOLTAGE }
                buttonTitle={ labels.current?.SELECT }
                active={yachtDetails.voltageSelectVisible}
                setActive={closeYachtVoltageSelect}
                selectedValue={yachtDetails.voltage}
                options={voltageOptions.current}
                onValueChange={(value) => {updateYachtDetails('voltage', value)}}
            />

            <SelectMenu
                badge={false}
                title={ labels.current?.SELECT +' '+ labels.current?.HULL_TYPE }
                buttonTitle={ labels.current?.SELECT }
                active={yachtDetails.hullTypeSelectVisible}
                setActive={closeYachtHullTypeSelect}
                selectedValue={yachtDetails.hullType}
                options={hullTypeOptions.current}
                onValueChange={(value) => {updateYachtDetails('hullType', value)}}
            />

            <SelectMenu
                badge={false}
                title={ labels.current?.SELECT +' '+ labels.current?.HULL_MATERIAL }
                buttonTitle={ labels.current?.SELECT }
                active={yachtDetails.materialSelectVisible}
                setActive={closeYachtMaterialSelect}
                selectedValue={yachtDetails.material}
                options={materialOptions.current}
                onValueChange={(value) => {updateYachtDetails('material', value)}}
            />

        </View>
    )
}

export default EditYachtView;
